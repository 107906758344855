import {
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_SELECT_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_SELECTED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedFormProcessingFormReceiveQuotationWaste: null,
  selectedFormProcessingFormReceiveQuotationWasteId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'createdDate',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_SELECTED:
      return {
        ...state,
        selectedFormProcessingFormReceiveQuotationWaste: action.payload,
        selectedFormProcessingFormReceiveQuotationWasteId: action.payload.id
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_FAILED:
      return {
        ...state
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD:
      return {
        ...state
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS:
      return {
        ...state
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_FAILED:
      return {
        ...state
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL:
      return {
        ...state
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    default:
      return state;
  }
}
