import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSupplyFormItems as loadSupplyFormItemsAction,
  loadSupplyFormItemsSuccess,
  updateSupplyFormItemSuccess,
  addSupplyFormItemSuccess,
  deleteSupplyFormItemSuccess,
  deleteAllSupplyFormItemsSuccess,
  exportExcelSupplyFormItems,
  exportExcelSupplyFormItemsSuccess,
  exportExcelSupplyFormItemsFailed,
} from '../actions/SupplyFormItem';

import {
  SUPPLYFORMITEMS_LOAD,
  SUPPLYFORMITEM_ADD,
  SUPPLYFORMITEM_UPDATE,
  SUPPLYFORMITEM_DELETE,
  SUPPLYFORMITEM_DELETE_ALL,
  SUPPLYFORMITEMS_EXPORT_EXCEL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSupplyFormItemById = (id, callback) => {
  Api().get('supply-form-item/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllSupplyFormItems = (callback, params) => {
  if (!params) {
    params = {
      offset: 0,
      limit: GET_ALL_SIZE,
      orderBy: 'wasteCode',
      descending: false
    }
  }
  
  Api().post('supply-form-item/search', {
    ...params,
    offset: 0,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadSupplyFormItemsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`supply-form-item/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`supply-form-item`, { params })
    .then(data => data)
    .catch(error => error);
}

const addSupplyFormItemRequest = async (payload) =>
  await Api().post(`supply-form-item`, payload)
    .then(data => data)
    .catch(error => error);

const updateSupplyFormItemRequest = async (payload) =>
  await Api().patch(`supply-form-item/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSupplyFormItemRequest = async (id) =>
  await Api().delete(`supply-form-item/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSupplyFormItems({ payload }) {
  try {
    const data = yield call(loadSupplyFormItemsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadSupplyFormItemsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyFormItems() {
  yield takeEvery(SUPPLYFORMITEMS_LOAD, loadSupplyFormItems);
}

function* loadSupplyFormItem({ selectedSupplyFormItemId }) {
  try {
    const data = yield call(loadSupplyFormItemsRequest, selectedSupplyFormItemId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadSupplyFormItemsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyFormItem() {
  yield takeEvery(SUPPLYFORMITEMS_LOAD, loadSupplyFormItem);
}

function* addSupplyFormItem({ payload }) {
  try {
    const data = yield call(addSupplyFormItemRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addSupplyFormItemSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSupplyFormItem() {
  yield takeEvery(SUPPLYFORMITEM_ADD, addSupplyFormItem);
}

function* updateSupplyFormItem({ payload }) {
  try {
    const data = yield call(updateSupplyFormItemRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateSupplyFormItemSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSupplyFormItem() {
  yield takeEvery(SUPPLYFORMITEM_UPDATE, updateSupplyFormItem);
}

function* deleteSupplyFormItem({ payload }) {
  try {
    const data = yield call(deleteSupplyFormItemRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(deleteSupplyFormItemSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSupplyFormItems({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSupplyFormItemRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllSupplyFormItemsSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSupplyFormItem() {
  yield takeEvery(SUPPLYFORMITEM_DELETE, deleteSupplyFormItem);
}

function* processDeleteAllSupplyFormItems() {
  yield takeEvery(SUPPLYFORMITEM_DELETE_ALL, deleteAllSupplyFormItems);
}

const exportSupplyFormItemsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`supply-form-item/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportSupplyFormItems({ payload }) {
  try {
    const data = yield call(exportSupplyFormItemsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSupplyFormItemsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSupplyFormItems() {
  yield takeEvery(SUPPLYFORMITEMS_EXPORT_EXCEL, exportSupplyFormItems);
}

export default function* SupplyFormItemSagas() {
  yield all([fork(processLoadSupplyFormItems),
  fork(processLoadSupplyFormItem),
  fork(processAddSupplyFormItem),
  fork(processUpdateSupplyFormItem),
  fork(processDeleteAllSupplyFormItems),
  fork(processExportSupplyFormItems),
  fork(processDeleteSupplyFormItem)]);
}
