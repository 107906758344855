import {
  PRODUCTS_SELECT_ALL,
  PRODUCTS_UNSELECT_ALL,
  PRODUCT_SELECTED,
  PRODUCTS_LOAD,
  PRODUCTS_LOAD_SUCCESS,
  PRODUCTS_LOAD_FAILED,
  PRODUCT_LOAD,
  PRODUCT_LOAD_SUCCESS,
  PRODUCT_LOAD_FAILED,
  PRODUCT_ADD,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAILED,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILED,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILED,
  PRODUCT_DELETE_ALL,
  PRODUCT_DELETE_ALL_SUCCESS,
  PRODUCT_DELETE_ALL_FAILED,
  PRODUCTS_EXPORT_EXCEL,
  PRODUCTS_EXPORT_EXCEL_SUCCESS,
  PRODUCTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedProduct: null,
  selectedProductId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRODUCTS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case PRODUCTS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case PRODUCT_SELECTED:
      return {
        ...state,
        selectedProduct: action.payload,
        selectedProductId: action.payload.id
      };
    case PRODUCTS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case PRODUCTS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case PRODUCTS_LOAD_FAILED:
      return {
        ...state
      };
    case PRODUCT_LOAD:
      return {
        ...state
      };
    case PRODUCT_LOAD_SUCCESS:
      return {
        ...state
      };
    case PRODUCT_LOAD_FAILED:
      return {
        ...state
      };
    case PRODUCT_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCT_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCT_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCT_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCT_DELETE:
      return {
        ...state
      };
    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PRODUCT_DELETE_FAILED:
      return {
        ...state
      };
    case PRODUCT_DELETE_ALL:
      return {
        ...state
      };
    case PRODUCT_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PRODUCT_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case PRODUCTS_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case PRODUCTS_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case PRODUCTS_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    default:
      return state;
  }
}
