import {
  SUPPLIES_SELECT_ALL,
  SUPPLIES_UNSELECT_ALL,
  SUPPLY_SELECTED,
  SUPPLIES_LOAD,
  SUPPLIES_LOAD_SUCCESS,
  SUPPLIES_LOAD_FAILED,
  SUPPLY_LOAD,
  SUPPLY_LOAD_SUCCESS,
  SUPPLY_LOAD_FAILED,
  SUPPLY_ADD,
  SUPPLY_ADD_SUCCESS,
  SUPPLY_ADD_FAILED,
  SUPPLY_UPDATE,
  SUPPLY_UPDATE_SUCCESS,
  SUPPLY_UPDATE_FAILED,
  SUPPLY_DELETE,
  SUPPLY_DELETE_SUCCESS,
  SUPPLY_DELETE_FAILED,
  SUPPLY_DELETE_ALL,
  SUPPLY_DELETE_ALL_SUCCESS,
  SUPPLY_DELETE_ALL_FAILED,
  SUPPLIES_EXPORT_EXCEL,
  SUPPLIES_EXPORT_EXCEL_SUCCESS,
  SUPPLIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSupplies = () => {
  return {
    type: SUPPLIES_SELECT_ALL
  }
}

export const unselectAllSupplies = () => {
  return {
    type: SUPPLIES_UNSELECT_ALL
  }
}

export const selectSupply = data => {
  return {
    type: SUPPLY_SELECTED,
    payload: data
  }
}

export const loadSupplies = (data) => {
  return {
    type: SUPPLIES_LOAD,
    payload: data
  };
}

export const loadSuppliesSuccess = (data) => {
  return {
    type: SUPPLIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSuppliesFailed = () => {
  return {
    type: SUPPLIES_LOAD_FAILED
  };
}

export const loadSupply = () => {
  return {
    type: SUPPLY_LOAD
  };
}

export const loadSupplySuccess = (data) => {
  return {
    type: SUPPLY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSupplyFailed = () => {
  return {
    type: SUPPLY_LOAD_FAILED
  };
}


export const addSupply = (data) => {
  return {
    type: SUPPLY_ADD,
    payload: data
  };
}


export const addSupplySuccess = (filter) => {
  return {
    type: SUPPLY_ADD_SUCCESS,
    message: 'Supply added successfully.',
    payload: filter
  };
}

export const addSupplyFailed = () => {
  return {
    type: SUPPLY_ADD_FAILED
  };
}

export const updateSupply = (data) => {
  return {
    type: SUPPLY_UPDATE,
    payload: data
  };
}


export const updateSupplySuccess = (data) => {
  return {
    type: SUPPLY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSupplyFailed = (data) => {
  return {
    type: SUPPLY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSupply = (data) => {
  return {
    type: SUPPLY_DELETE,
    payload: data
  };
}

export const deleteSupplySuccess = () => {
  return {
    type: SUPPLY_DELETE_SUCCESS,
    message: 'Supply deleted successfully.'
  };
}

export const deleteSupplyFailed = () => {
  return {
    type: SUPPLY_DELETE_FAILED
  };
}

export const deleteAllSupplies = (data) => {
  return {
    type: SUPPLY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSuppliesSuccess = () => {
  return {
    type: SUPPLY_DELETE_ALL_SUCCESS,
    message: 'Supply all deleted successfully.'
  };
}

export const deleteAllSuppliesFailed = () => {
  return {
    type: SUPPLY_DELETE_ALL_FAILED
  };
}

export const exportExcelSupplies = (filter) => {
  return {
    type:SUPPLIES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSuppliesSuccess = (data) => {
  return {
    type:SUPPLIES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSuppliesFailed = () => {
  return {
    type:SUPPLIES_EXPORT_EXCEL_FAILED
  };
}
