import {
  DOCUMENTCATEGORIES_SELECT_ALL,
  DOCUMENTCATEGORIES_UNSELECT_ALL,
  DOCUMENTCATEGORY_SELECTED,
  DOCUMENTCATEGORIES_LOAD,
  DOCUMENTCATEGORIES_LOAD_SUCCESS,
  DOCUMENTCATEGORIES_LOAD_FAILED,
  DOCUMENTCATEGORY_LOAD,
  DOCUMENTCATEGORY_LOAD_SUCCESS,
  DOCUMENTCATEGORY_LOAD_FAILED,
  DOCUMENTCATEGORY_ADD,
  DOCUMENTCATEGORY_ADD_SUCCESS,
  DOCUMENTCATEGORY_ADD_FAILED,
  DOCUMENTCATEGORY_UPDATE,
  DOCUMENTCATEGORY_UPDATE_SUCCESS,
  DOCUMENTCATEGORY_UPDATE_FAILED,
  DOCUMENTCATEGORY_DELETE,
  DOCUMENTCATEGORY_DELETE_SUCCESS,
  DOCUMENTCATEGORY_DELETE_FAILED,
  DOCUMENTCATEGORY_DELETE_ALL,
  DOCUMENTCATEGORY_DELETE_ALL_SUCCESS,
  DOCUMENTCATEGORY_DELETE_ALL_FAILED,
  DOCUMENTCATEGORIES_EXPORT_EXCEL,
  DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS,
  DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedDocumentCategory: null,
  selectedDocumentCategoryId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'createdDate',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOCUMENTCATEGORIES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case DOCUMENTCATEGORIES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case DOCUMENTCATEGORY_SELECTED:
      return {
        ...state,
        selectedDocumentCategory: action.payload,
        selectedDocumentCategoryId: action.payload.id
      };
    case DOCUMENTCATEGORIES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case DOCUMENTCATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case DOCUMENTCATEGORIES_LOAD_FAILED:
      return {
        ...state
      };
    case DOCUMENTCATEGORY_LOAD:
      return {
        ...state
      };
    case DOCUMENTCATEGORY_LOAD_SUCCESS:
      return {
        ...state
      };
    case DOCUMENTCATEGORY_LOAD_FAILED:
      return {
        ...state
      };
    case DOCUMENTCATEGORY_ADD:
      return {
        ...state,
        hideLoading: false,
        selectedDocumentCategory: undefined
      };
    case DOCUMENTCATEGORY_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedDocumentCategory: action.payload
      };
    case DOCUMENTCATEGORY_ADD_FAILED:
      return {
        ...state,
        hideLoading: true,
        selectedDocumentCategory: undefined
      };
    case DOCUMENTCATEGORY_UPDATE:
      return {
        ...state,
        hideLoading: false,
        selectedDocumentCategory: undefined
      };
    case DOCUMENTCATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedDocumentCategory: action.payload
      };
    case DOCUMENTCATEGORY_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true,
        selectedDocumentCategory: undefined
      };
    case DOCUMENTCATEGORY_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case DOCUMENTCATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case DOCUMENTCATEGORY_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case DOCUMENTCATEGORY_DELETE_ALL:
      return {
        ...state
      };
    case DOCUMENTCATEGORY_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case DOCUMENTCATEGORY_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case DOCUMENTCATEGORIES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
