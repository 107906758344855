import {
  LISTABLES_SELECT_ALL,
  LISTABLES_UNSELECT_ALL,
  LISTABLE_SELECTED,
  LISTABLES_LOAD,
  LISTABLES_LOAD_SUCCESS,
  LISTABLES_LOAD_FAILED,
  LISTABLE_LOAD,
  LISTABLE_LOAD_SUCCESS,
  LISTABLE_LOAD_FAILED,
  LISTABLE_ADD,
  LISTABLE_ADD_SUCCESS,
  LISTABLE_ADD_FAILED,
  LISTABLE_UPDATE,
  LISTABLE_UPDATE_SUCCESS,
  LISTABLE_UPDATE_FAILED,
  LISTABLE_DELETE,
  LISTABLE_DELETE_SUCCESS,
  LISTABLE_DELETE_FAILED,
  LISTABLE_DELETE_ALL,
  LISTABLE_DELETE_ALL_SUCCESS,
  LISTABLE_DELETE_ALL_FAILED,
  LISTABLES_EXPORT_EXCEL,
  LISTABLES_EXPORT_EXCEL_SUCCESS,
  LISTABLES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllListables = () => {
  return {
    type: LISTABLES_SELECT_ALL
  }
}

export const unselectAllListables = () => {
  return {
    type: LISTABLES_UNSELECT_ALL
  }
}

export const selectListable = data => {
  return {
    type: LISTABLE_SELECTED,
    payload: data
  }
}

export const loadListables = (data) => {
  return {
    type: LISTABLES_LOAD,
    payload: data
  };
}

export const loadListablesSuccess = (data) => {
  return {
    type: LISTABLES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadListablesFailed = () => {
  return {
    type: LISTABLES_LOAD_FAILED
  };
}

export const loadListable = () => {
  return {
    type: LISTABLE_LOAD
  };
}

export const loadListableSuccess = (data) => {
  return {
    type: LISTABLE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadListableFailed = () => {
  return {
    type: LISTABLE_LOAD_FAILED
  };
}


export const addListable = (data) => {
  return {
    type: LISTABLE_ADD,
    payload: data
  };
}


export const addListableSuccess = (filter) => {
  return {
    type: LISTABLE_ADD_SUCCESS,
    message: 'Listable added successfully.',
    payload: filter
  };
}

export const addListableFailed = () => {
  return {
    type: LISTABLE_ADD_FAILED
  };
}

export const updateListable = (data) => {
  return {
    type: LISTABLE_UPDATE,
    payload: data
  };
}


export const updateListableSuccess = (data) => {
  return {
    type: LISTABLE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateListableFailed = (data) => {
  return {
    type: LISTABLE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteListable = (data) => {
  return {
    type: LISTABLE_DELETE,
    payload: data
  };
}

export const deleteListableSuccess = () => {
  return {
    type: LISTABLE_DELETE_SUCCESS,
    message: 'Listable deleted successfully.'
  };
}

export const deleteListableFailed = () => {
  return {
    type: LISTABLE_DELETE_FAILED
  };
}

export const deleteAllListables = (data) => {
  return {
    type: LISTABLE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllListablesSuccess = () => {
  return {
    type: LISTABLE_DELETE_ALL_SUCCESS,
    message: 'Listable all deleted successfully.'
  };
}

export const deleteAllListablesFailed = () => {
  return {
    type: LISTABLE_DELETE_ALL_FAILED
  };
}

export const exportExcelListables = (filter) => {
  return {
    type: LISTABLES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelListablesSuccess = (data) => {
  return {
    type: LISTABLES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelListablesFailed = () => {
  return {
    type: LISTABLES_EXPORT_EXCEL_FAILED
  };
}
