import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadReceiptVouchers as loadReceiptVouchersAction,
  loadReceiptVouchersSuccess,
  addReceiptVoucherSuccess,
  updateReceiptVoucherSuccess,
  exportExcelReceiptVouchersSuccess,
  reportReceiptVouchersSuccess
} from '../actions/ReceiptVoucher';

import {
  RECEIPTVOUCHERS_LOAD,
  RECEIPTVOUCHER_ADD,
  RECEIPTVOUCHER_UPDATE,
  RECEIPTVOUCHER_DELETE,
  RECEIPTVOUCHER_DELETE_ALL,
  RECEIPTVOUCHERS_EXPORT_EXCEL,
  RECEIPTVOUCHERS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getReceiptVoucherById = (id, callback) => {
  Api().get('receipt-voucher/' + id).then(response => {
    callback(response.data);
  })
}

export const changeReceiptVoucherStatus = (model, callback) => {
  Api().post('receipt-voucher/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllReceiptVouchers = (callback) => {
  Api().post('receipt-voucher/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxReceiptVoucherCodeSorted = (callback) => {
  Api().post('receipt-voucher/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadReceiptVouchersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`receipt-voucher/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`receipt-voucher`, { params })
    .then(data => data)
    .catch(error => error);
}


const addReceiptVoucherRequest = async (payload) =>
  await Api().post(`receipt-voucher`, payload)
    .then(data => data)
    .catch(error => error);

const updateReceiptVoucherRequest = async (payload) =>
  await Api().patch(`receipt-voucher/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteReceiptVoucherRequest = async (id) =>
  await Api().delete(`receipt-voucher/${id}`)
    .then(data => data)
    .catch(error => error);

const exportReceiptVouchersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`receipt-voucher/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadReceiptVouchers({ payload }) {
  try {

    const data = yield call(loadReceiptVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadReceiptVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadReceiptVouchers() {
  yield takeEvery(RECEIPTVOUCHERS_LOAD, loadReceiptVouchers);
}

function* loadReceiptVoucher({ selectedReceiptVoucherId }) {
  try {

    const data = yield call(loadReceiptVouchersRequest, selectedReceiptVoucherId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadReceiptVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadReceiptVoucher() {
  yield takeEvery(RECEIPTVOUCHERS_LOAD, loadReceiptVoucher);
}


function* addReceiptVoucher({ payload }) {
  try {
    const data = yield call(addReceiptVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addReceiptVoucherSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddReceiptVoucher() {
  yield takeEvery(RECEIPTVOUCHER_ADD, addReceiptVoucher);
}


function* updateReceiptVoucher({ payload }) {
  try {
    const data = yield call(updateReceiptVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateReceiptVoucherSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateReceiptVoucher() {
  yield takeEvery(RECEIPTVOUCHER_UPDATE, updateReceiptVoucher);
}

function* deleteReceiptVoucher({ payload }) {
  try {
    const data = yield call(deleteReceiptVoucherRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadReceiptVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllReceiptVouchers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteReceiptVoucherRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadReceiptVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteReceiptVoucher() {
  yield takeEvery(RECEIPTVOUCHER_DELETE, deleteReceiptVoucher);
}

function* processDeleteAllReceiptVouchers() {
  yield takeEvery(RECEIPTVOUCHER_DELETE_ALL, deleteAllReceiptVouchers);
}

function* exportReceiptVouchers({ payload }) {
  try {
    const data = yield call(exportReceiptVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelReceiptVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportReceiptVouchers() {
  yield takeEvery(RECEIPTVOUCHERS_EXPORT_EXCEL, exportReceiptVouchers);
}








const reportReceiptVouchersRequest = async (params) => {
  return await Api().post(`receipt-voucher/report`, params)
    .then(data => data)
    .catch(error => error);
}

function* reportReceiptVouchers({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportReceiptVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportReceiptVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportReceiptVouchers() {
  yield takeEvery(RECEIPTVOUCHERS_REPORT, reportReceiptVouchers);
}
export default function* ReceiptVoucherSagas() {
  yield all([fork(processLoadReceiptVouchers),
  fork(processLoadReceiptVoucher),
  fork(processAddReceiptVoucher),
  fork(processUpdateReceiptVoucher),
  fork(processDeleteAllReceiptVouchers),
  fork(processDeleteReceiptVoucher),
  fork(processExportReceiptVouchers),
  fork(processReportReceiptVouchers)]);
}
