import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE, LISTABLE_SETTING_STATUS } from '../constants/AppConstant';

import {
  loadListableSettings as loadListableSettingsAction,
  loadListableSettingsSuccess,
  addListableSettingSuccess,
  updateListableSettingSuccess,
  exportExcelListableSettingsSuccess,
  reportListableSettingsSuccess
} from '../actions/ListableSetting';

import {
  LISTABLESETTINGS_LOAD,
  LISTABLESETTING_ADD,
  LISTABLESETTING_UPDATE,
  LISTABLESETTING_DELETE,
  LISTABLESETTING_DELETE_ALL,
  LISTABLESETTINGS_EXPORT_EXCEL,
  LISTABLESETTINGS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getListableSettingById = (id, callback) => {
  Api().get('listable-setting/' + id).then(response => {
    callback(response.data);
  })
}

export const getListableSettingByCustomerId = (customerId, callback, filterGroups = null) => {
  if (filterGroups) {
    Api().post('listable-setting/search', {
      limit: GET_ALL_SIZE,
      filterGroups: filterGroups
    }).then(response => {
      callback(response.data.records);    
    })
  }
  else {
    Api().post('listable-setting/search', {
      limit: GET_ALL_SIZE,
      filterGroups: [{
        filters: [{
          property: 'customerId',
          operator: 'equal',
          value: customerId
        },{
          property: 'status',
          operator: 'equal',
          value: LISTABLE_SETTING_STATUS.INPROGRESS
        }]
      }]
    }).then(response => {
      callback(response.data.records);    
    })
  }
}

export const changeListableSettingStatus = (model, callback) => {
  Api().post('listable-setting/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllListableSettings = (callback) => {
  Api().post('listable-setting/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxListableSettingCodeSorted = (callback) => {
  Api().post('listable-setting/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadListableSettingsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`listable-setting/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`listable-setting`, { params })
    .then(data => data)
    .catch(error => error);
}


const addListableSettingRequest = async (payload) =>
  await Api().post(`listable-setting`, payload)
    .then(data => data)
    .catch(error => error);

const updateListableSettingRequest = async (payload) =>
  await Api().patch(`listable-setting/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteListableSettingRequest = async (id) =>
  await Api().delete(`listable-setting/${id}`)
    .then(data => data)
    .catch(error => error);

const exportListableSettingsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`listable-setting/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadListableSettings({ payload }) {
  try {

    const data = yield call(loadListableSettingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadListableSettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadListableSettings() {
  yield takeEvery(LISTABLESETTINGS_LOAD, loadListableSettings);
}

function* loadListableSetting({ selectedListableSettingId }) {
  try {

    const data = yield call(loadListableSettingsRequest, selectedListableSettingId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadListableSettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadListableSetting() {
  yield takeEvery(LISTABLESETTINGS_LOAD, loadListableSetting);
}


function* addListableSetting({ payload }) {
  try {
    const data = yield call(addListableSettingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addListableSettingSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddListableSetting() {
  yield takeEvery(LISTABLESETTING_ADD, addListableSetting);
}


function* updateListableSetting({ payload }) {
  try {
    const data = yield call(updateListableSettingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateListableSettingSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateListableSetting() {
  yield takeEvery(LISTABLESETTING_UPDATE, updateListableSetting);
}

function* deleteListableSetting({ payload }) {
  try {
    const data = yield call(deleteListableSettingRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadListableSettingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllListableSettings({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteListableSettingRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadListableSettingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteListableSetting() {
  yield takeEvery(LISTABLESETTING_DELETE, deleteListableSetting);
}

function* processDeleteAllListableSettings() {
  yield takeEvery(LISTABLESETTING_DELETE_ALL, deleteAllListableSettings);
}

function* exportListableSettings({ payload }) {
  try {
    const data = yield call(exportListableSettingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelListableSettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportListableSettings() {
  yield takeEvery(LISTABLESETTINGS_EXPORT_EXCEL, exportListableSettings);
}








const reportListableSettingsRequest = async (params) => {
  return await Api().post(`listable-setting/report`, params)
    .then(data => data)
    .catch(error => error);
}

function* reportListableSettings({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportListableSettingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportListableSettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportListableSettings() {
  yield takeEvery(LISTABLESETTINGS_REPORT, reportListableSettings);
}
export default function* ListableSettingSagas() {
  yield all([fork(processLoadListableSettings),
  fork(processLoadListableSetting),
  fork(processAddListableSetting),
  fork(processUpdateListableSetting),
  fork(processDeleteAllListableSettings),
  fork(processDeleteListableSetting),
  fork(processExportListableSettings),
  fork(processReportListableSettings)]);
}
