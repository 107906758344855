import {
  DOCUMENTCATEGORIES_SELECT_ALL,
  DOCUMENTCATEGORIES_UNSELECT_ALL,
  DOCUMENTCATEGORY_SELECTED,
  DOCUMENTCATEGORIES_LOAD,
  DOCUMENTCATEGORIES_LOAD_SUCCESS,
  DOCUMENTCATEGORIES_LOAD_FAILED,
  DOCUMENTCATEGORY_LOAD,
  DOCUMENTCATEGORY_LOAD_SUCCESS,
  DOCUMENTCATEGORY_LOAD_FAILED,
  DOCUMENTCATEGORY_ADD,
  DOCUMENTCATEGORY_ADD_SUCCESS,
  DOCUMENTCATEGORY_ADD_FAILED,
  DOCUMENTCATEGORY_UPDATE,
  DOCUMENTCATEGORY_UPDATE_SUCCESS,
  DOCUMENTCATEGORY_UPDATE_FAILED,
  DOCUMENTCATEGORY_DELETE,
  DOCUMENTCATEGORY_DELETE_SUCCESS,
  DOCUMENTCATEGORY_DELETE_FAILED,
  DOCUMENTCATEGORY_DELETE_ALL,
  DOCUMENTCATEGORY_DELETE_ALL_SUCCESS,
  DOCUMENTCATEGORY_DELETE_ALL_FAILED,
  DOCUMENTCATEGORIES_EXPORT_EXCEL,
  DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS,
  DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllDocumentCategories = () => {
  return {
    type: DOCUMENTCATEGORIES_SELECT_ALL
  }
}

export const unselectAllDocumentCategories = () => {
  return {
    type: DOCUMENTCATEGORIES_UNSELECT_ALL
  }
}

export const selectDocumentCategory = data => {
  return {
    type: DOCUMENTCATEGORY_SELECTED,
    payload: data
  }
}

export const loadDocumentCategories = (data) => {
  return {
    type: DOCUMENTCATEGORIES_LOAD,
    payload: data
  };
}

export const loadDocumentCategoriesSuccess = (data) => {
  return {
    type: DOCUMENTCATEGORIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadDocumentCategoriesFailed = () => {
  return {
    type: DOCUMENTCATEGORIES_LOAD_FAILED
  };
}

export const loadDocumentCategory = () => {
  return {
    type: DOCUMENTCATEGORY_LOAD
  };
}

export const loadDocumentCategorySuccess = (data) => {
  return {
    type: DOCUMENTCATEGORY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadDocumentCategoryFailed = () => {
  return {
    type: DOCUMENTCATEGORY_LOAD_FAILED
  };
}


export const addDocumentCategory = (data) => {
  return {
    type: DOCUMENTCATEGORY_ADD,
    payload: data
  };
}


export const addDocumentCategorySuccess = (filter) => {
  return {
    type: DOCUMENTCATEGORY_ADD_SUCCESS,
    message: 'DocumentCategory added successfully.',
    payload: filter
  };
}

export const addDocumentCategoryFailed = () => {
  return {
    type: DOCUMENTCATEGORY_ADD_FAILED
  };
}

export const updateDocumentCategory = (data) => {
  return {
    type: DOCUMENTCATEGORY_UPDATE,
    payload: data
  };
}


export const updateDocumentCategorySuccess = (data) => {
  return {
    type: DOCUMENTCATEGORY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateDocumentCategoryFailed = (data) => {
  return {
    type: DOCUMENTCATEGORY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteDocumentCategory = (data) => {
  return {
    type: DOCUMENTCATEGORY_DELETE,
    payload: data
  };
}

export const deleteDocumentCategorySuccess = () => {
  return {
    type: DOCUMENTCATEGORY_DELETE_SUCCESS,
    message: 'DocumentCategory deleted successfully.'
  };
}

export const deleteDocumentCategoryFailed = () => {
  return {
    type: DOCUMENTCATEGORY_DELETE_FAILED
  };
}

export const deleteAllDocumentCategories = (data) => {
  return {
    type: DOCUMENTCATEGORY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllDocumentCategoriesSuccess = () => {
  return {
    type: DOCUMENTCATEGORY_DELETE_ALL_SUCCESS,
    message: 'DocumentCategory all deleted successfully.'
  };
}

export const deleteAllDocumentCategoriesFailed = () => {
  return {
    type: DOCUMENTCATEGORY_DELETE_ALL_FAILED
  };
}

export const exportExcelDocumentCategories = (filter) => {
  return {
    type: DOCUMENTCATEGORIES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelDocumentCategoriesSuccess = (data) => {
  return {
    type: DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelDocumentCategoriesFailed = () => {
  return {
    type: DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED
  };
}
