import {
  SUPPLYFORMS_SELECT_ALL,
  SUPPLYFORMS_UNSELECT_ALL,
  SUPPLYFORM_SELECTED,
  SUPPLYFORMS_LOAD,
  SUPPLYFORMS_LOAD_SUCCESS,
  SUPPLYFORMS_LOAD_FAILED,
  SUPPLYFORM_LOAD,
  SUPPLYFORM_LOAD_SUCCESS,
  SUPPLYFORM_LOAD_FAILED,
  SUPPLYFORM_ADD,
  SUPPLYFORM_ADD_SUCCESS,
  SUPPLYFORM_ADD_FAILED,
  SUPPLYFORM_UPDATE,
  SUPPLYFORM_UPDATE_SUCCESS,
  SUPPLYFORM_UPDATE_FAILED,
  SUPPLYFORM_DELETE,
  SUPPLYFORM_DELETE_SUCCESS,
  SUPPLYFORM_DELETE_FAILED,
  SUPPLYFORM_DELETE_ALL,
  SUPPLYFORM_DELETE_ALL_SUCCESS,
  SUPPLYFORM_DELETE_ALL_FAILED,
  SUPPLYFORMS_EXPORT_EXCEL,
  SUPPLYFORMS_EXPORT_EXCEL_SUCCESS,
  SUPPLYFORMS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSupplyForm: null,
  selectedSupplyFormId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLYFORMS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SUPPLYFORMS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SUPPLYFORM_SELECTED:
      return {
        ...state,
        selectedSupplyForm: action.payload,
        selectedSupplyFormId: action.payload.id
      };
    case SUPPLYFORMS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SUPPLYFORMS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SUPPLYFORMS_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYFORM_LOAD:
      return {
        ...state
      };
    case SUPPLYFORM_LOAD_SUCCESS:
      return {
        ...state
      };
    case SUPPLYFORM_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYFORM_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYFORM_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedSupplyForm: action.payload
      };
    case SUPPLYFORM_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORM_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYFORM_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORM_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORM_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYFORM_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case SUPPLYFORM_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORM_DELETE_ALL:
      return {
        ...state
      };
    case SUPPLYFORM_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SUPPLYFORM_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case SUPPLYFORMS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case SUPPLYFORMS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case SUPPLYFORMS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
