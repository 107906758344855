import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadQuotationWastes as loadQuotationWastesAction,
  loadQuotationWastesSuccess,
  updateQuotationWasteSuccess,
  addQuotationWasteSuccess,
  deleteQuotationWasteSuccess,
  deleteAllQuotationWastesSuccess
} from '../actions/QuotationWaste';

import {
  QUOTATIONWASTES_LOAD,
  QUOTATIONWASTE_ADD,
  QUOTATIONWASTE_UPDATE,
  QUOTATIONWASTE_DELETE,
  QUOTATIONWASTE_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getQuotationWasteById = (id, callback) => {
  Api().get('quotation-waste/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllQuotationWastes = (callback, params) => {
  if (!params) {
    params = {
      offset: 0,
      limit: GET_ALL_SIZE,
      orderBy: 'wasteCode',
      descending: false
    }
  }
  
  Api().post('quotation-waste/search', {
    ...params,
    offset: 0,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadQuotationWastesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`quotation-waste/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`quotation-waste`, { params })
    .then(data => data)
    .catch(error => error);
}

const addQuotationWasteRequest = async (payload) =>
  await Api().post(`quotation-waste`, payload)
    .then(data => data)
    .catch(error => error);

const updateQuotationWasteRequest = async (payload) =>
  await Api().patch(`quotation-waste/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteQuotationWasteRequest = async (id) =>
  await Api().delete(`quotation-waste/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadQuotationWastes({ payload }) {
  try {
    const data = yield call(loadQuotationWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadQuotationWastes() {
  yield takeEvery(QUOTATIONWASTES_LOAD, loadQuotationWastes);
}

function* loadQuotationWaste({ selectedQuotationWasteId }) {
  try {
    const data = yield call(loadQuotationWastesRequest, selectedQuotationWasteId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadQuotationWaste() {
  yield takeEvery(QUOTATIONWASTES_LOAD, loadQuotationWaste);
}

function* addQuotationWaste({ payload }) {
  try {
    const data = yield call(addQuotationWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddQuotationWaste() {
  yield takeEvery(QUOTATIONWASTE_ADD, addQuotationWaste);
}

function* updateQuotationWaste({ payload }) {
  try {
    const data = yield call(updateQuotationWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateQuotationWaste() {
  yield takeEvery(QUOTATIONWASTE_UPDATE, updateQuotationWaste);
}

function* deleteQuotationWaste({ payload }) {
  try {
    const data = yield call(deleteQuotationWasteRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(deleteQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllQuotationWastes({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteQuotationWasteRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllQuotationWastesSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteQuotationWaste() {
  yield takeEvery(QUOTATIONWASTE_DELETE, deleteQuotationWaste);
}

function* processDeleteAllQuotationWastes() {
  yield takeEvery(QUOTATIONWASTE_DELETE_ALL, deleteAllQuotationWastes);
}

export default function* QuotationWasteSagas() {
  yield all([fork(processLoadQuotationWastes),
  fork(processLoadQuotationWaste),
  fork(processAddQuotationWaste),
  fork(processUpdateQuotationWaste),
  fork(processDeleteAllQuotationWastes),
  fork(processDeleteQuotationWaste)]);
}
