import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE, MOMENT_DATE_FORMAT, SQL_DATE_FORMAT } from '../constants/AppConstant';
import Moment from 'moment';

import {
  loadHealthDeclarations as loadHealthDeclarationsAction,
  loadHealthDeclarationsSuccess,
  addHealthDeclarationSuccess,
  updateHealthDeclarationSuccess,
  exportExcelHealthDeclarations,
  exportExcelHealthDeclarationsSuccess,
  exportExcelHealthDeclarationsFailed
} from '../actions/HealthDeclaration';

import {
  HEALTHDECLARATIONS_LOAD,
  HEALTHDECLARATION_ADD,
  HEALTHDECLARATION_UPDATE,
  HEALTHDECLARATION_DELETE,
  HEALTHDECLARATION_DELETE_ALL,
  HEALTHDECLARATIONS_EXPORT_EXCEL,
  HEALTHDECLARATIONS_EXPORT_EXCEL_SUCCESS,
  HEALTHDECLARATIONS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getHealthDeclarationById = (id, callback) => {
  Api().get('health-declaration/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllHealthDeclarations = (callback) => {
  Api().post('health-declaration/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const multipleUpdateHealthDeclaration = (model, callback) => {
  Api().post('health-declaration/multiple-update', model).then(response => {
    callback(response);
  })
}

export const getHealthDeclarationsByCreatedDate = (createdDate, callback) => {
  var startDate = Moment(createdDate).format(SQL_DATE_FORMAT);
  var endDate = Moment(startDate).add(1, 'day').format(SQL_DATE_FORMAT)

  Api().post('health-declaration/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'createdDate',
        operator: '>=',
        value: startDate,
      }, {
        property: 'createdDate',
        operator: '<',
        value: endDate,
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

export const getHealthDeclarationsByCompany = (companyId, callback) => {
  Api().post('health-declaration/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId,
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

export const getMaxHealthDeclarationCodeSorted = (callback) => {
  Api().post('health-declaration/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

export const addHealthDeclarationForm = (payload, callback, errorCallback) => {
  Api().post(`health-declaration`, payload)
    .then(data => {
      callback(data);
    })
    .catch(error => {
      errorCallback(error);
    });
} 

const loadHealthDeclarationsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`health-declaration/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`health-declaration`, { params })
    .then(data => data)
    .catch(error => error);
}

const addHealthDeclarationRequest = async (payload) =>
  await Api().post(`health-declaration`, payload)
    .then(data => data)
    .catch(error => error);

const updateHealthDeclarationRequest = async (payload) =>
  await Api().patch(`health-declaration/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteHealthDeclarationRequest = async (id) =>
  await Api().delete(`health-declaration/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadHealthDeclarations({ payload }) {
  try {
    const data = yield call(loadHealthDeclarationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadHealthDeclarationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadHealthDeclarations() {
  yield takeEvery(HEALTHDECLARATIONS_LOAD, loadHealthDeclarations);
}

function* loadHealthDeclaration({ selectedHealthDeclarationId }) {
  try {
    const data = yield call(loadHealthDeclarationsRequest, selectedHealthDeclarationId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadHealthDeclarationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadHealthDeclaration() {
  yield takeEvery(HEALTHDECLARATIONS_LOAD, loadHealthDeclaration);
}


function* addHealthDeclaration({ payload }) {
  try {
    const data = yield call(addHealthDeclarationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addHealthDeclarationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddHealthDeclaration() {
  yield takeEvery(HEALTHDECLARATION_ADD, addHealthDeclaration);
}


function* updateHealthDeclaration({ payload }) {
  try {
    const data = yield call(updateHealthDeclarationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateHealthDeclarationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateHealthDeclaration() {
  yield takeEvery(HEALTHDECLARATION_UPDATE, updateHealthDeclaration);
}

function* deleteHealthDeclaration({ payload }) {
  try {
    const data = yield call(deleteHealthDeclarationRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadHealthDeclarationsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllHealthDeclarations({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteHealthDeclarationRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadHealthDeclarationsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteHealthDeclaration() {
  yield takeEvery(HEALTHDECLARATION_DELETE, deleteHealthDeclaration);
}

function* processDeleteAllHealthDeclarations() {
  yield takeEvery(HEALTHDECLARATION_DELETE_ALL, deleteAllHealthDeclarations);
}


const exportHealthDeclarationsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`health-declaration/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportHealthDeclarations({ payload }) {
  try {
    const data = yield call(exportHealthDeclarationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelHealthDeclarationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportHealthDeclarations() {
  yield takeEvery(HEALTHDECLARATIONS_EXPORT_EXCEL, exportHealthDeclarations);
}

export default function* HealthDeclarationSagas() {
  yield all([fork(processLoadHealthDeclarations),
  fork(processLoadHealthDeclaration),
  fork(processAddHealthDeclaration),
  fork(processUpdateHealthDeclaration),
  fork(processDeleteAllHealthDeclarations),
  fork(processDeleteHealthDeclaration),
  fork(processExportHealthDeclarations)
]);
}
