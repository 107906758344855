import {
  CUSTOMERREGS_SELECT_ALL,
  CUSTOMERREGS_UNSELECT_ALL,
  CUSTOMERREG_SELECTED,
  CUSTOMERREGS_LOAD,
  CUSTOMERREGS_LOAD_SUCCESS,
  CUSTOMERREGS_LOAD_FAILED,
  CUSTOMERREG_LOAD,
  CUSTOMERREG_LOAD_SUCCESS,
  CUSTOMERREG_LOAD_FAILED,
  CUSTOMERREG_ADD,
  CUSTOMERREG_ADD_SUCCESS,
  CUSTOMERREG_ADD_FAILED,
  CUSTOMERREG_UPDATE,
  CUSTOMERREG_UPDATE_SUCCESS,
  CUSTOMERREG_UPDATE_FAILED,
  CUSTOMERREG_DELETE,
  CUSTOMERREG_DELETE_SUCCESS,
  CUSTOMERREG_DELETE_FAILED,
  CUSTOMERREG_DELETE_ALL,
  CUSTOMERREG_DELETE_ALL_SUCCESS,
  CUSTOMERREG_DELETE_ALL_FAILED,
  CUSTOMERREGS_EXPORT_EXCEL,
  CUSTOMERREGS_EXPORT_EXCEL_SUCCESS,
  CUSTOMERREGS_EXPORT_EXCEL_FAILED,
  CUSTOMERREGS_REPORT,
  CUSTOMERREGS_REPORT_SUCCESS,
  CUSTOMERREGS_REPORT_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  reportData: [],
  selectedCustomerReg: null,
  selectedCustomerRegId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMERREGS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case CUSTOMERREGS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case CUSTOMERREG_SELECTED:
      return {
        ...state,
        selectedCustomerReg: action.payload,
        selectedCustomerRegId: action.payload.id
      };
    case CUSTOMERREGS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case CUSTOMERREGS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case CUSTOMERREGS_LOAD_FAILED:
      return {
        ...state
      };
    case CUSTOMERREG_LOAD:
      return {
        ...state
      };
    case CUSTOMERREG_LOAD_SUCCESS:
      return {
        ...state
      };
    case CUSTOMERREG_LOAD_FAILED:
      return {
        ...state
      };
    case CUSTOMERREG_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case CUSTOMERREG_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMERREG_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMERREG_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case CUSTOMERREG_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMERREG_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMERREG_DELETE:
      return {
        ...state
      };
    case CUSTOMERREG_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case CUSTOMERREG_DELETE_FAILED:
      return {
        ...state
      };
    case CUSTOMERREG_DELETE_ALL:
      return {
        ...state
      };
    case CUSTOMERREG_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case CUSTOMERREG_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case CUSTOMERREGS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case CUSTOMERREGS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case CUSTOMERREGS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case CUSTOMERREGS_REPORT:
      return {
        ...state,
        reportData: []
      };
    case CUSTOMERREGS_REPORT_SUCCESS:
      return {
        ...state,
        reportData: action.payload
      };
    case CUSTOMERREGS_REPORT_FAILED:
      return {
        ...state,
        reportData: []
      };
    default:
      return state;
  }
}
