import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadAdvanceSalaries as loadAdvanceSalariesAction,
  loadAdvanceSalariesSuccess,
  addAdvanceSalarySuccess,
  updateAdvanceSalarySuccess,
  exportExcelAdvanceSalaries,
  exportExcelAdvanceSalariesSuccess,
  exportExcelAdvanceSalariesFailed
} from '../actions/AdvanceSalary';

import {
  ADVANCESALARIES_LOAD,
  ADVANCESALARY_ADD,
  ADVANCESALARY_UPDATE,
  ADVANCESALARY_DELETE,
  ADVANCESALARY_DELETE_ALL,
  ADVANCESALARIES_EXPORT_EXCEL,
  ADVANCESALARIES_EXPORT_EXCEL_SUCCESS,
  ADVANCESALARIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getAdvanceSalaryById = (id, callback) => {
  Api().get('advanceSalary/' + id).then(response => {
    callback(response.data);
  })
}

export const changeAdvanceSalaryStatus = (model, callback) => {
  Api().post('advanceSalary/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllAdvanceSalaries = (callback) => {
  Api().post('advanceSalary/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getAdvanceSalariesByCompany = (type, company, callback) => {
  Api().post('advanceSalary/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxAdvanceSalaryCodeSorted = (callback) => {
  Api().post('advanceSalary/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadAdvanceSalariesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`advanceSalary/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`advanceSalary`, { params })
    .then(data => data)
    .catch(error => error);
}

const addAdvanceSalaryRequest = async (payload) =>
  await Api().post(`advanceSalary`, payload)
    .then(data => data)
    .catch(error => error);

const updateAdvanceSalaryRequest = async (payload) =>
  await Api().patch(`advanceSalary/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteAdvanceSalaryRequest = async (id) =>
  await Api().delete(`advanceSalary/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadAdvanceSalaries({ payload }) {
  try {
    const data = yield call(loadAdvanceSalariesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAdvanceSalariesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAdvanceSalaries() {
  yield takeEvery(ADVANCESALARIES_LOAD, loadAdvanceSalaries);
}

function* loadAdvanceSalary({ selectedAdvanceSalaryId }) {
  try {
    const data = yield call(loadAdvanceSalariesRequest, selectedAdvanceSalaryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAdvanceSalariesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAdvanceSalary() {
  yield takeEvery(ADVANCESALARIES_LOAD, loadAdvanceSalary);
}


function* addAdvanceSalary({ payload }) {
  try {
    const data = yield call(addAdvanceSalaryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addAdvanceSalarySuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddAdvanceSalary() {
  yield takeEvery(ADVANCESALARY_ADD, addAdvanceSalary);
}


function* updateAdvanceSalary({ payload }) {
  try {
    const data = yield call(updateAdvanceSalaryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateAdvanceSalarySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateAdvanceSalary() {
  yield takeEvery(ADVANCESALARY_UPDATE, updateAdvanceSalary);
}

function* deleteAdvanceSalary({ payload }) {
  try {
    const data = yield call(deleteAdvanceSalaryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAdvanceSalariesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllAdvanceSalaries({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteAdvanceSalaryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadAdvanceSalariesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteAdvanceSalary() {
  yield takeEvery(ADVANCESALARY_DELETE, deleteAdvanceSalary);
}

function* processDeleteAllAdvanceSalaries() {
  yield takeEvery(ADVANCESALARY_DELETE_ALL, deleteAllAdvanceSalaries);
}


const exportAdvanceSalariesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`advanceSalary/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportAdvanceSalaries({ payload }) {
  try {
    const data = yield call(exportAdvanceSalariesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelAdvanceSalariesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportAdvanceSalaries() {
  yield takeEvery(ADVANCESALARIES_EXPORT_EXCEL, exportAdvanceSalaries);
}

export default function* AdvanceSalarySagas() {
  yield all([fork(processLoadAdvanceSalaries),
  fork(processLoadAdvanceSalary),
  fork(processAddAdvanceSalary),
  fork(processUpdateAdvanceSalary),
  fork(processDeleteAllAdvanceSalaries),
  fork(processDeleteAdvanceSalary),
  fork(processExportAdvanceSalaries)
]);
}
