import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadFormReceiveQuotationWastes as loadFormReceiveQuotationWastesAction,
  loadFormReceiveQuotationWastesSuccess,
  updateFormReceiveQuotationWasteSuccess,
  addFormReceiveQuotationWasteSuccess,
  deleteFormReceiveQuotationWasteSuccess,
  deleteAllFormReceiveQuotationWastesSuccess,
  exportExcelFormReceiveQuotationWastes,
  exportExcelFormReceiveQuotationWastesSuccess,
  exportExcelFormReceiveQuotationWastesFailed,
  sumFormReceiveQuotationWastesSuccess
} from '../actions/FormReceiveQuotationWaste';

import {
  FORMRECEIVEQUOTATIONWASTES_LOAD,
  FORMRECEIVEQUOTATIONWASTE_ADD,
  FORMRECEIVEQUOTATIONWASTE_UPDATE,
  FORMRECEIVEQUOTATIONWASTE_DELETE,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
  FORMRECEIVEQUOTATIONWASTES_SUM
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getFormReceiveQuotationWasteById = (id, callback) => {
  Api().get('form-receive-quotation-waste/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllFormReceiveQuotationWastes = (callback, params) => {
  if (!params) {
    params = {
      offset: 0,
      limit: GET_ALL_SIZE,
      orderBy: 'wasteCode',
      descending: false
    }
  }
  
  Api().post('form-receive-quotation-waste/search', {
    ...params,
    offset: 0,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadFormReceiveQuotationWastesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`form-receive-quotation-waste/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`form-receive-quotation-waste`, { params })
    .then(data => data)
    .catch(error => error);
}

const addFormReceiveQuotationWasteRequest = async (payload) =>
  await Api().post(`form-receive-quotation-waste`, payload)
    .then(data => data)
    .catch(error => error);

const updateFormReceiveQuotationWasteRequest = async (payload) =>
  await Api().patch(`form-receive-quotation-waste/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteFormReceiveQuotationWasteRequest = async (id) =>
  await Api().delete(`form-receive-quotation-waste/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadFormReceiveQuotationWastes({ payload }) {
  try {
    const data = yield call(loadFormReceiveQuotationWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormReceiveQuotationWastes() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTES_LOAD, loadFormReceiveQuotationWastes);
}

function* loadFormReceiveQuotationWaste({ selectedFormReceiveQuotationWasteId }) {
  try {
    const data = yield call(loadFormReceiveQuotationWastesRequest, selectedFormReceiveQuotationWasteId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormReceiveQuotationWaste() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTES_LOAD, loadFormReceiveQuotationWaste);
}

function* addFormReceiveQuotationWaste({ payload }) {
  try {
    const data = yield call(addFormReceiveQuotationWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addFormReceiveQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddFormReceiveQuotationWaste() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTE_ADD, addFormReceiveQuotationWaste);
}

function* updateFormReceiveQuotationWaste({ payload }) {
  try {
    const data = yield call(updateFormReceiveQuotationWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateFormReceiveQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateFormReceiveQuotationWaste() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTE_UPDATE, updateFormReceiveQuotationWaste);
}

function* deleteFormReceiveQuotationWaste({ payload }) {
  try {
    const data = yield call(deleteFormReceiveQuotationWasteRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(deleteFormReceiveQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllFormReceiveQuotationWastes({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteFormReceiveQuotationWasteRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllFormReceiveQuotationWastesSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteFormReceiveQuotationWaste() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTE_DELETE, deleteFormReceiveQuotationWaste);
}

function* processDeleteAllFormReceiveQuotationWastes() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTE_DELETE_ALL, deleteAllFormReceiveQuotationWastes);
}

const exportFormReceiveQuotationWastesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`form-receive-quotation-waste/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportFormReceiveQuotationWastes({ payload }) {
  try {
    const data = yield call(exportFormReceiveQuotationWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportFormReceiveQuotationWastes() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL, exportFormReceiveQuotationWastes);
}

const sumFormReceiveQuotationWastesRequest = async (params) => {
  return await Api().post(`form-receive-quotation-waste/sum`, params)
    .then(data => data)
    .catch(error => error);
}

function* sumFormReceiveQuotationWastes({ payload }) {
  try {
    const data = yield call(sumFormReceiveQuotationWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(sumFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processSumFormReceiveQuotationWastes() {
  yield takeEvery(FORMRECEIVEQUOTATIONWASTES_SUM, sumFormReceiveQuotationWastes);
}

export default function* FormReceiveQuotationWasteSagas() {
  yield all([fork(processLoadFormReceiveQuotationWastes),
  fork(processLoadFormReceiveQuotationWaste),
  fork(processAddFormReceiveQuotationWaste),
  fork(processUpdateFormReceiveQuotationWaste),
  fork(processDeleteAllFormReceiveQuotationWastes),
  fork(processExportFormReceiveQuotationWastes),
  fork(processSumFormReceiveQuotationWastes),
  fork(processDeleteFormReceiveQuotationWaste)]);
}
