import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSalaryHistories as loadSalaryHistoriesAction,
  loadSalaryHistoriesSuccess,
  addSalaryHistorySuccess,
  updateSalaryHistorySuccess,
  exportExcelSalaryHistories,
  exportExcelSalaryHistoriesSuccess,
  exportExcelSalaryHistoriesFailed
} from '../actions/SalaryHistory';

import {
  SALARYHISTORIES_LOAD,
  SALARYHISTORY_ADD,
  SALARYHISTORY_UPDATE,
  SALARYHISTORY_DELETE,
  SALARYHISTORY_DELETE_ALL,
  SALARYHISTORIES_EXPORT_EXCEL,
  SALARYHISTORIES_EXPORT_EXCEL_SUCCESS,
  SALARYHISTORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSalaryHistoryById = (id, callback) => {
  Api().get('salaryHistory/' + id).then(response => {
    callback(response.data);
  })
}

export const changeSalaryHistoryStatus = (model, callback) => {
  Api().post('salaryHistory/change-status', model).then(response => {
    callback(response);
  })
}

export const addOrUpdateSalaryHistory = (model, callback) => {
  Api().post('salaryHistory/add-or-update', model).then(response => {
    callback(response);
  })
}

export const getAllSalaryHistories = (callback) => {
  Api().post('salaryHistory/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getSalaryHistoriesByCompany = (type, company, callback) => {
  Api().post('salaryHistory/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxSalaryHistoryCodeSorted = (callback) => {
  Api().post('salaryHistory/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadSalaryHistoriesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`salaryHistory/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`salaryHistory`, { params })
    .then(data => data)
    .catch(error => error);
}

const addSalaryHistoryRequest = async (payload) =>
  await Api().post(`salaryHistory`, payload)
    .then(data => data)
    .catch(error => error);


const updateSalaryHistoryRequest = async (payload) =>
  await Api().patch(`salaryHistory/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSalaryHistoryRequest = async (id) =>
  await Api().delete(`salaryHistory/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSalaryHistories({ payload }) {
  try {
    const data = yield call(loadSalaryHistoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSalaryHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSalaryHistories() {
  yield takeEvery(SALARYHISTORIES_LOAD, loadSalaryHistories);
}

function* loadSalaryHistory({ selectedSalaryHistoryId }) {
  try {
    const data = yield call(loadSalaryHistoriesRequest, selectedSalaryHistoryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSalaryHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSalaryHistory() {
  yield takeEvery(SALARYHISTORIES_LOAD, loadSalaryHistory);
}


function* addSalaryHistory({ payload }) {
  try {
    const data = yield call(addSalaryHistoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSalaryHistorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSalaryHistory() {
  yield takeEvery(SALARYHISTORY_ADD, addSalaryHistory);
}


function* updateSalaryHistory({ payload }) {
  try {
    const data = yield call(updateSalaryHistoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSalaryHistorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSalaryHistory() {
  yield takeEvery(SALARYHISTORY_UPDATE, updateSalaryHistory);
}

function* deleteSalaryHistory({ payload }) {
  try {
    const data = yield call(deleteSalaryHistoryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSalaryHistoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSalaryHistories({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSalaryHistoryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSalaryHistoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSalaryHistory() {
  yield takeEvery(SALARYHISTORY_DELETE, deleteSalaryHistory);
}

function* processDeleteAllSalaryHistories() {
  yield takeEvery(SALARYHISTORY_DELETE_ALL, deleteAllSalaryHistories);
}


const exportSalaryHistoriesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`salaryHistory/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportSalaryHistories({ payload }) {
  try {
    const data = yield call(exportSalaryHistoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSalaryHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSalaryHistories() {
  yield takeEvery(SALARYHISTORIES_EXPORT_EXCEL, exportSalaryHistories);
}

export default function* SalaryHistorySagas() {
  yield all([fork(processLoadSalaryHistories),
  fork(processLoadSalaryHistory),
  fork(processAddSalaryHistory),
  fork(processUpdateSalaryHistory),
  fork(processDeleteAllSalaryHistories),
  fork(processDeleteSalaryHistory),
  fork(processExportSalaryHistories)
]);
}
