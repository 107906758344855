import {
  FORMRECEIVEQUOTATIONWASTES_SELECT_ALL,
  FORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL,
  FORMRECEIVEQUOTATIONWASTE_SELECTED,
  FORMRECEIVEQUOTATIONWASTES_LOAD,
  FORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS,
  FORMRECEIVEQUOTATIONWASTES_LOAD_FAILED,
  FORMRECEIVEQUOTATIONWASTE_LOAD,
  FORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_LOAD_FAILED,
  FORMRECEIVEQUOTATIONWASTE_ADD,
  FORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_ADD_FAILED,
  FORMRECEIVEQUOTATIONWASTE_UPDATE,
  FORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED,
  FORMRECEIVEQUOTATIONWASTE_DELETE,
  FORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_DELETE_FAILED,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED,
  FORMRECEIVEQUOTATIONWASTE_WASTES,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED,
  FORMRECEIVEQUOTATIONWASTES_SUM,
  FORMRECEIVEQUOTATIONWASTES_SUM_SUCCESS,
  FORMRECEIVEQUOTATIONWASTES_SUM_FAILED,
  FORMRECEIVEQUOTATIONWASTES_SET_FILTER
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedFormReceiveQuotationWaste: null,
  selectedFormReceiveQuotationWasteId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'receiveDate',
    descending: false,
    filterGroups: null
  },
  wastes: [],
  totalAmountResult: 0,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORMRECEIVEQUOTATIONWASTES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case FORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case FORMRECEIVEQUOTATIONWASTE_SELECTED:
      return {
        ...state,
        selectedFormReceiveQuotationWaste: action.payload,
        selectedFormReceiveQuotationWasteId: action.payload.id
      };
    case FORMRECEIVEQUOTATIONWASTES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case FORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case FORMRECEIVEQUOTATIONWASTES_LOAD_FAILED:
      return {
        ...state
      };
    case FORMRECEIVEQUOTATIONWASTE_LOAD:
      return {
        ...state
      };
    case FORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS:
      return {
        ...state
      };
    case FORMRECEIVEQUOTATIONWASTE_LOAD_FAILED:
      return {
        ...state
      };
    case FORMRECEIVEQUOTATIONWASTE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case FORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case FORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case FORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTE_DELETE_ALL:
      return {
        ...state
      };
    case FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case FORMRECEIVEQUOTATIONWASTE_WASTES:
      return {
        ...state,
        wastes: action.payload
      };
    case FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case FORMRECEIVEQUOTATIONWASTES_SUM:
      return {
        ...state,
        hideLoading: false
      };
    case FORMRECEIVEQUOTATIONWASTES_SUM_SUCCESS:
      return {
        ...state,
        totalAmountResult: action.payload,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTES_SUM_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVEQUOTATIONWASTES_SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
}
