import {
  LEAVETRACKS_SELECT_ALL,
  LEAVETRACKS_UNSELECT_ALL,
  LEAVETRACK_SELECTED,
  LEAVETRACKS_LOAD,
  LEAVETRACKS_LOAD_SUCCESS,
  LEAVETRACKS_LOAD_FAILED,
  LEAVETRACK_LOAD,
  LEAVETRACK_LOAD_SUCCESS,
  LEAVETRACK_LOAD_FAILED,
  LEAVETRACK_ADD,
  LEAVETRACK_ADD_SUCCESS,
  LEAVETRACK_ADD_FAILED,
  LEAVETRACK_UPDATE,
  LEAVETRACK_UPDATE_SUCCESS,
  LEAVETRACK_UPDATE_FAILED,
  LEAVETRACK_DELETE,
  LEAVETRACK_DELETE_SUCCESS,
  LEAVETRACK_DELETE_FAILED,
  LEAVETRACK_DELETE_ALL,
  LEAVETRACK_DELETE_ALL_SUCCESS,
  LEAVETRACK_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedLeaveTrack: null,
  selectedLeaveTrackId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEAVETRACKS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case LEAVETRACKS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case LEAVETRACK_SELECTED:
      return {
        ...state,
        selectedLeaveTrack: action.payload,
        selectedLeaveTrackId: action.payload.id
      };
    case LEAVETRACKS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case LEAVETRACKS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case LEAVETRACKS_LOAD_FAILED:
      return {
        ...state
      };
    case LEAVETRACK_LOAD:
      return {
        ...state
      };
    case LEAVETRACK_LOAD_SUCCESS:
      return {
        ...state
      };
    case LEAVETRACK_LOAD_FAILED:
      return {
        ...state
      };
    case LEAVETRACK_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case LEAVETRACK_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case LEAVETRACK_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LEAVETRACK_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case LEAVETRACK_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case LEAVETRACK_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LEAVETRACK_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case LEAVETRACK_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case LEAVETRACK_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LEAVETRACK_DELETE_ALL:
      return {
        ...state
      };
    case LEAVETRACK_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LEAVETRACK_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
