import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadPaymentVouchers as loadPaymentVouchersAction,
  loadPaymentVouchersSuccess,
  addPaymentVoucherSuccess,
  updatePaymentVoucherSuccess,
  exportExcelPaymentVouchersSuccess,
  reportPaymentVouchersSuccess
} from '../actions/PaymentVoucher';

import {
  PAYMENTVOUCHERS_LOAD,
  PAYMENTVOUCHER_ADD,
  PAYMENTVOUCHER_UPDATE,
  PAYMENTVOUCHER_DELETE,
  PAYMENTVOUCHER_DELETE_ALL,
  PAYMENTVOUCHERS_EXPORT_EXCEL,
  PAYMENTVOUCHERS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getPaymentVoucherById = (id, callback) => {
  Api().get('payment-voucher/' + id).then(response => {
    callback(response.data);
  })
}

export const changePaymentVoucherStatus = (model, callback) => {
  Api().post('payment-voucher/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllPaymentVouchers = (callback) => {
  Api().post('payment-voucher/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxPaymentVoucherCodeSorted = (callback) => {
  Api().post('payment-voucher/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadPaymentVouchersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`payment-voucher/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`payment-voucher`, { params })
    .then(data => data)
    .catch(error => error);
}


const addPaymentVoucherRequest = async (payload) =>
  await Api().post(`payment-voucher`, payload)
    .then(data => data)
    .catch(error => error);

const updatePaymentVoucherRequest = async (payload) =>
  await Api().patch(`payment-voucher/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deletePaymentVoucherRequest = async (id) =>
  await Api().delete(`payment-voucher/${id}`)
    .then(data => data)
    .catch(error => error);

const exportPaymentVouchersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`payment-voucher/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadPaymentVouchers({ payload }) {
  try {

    const data = yield call(loadPaymentVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPaymentVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPaymentVouchers() {
  yield takeEvery(PAYMENTVOUCHERS_LOAD, loadPaymentVouchers);
}

function* loadPaymentVoucher({ selectedPaymentVoucherId }) {
  try {

    const data = yield call(loadPaymentVouchersRequest, selectedPaymentVoucherId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPaymentVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPaymentVoucher() {
  yield takeEvery(PAYMENTVOUCHERS_LOAD, loadPaymentVoucher);
}


function* addPaymentVoucher({ payload }) {
  try {
    const data = yield call(addPaymentVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addPaymentVoucherSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddPaymentVoucher() {
  yield takeEvery(PAYMENTVOUCHER_ADD, addPaymentVoucher);
}


function* updatePaymentVoucher({ payload }) {
  try {
    const data = yield call(updatePaymentVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updatePaymentVoucherSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdatePaymentVoucher() {
  yield takeEvery(PAYMENTVOUCHER_UPDATE, updatePaymentVoucher);
}

function* deletePaymentVoucher({ payload }) {
  try {
    const data = yield call(deletePaymentVoucherRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPaymentVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllPaymentVouchers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deletePaymentVoucherRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadPaymentVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeletePaymentVoucher() {
  yield takeEvery(PAYMENTVOUCHER_DELETE, deletePaymentVoucher);
}

function* processDeleteAllPaymentVouchers() {
  yield takeEvery(PAYMENTVOUCHER_DELETE_ALL, deleteAllPaymentVouchers);
}

function* exportPaymentVouchers({ payload }) {
  try {
    const data = yield call(exportPaymentVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelPaymentVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportPaymentVouchers() {
  yield takeEvery(PAYMENTVOUCHERS_EXPORT_EXCEL, exportPaymentVouchers);
}

const reportPaymentVouchersRequest = async (params) => {
  return await Api().post(`payment-voucher/report`, params)
    .then(data => data)
    .catch(error => error);
}

function* reportPaymentVouchers({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportPaymentVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportPaymentVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportPaymentVouchers() {
  yield takeEvery(PAYMENTVOUCHERS_REPORT, reportPaymentVouchers);
}


export default function* PaymentVoucherSagas() {
  yield all([fork(processLoadPaymentVouchers),
  fork(processLoadPaymentVoucher),
  fork(processAddPaymentVoucher),
  fork(processUpdatePaymentVoucher),
  fork(processDeleteAllPaymentVouchers),
  fork(processDeletePaymentVoucher),
  fork(processExportPaymentVouchers),
  fork(processReportPaymentVouchers)
]);
  
}
