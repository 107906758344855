import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadWastes as loadWastesAction,
  loadWastesSuccess,
  addWasteSuccess,
  updateWasteSuccess,
  exportExcelWastes,
  exportExcelWastesSuccess,
  exportExcelWastesFailed
} from '../actions/Waste';

import {
  WASTES_LOAD,
  WASTE_ADD,
  WASTE_UPDATE,
  WASTE_DELETE,
  WASTE_DELETE_ALL,
  WASTES_EXPORT_EXCEL,
  WASTES_EXPORT_EXCEL_SUCCESS,
  WASTES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getWasteById = (id, callback) => {
  Api().get('waste/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllWastes = (callback) => {
  Api().post('waste/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxWasteCodeSorted = (callback) => {
  Api().post('waste/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadWastesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`waste/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`waste`, { params })
    .then(data => data)
    .catch(error => error);
}


const addWasteRequest = async (payload) =>
  await Api().post(`waste`, payload)
    .then(data => data)
    .catch(error => error);

const updateWasteRequest = async (payload) =>
  await Api().patch(`waste/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteWasteRequest = async (id) =>
  await Api().delete(`waste/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadWastes({ payload }) {
  try {
    const data = yield call(loadWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadWastes() {
  yield takeEvery(WASTES_LOAD, loadWastes);
}

function* loadWaste({ selectedWasteId }) {
  try {
    const data = yield call(loadWastesRequest, selectedWasteId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadWaste() {
  yield takeEvery(WASTES_LOAD, loadWaste);
}


function* addWaste({ payload }) {
  try {
    const data = yield call(addWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddWaste() {
  yield takeEvery(WASTE_ADD, addWaste);
}


function* updateWaste({ payload }) {
  try {
    const data = yield call(updateWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateWaste() {
  yield takeEvery(WASTE_UPDATE, updateWaste);
}

function* deleteWaste({ payload }) {
  try {
    const data = yield call(deleteWasteRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadWastesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllWastes({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteWasteRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadWastesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteWaste() {
  yield takeEvery(WASTE_DELETE, deleteWaste);
}

function* processDeleteAllWastes() {
  yield takeEvery(WASTE_DELETE_ALL, deleteAllWastes);
}

const exportWastesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`waste/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportWastes({ payload }) {
  try {
    const data = yield call(exportWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportWastes() {
  yield takeEvery(WASTES_EXPORT_EXCEL, exportWastes);
}

export default function* WasteSagas() {
  yield all([fork(processLoadWastes),
  fork(processLoadWaste),
  fork(processAddWaste),
  fork(processUpdateWaste),
  fork(processDeleteAllWastes),
  fork(processDeleteWaste),
  fork(processExportWastes)
]);
}
