import {
  PRODUCTEXPORTVOUCHERS_SELECT_ALL,
  PRODUCTEXPORTVOUCHERS_UNSELECT_ALL,
  PRODUCTEXPORTVOUCHER_SELECTED,
  PRODUCTEXPORTVOUCHERS_LOAD,
  PRODUCTEXPORTVOUCHERS_LOAD_SUCCESS,
  PRODUCTEXPORTVOUCHERS_LOAD_FAILED,
  PRODUCTEXPORTVOUCHER_LOAD,
  PRODUCTEXPORTVOUCHER_LOAD_SUCCESS,
  PRODUCTEXPORTVOUCHER_LOAD_FAILED,
  PRODUCTEXPORTVOUCHER_ADD,
  PRODUCTEXPORTVOUCHER_ADD_SUCCESS,
  PRODUCTEXPORTVOUCHER_ADD_FAILED,
  PRODUCTEXPORTVOUCHER_UPDATE,
  PRODUCTEXPORTVOUCHER_UPDATE_SUCCESS,
  PRODUCTEXPORTVOUCHER_UPDATE_FAILED,
  PRODUCTEXPORTVOUCHER_DELETE,
  PRODUCTEXPORTVOUCHER_DELETE_SUCCESS,
  PRODUCTEXPORTVOUCHER_DELETE_FAILED,
  PRODUCTEXPORTVOUCHER_DELETE_ALL,
  PRODUCTEXPORTVOUCHER_DELETE_ALL_SUCCESS,
  PRODUCTEXPORTVOUCHER_DELETE_ALL_FAILED,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedProductExportVoucher: null,
  selectedProductExportVoucherId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRODUCTEXPORTVOUCHERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case PRODUCTEXPORTVOUCHERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case PRODUCTEXPORTVOUCHER_SELECTED:
      return {
        ...state,
        selectedProductExportVoucher: action.payload,
        selectedProductExportVoucherId: action.payload.id
      };
    case PRODUCTEXPORTVOUCHERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case PRODUCTEXPORTVOUCHERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case PRODUCTEXPORTVOUCHERS_LOAD_FAILED:
      return {
        ...state
      };
    case PRODUCTEXPORTVOUCHER_LOAD:
      return {
        ...state
      };
    case PRODUCTEXPORTVOUCHER_LOAD_SUCCESS:
      return {
        ...state
      };
    case PRODUCTEXPORTVOUCHER_LOAD_FAILED:
      return {
        ...state
      };
    case PRODUCTEXPORTVOUCHER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCTEXPORTVOUCHER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedProductExportVoucher: action.payload
      };
    case PRODUCTEXPORTVOUCHER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTEXPORTVOUCHER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCTEXPORTVOUCHER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTEXPORTVOUCHER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTEXPORTVOUCHER_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCTEXPORTVOUCHER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case PRODUCTEXPORTVOUCHER_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTEXPORTVOUCHER_DELETE_ALL:
      return {
        ...state
      };
    case PRODUCTEXPORTVOUCHER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PRODUCTEXPORTVOUCHER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
