import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSupplyExportVouchers as loadSupplyExportVouchersAction,
  loadSupplyExportVouchersSuccess,
  addSupplyExportVoucherSuccess,
  updateSupplyExportVoucherSuccess,
  exportExcelSupplyExportVouchersSuccess
} from '../actions/SupplyExportVoucher';

import {
  SUPPLYEXPORTVOUCHERS_LOAD,
  SUPPLYEXPORTVOUCHER_ADD,
  SUPPLYEXPORTVOUCHER_UPDATE,
  SUPPLYEXPORTVOUCHER_DELETE,
  SUPPLYEXPORTVOUCHER_DELETE_ALL,
  SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSupplyExportVoucherById = (id, callback) => {
  Api().get('supply-export-voucher/' + id).then(response => {
    callback(response.data);
  })
}

export const changeSupplyExportVoucherStatus = (model, callback) => {
  Api().post('supply-export-voucher/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllSupplyExportVouchers = (callback) => {
  Api().post('supply-export-voucher/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxSupplyExportVoucherCodeSorted = (callback) => {
  Api().post('supply-export-voucher/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadSupplyExportVouchersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`supply-export-voucher/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`supply-export-voucher`, { params })
    .then(data => data)
    .catch(error => error);
}


const addSupplyExportVoucherRequest = async (payload) =>
  await Api().post(`supply-export-voucher`, payload)
    .then(data => data)
    .catch(error => error);

const updateSupplyExportVoucherRequest = async (payload) =>
  await Api().patch(`supply-export-voucher/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSupplyExportVoucherRequest = async (id) =>
  await Api().delete(`supply-export-voucher/${id}`)
    .then(data => data)
    .catch(error => error);

const exportSupplyExportVouchersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`supply-export-voucher/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadSupplyExportVouchers({ payload }) {
  try {

    const data = yield call(loadSupplyExportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyExportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyExportVouchers() {
  yield takeEvery(SUPPLYEXPORTVOUCHERS_LOAD, loadSupplyExportVouchers);
}

function* loadSupplyExportVoucher({ selectedSupplyExportVoucherId }) {
  try {

    const data = yield call(loadSupplyExportVouchersRequest, selectedSupplyExportVoucherId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyExportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyExportVoucher() {
  yield takeEvery(SUPPLYEXPORTVOUCHERS_LOAD, loadSupplyExportVoucher);
}


function* addSupplyExportVoucher({ payload }) {
  try {
    const data = yield call(addSupplyExportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSupplyExportVoucherSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSupplyExportVoucher() {
  yield takeEvery(SUPPLYEXPORTVOUCHER_ADD, addSupplyExportVoucher);
}


function* updateSupplyExportVoucher({ payload }) {
  try {
    const data = yield call(updateSupplyExportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSupplyExportVoucherSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSupplyExportVoucher() {
  yield takeEvery(SUPPLYEXPORTVOUCHER_UPDATE, updateSupplyExportVoucher);
}

function* deleteSupplyExportVoucher({ payload }) {
  try {
    const data = yield call(deleteSupplyExportVoucherRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyExportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSupplyExportVouchers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSupplyExportVoucherRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSupplyExportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSupplyExportVoucher() {
  yield takeEvery(SUPPLYEXPORTVOUCHER_DELETE, deleteSupplyExportVoucher);
}

function* processDeleteAllSupplyExportVouchers() {
  yield takeEvery(SUPPLYEXPORTVOUCHER_DELETE_ALL, deleteAllSupplyExportVouchers);
}

function* exportSupplyExportVouchers({ payload }) {
  try {
    const data = yield call(exportSupplyExportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSupplyExportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSupplyExportVouchers() {
  yield takeEvery(SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL, exportSupplyExportVouchers);
}

export default function* SupplyExportVoucherSagas() {
  yield all([fork(processLoadSupplyExportVouchers),
  fork(processLoadSupplyExportVoucher),
  fork(processAddSupplyExportVoucher),
  fork(processUpdateSupplyExportVoucher),
  fork(processDeleteAllSupplyExportVouchers),
  fork(processDeleteSupplyExportVoucher),
  fork(processExportSupplyExportVouchers)]);
}
