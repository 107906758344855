import {
  CUSTOMERREGS_SELECT_ALL,
  CUSTOMERREGS_UNSELECT_ALL,
  CUSTOMERREG_SELECTED,
  CUSTOMERREGS_LOAD,
  CUSTOMERREGS_LOAD_SUCCESS,
  CUSTOMERREGS_LOAD_FAILED,
  CUSTOMERREG_LOAD,
  CUSTOMERREG_LOAD_SUCCESS,
  CUSTOMERREG_LOAD_FAILED,
  CUSTOMERREG_ADD,
  CUSTOMERREG_ADD_SUCCESS,
  CUSTOMERREG_ADD_FAILED,
  CUSTOMERREG_UPDATE,
  CUSTOMERREG_UPDATE_SUCCESS,
  CUSTOMERREG_UPDATE_FAILED,
  CUSTOMERREG_DELETE,
  CUSTOMERREG_DELETE_SUCCESS,
  CUSTOMERREG_DELETE_FAILED,
  CUSTOMERREG_DELETE_ALL,
  CUSTOMERREG_DELETE_ALL_SUCCESS,
  CUSTOMERREG_DELETE_ALL_FAILED,
  CUSTOMERREGS_EXPORT_EXCEL,
  CUSTOMERREGS_EXPORT_EXCEL_SUCCESS,
  CUSTOMERREGS_EXPORT_EXCEL_FAILED,
  CUSTOMERREGS_REPORT,
  CUSTOMERREGS_REPORT_SUCCESS,
  CUSTOMERREGS_REPORT_FAILED
} from '../constants/ActionTypes';

export const selectAllCustomerRegs = () => {
  return {
    type: CUSTOMERREGS_SELECT_ALL
  }
}

export const unselectAllCustomerRegs = () => {
  return {
    type: CUSTOMERREGS_UNSELECT_ALL
  }
}

export const selectCustomerReg = data => {
  return {
    type: CUSTOMERREG_SELECTED,
    payload: data
  }
}

export const loadCustomerRegs = (data) => {
  return {
    type: CUSTOMERREGS_LOAD,
    payload: data
  };
}

export const loadCustomerRegsSuccess = (data) => {
  return {
    type: CUSTOMERREGS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadCustomerRegsFailed = () => {
  return {
    type: CUSTOMERREGS_LOAD_FAILED
  };
}

export const loadCustomerReg = () => {
  return {
    type: CUSTOMERREG_LOAD
  };
}

export const loadCustomerRegSuccess = (data) => {
  return {
    type: CUSTOMERREG_LOAD_SUCCESS,
    payload: data
  };
}


export const loadCustomerRegFailed = () => {
  return {
    type: CUSTOMERREG_LOAD_FAILED
  };
}


export const addCustomerReg = (data) => {
  return {
    type: CUSTOMERREG_ADD,
    payload: data
  };
}


export const addCustomerRegSuccess = (filter) => {
  return {
    type: CUSTOMERREG_ADD_SUCCESS,
    message: 'CustomerReg added successfully.',
    payload: filter
  };
}

export const addCustomerRegFailed = () => {
  return {
    type: CUSTOMERREG_ADD_FAILED
  };
}

export const updateCustomerReg = (data) => {
  return {
    type: CUSTOMERREG_UPDATE,
    payload: data
  };
}


export const updateCustomerRegSuccess = (data) => {
  return {
    type: CUSTOMERREG_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateCustomerRegFailed = (data) => {
  return {
    type: CUSTOMERREG_UPDATE_FAILED,
    payload: data
  };
}

export const deleteCustomerReg = (data) => {
  return {
    type: CUSTOMERREG_DELETE,
    payload: data
  };
}

export const deleteCustomerRegSuccess = () => {
  return {
    type: CUSTOMERREG_DELETE_SUCCESS,
    message: 'CustomerReg deleted successfully.'
  };
}

export const deleteCustomerRegFailed = () => {
  return {
    type: CUSTOMERREG_DELETE_FAILED
  };
}

export const deleteAllCustomerRegs = (data) => {
  return {
    type: CUSTOMERREG_DELETE_ALL,
    payload: data
  };
}

export const deleteAllCustomerRegsSuccess = () => {
  return {
    type: CUSTOMERREG_DELETE_ALL_SUCCESS,
    message: 'CustomerReg all deleted successfully.'
  };
}

export const deleteAllCustomerRegsFailed = () => {
  return {
    type: CUSTOMERREG_DELETE_ALL_FAILED
  };
}

export const exportExcelCustomerRegs = (filter) => {
  return {
    type: CUSTOMERREGS_EXPORT_EXCEL,
    payload: filter
  }
}

export const exportExcelCustomerRegsSuccess = (data) => {
  return {
    type: CUSTOMERREGS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelCustomerRegsFailed = () => {
  return {
    type: CUSTOMERREGS_EXPORT_EXCEL_FAILED
  };
}

export const reportCustomerRegs = (filter) => {
  return {
    type: CUSTOMERREGS_REPORT,
    payload: filter
  }
}

export const reportCustomerRegsSuccess = (data) => {
  return {
    type: CUSTOMERREGS_REPORT_SUCCESS,
    payload: data
  };
}
export const reportCustomerRegsFailed = () => {
  return {
    type: CUSTOMERREGS_REPORT_FAILED
  };
}