import {
  ADVANCESALARIES_SELECT_ALL,
  ADVANCESALARIES_UNSELECT_ALL,
  ADVANCESALARY_SELECTED,
  ADVANCESALARIES_LOAD,
  ADVANCESALARIES_LOAD_SUCCESS,
  ADVANCESALARIES_LOAD_FAILED,
  ADVANCESALARY_LOAD,
  ADVANCESALARY_LOAD_SUCCESS,
  ADVANCESALARY_LOAD_FAILED,
  ADVANCESALARY_ADD,
  ADVANCESALARY_ADD_SUCCESS,
  ADVANCESALARY_ADD_FAILED,
  ADVANCESALARY_UPDATE,
  ADVANCESALARY_UPDATE_SUCCESS,
  ADVANCESALARY_UPDATE_FAILED,
  ADVANCESALARY_DELETE,
  ADVANCESALARY_DELETE_SUCCESS,
  ADVANCESALARY_DELETE_FAILED,
  ADVANCESALARY_DELETE_ALL,
  ADVANCESALARY_DELETE_ALL_SUCCESS,
  ADVANCESALARY_DELETE_ALL_FAILED,
  ADVANCESALARIES_EXPORT_EXCEL,
  ADVANCESALARIES_EXPORT_EXCEL_SUCCESS,
  ADVANCESALARIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllAdvanceSalaries = () => {
  return {
    type: ADVANCESALARIES_SELECT_ALL
  }
}

export const unselectAllAdvanceSalaries = () => {
  return {
    type: ADVANCESALARIES_UNSELECT_ALL
  }
}

export const selectAdvanceSalary = data => {
  return {
    type: ADVANCESALARY_SELECTED,
    payload: data
  }
}

export const loadAdvanceSalaries = (data) => {
  return {
    type: ADVANCESALARIES_LOAD,
    payload: data
  };
}

export const loadAdvanceSalariesSuccess = (data) => {
  return {
    type: ADVANCESALARIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadAdvanceSalariesFailed = () => {
  return {
    type: ADVANCESALARIES_LOAD_FAILED
  };
}

export const loadAdvanceSalary = () => {
  return {
    type: ADVANCESALARY_LOAD
  };
}

export const loadAdvanceSalarySuccess = (data) => {
  return {
    type: ADVANCESALARY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadAdvanceSalaryFailed = () => {
  return {
    type: ADVANCESALARY_LOAD_FAILED
  };
}


export const addAdvanceSalary = (data) => {
  return {
    type: ADVANCESALARY_ADD,
    payload: data
  };
}


export const addAdvanceSalarySuccess = (filter) => {
  return {
    type: ADVANCESALARY_ADD_SUCCESS,
    message: 'AdvanceSalary added successfully.',
    payload: filter
  };
}

export const addAdvanceSalaryFailed = () => {
  return {
    type: ADVANCESALARY_ADD_FAILED
  };
}

export const updateAdvanceSalary = (data) => {
  return {
    type: ADVANCESALARY_UPDATE,
    payload: data
  };
}


export const updateAdvanceSalarySuccess = (data) => {
  return {
    type: ADVANCESALARY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateAdvanceSalaryFailed = (data) => {
  return {
    type: ADVANCESALARY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteAdvanceSalary = (data) => {
  return {
    type: ADVANCESALARY_DELETE,
    payload: data
  };
}

export const deleteAdvanceSalarySuccess = () => {
  return {
    type: ADVANCESALARY_DELETE_SUCCESS,
    message: 'AdvanceSalary deleted successfully.'
  };
}

export const deleteAdvanceSalaryFailed = () => {
  return {
    type: ADVANCESALARY_DELETE_FAILED
  };
}

export const deleteAllAdvanceSalaries = (data) => {
  return {
    type: ADVANCESALARY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllAdvanceSalariesSuccess = () => {
  return {
    type: ADVANCESALARY_DELETE_ALL_SUCCESS,
    message: 'AdvanceSalary all deleted successfully.'
  };
}

export const deleteAllAdvanceSalariesFailed = () => {
  return {
    type: ADVANCESALARY_DELETE_ALL_FAILED
  };
}

export const exportExcelAdvanceSalaries = (filter) => {
  return {
    type:ADVANCESALARIES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelAdvanceSalariesSuccess = (data) => {
  return {
    type:ADVANCESALARIES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelAdvanceSalariesFailed = () => {
  return {
    type:ADVANCESALARIES_EXPORT_EXCEL_FAILED
  };
}
