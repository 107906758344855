import {
  PAYMENTVOUCHERS_SELECT_ALL,
  PAYMENTVOUCHERS_UNSELECT_ALL,
  PAYMENTVOUCHER_SELECTED,
  PAYMENTVOUCHERS_LOAD,
  PAYMENTVOUCHERS_LOAD_SUCCESS,
  PAYMENTVOUCHERS_LOAD_FAILED,
  PAYMENTVOUCHER_LOAD,
  PAYMENTVOUCHER_LOAD_SUCCESS,
  PAYMENTVOUCHER_LOAD_FAILED,
  PAYMENTVOUCHER_ADD,
  PAYMENTVOUCHER_ADD_SUCCESS,
  PAYMENTVOUCHER_ADD_FAILED,
  PAYMENTVOUCHER_UPDATE,
  PAYMENTVOUCHER_UPDATE_SUCCESS,
  PAYMENTVOUCHER_UPDATE_FAILED,
  PAYMENTVOUCHER_DELETE,
  PAYMENTVOUCHER_DELETE_SUCCESS,
  PAYMENTVOUCHER_DELETE_FAILED,
  PAYMENTVOUCHER_DELETE_ALL,
  PAYMENTVOUCHER_DELETE_ALL_SUCCESS,
  PAYMENTVOUCHER_DELETE_ALL_FAILED,
  PAYMENTVOUCHERS_EXPORT_EXCEL,
  PAYMENTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  PAYMENTVOUCHERS_EXPORT_EXCEL_FAILED,
  PAYMENTVOUCHERS_REPORT,
  PAYMENTVOUCHERS_REPORT_SUCCESS,
  PAYMENTVOUCHERS_REPORT_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  reportPaymentVoucherData: [],
  selectedPaymentVoucher: null,
  selectedPaymentVoucherId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAYMENTVOUCHERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case PAYMENTVOUCHERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case PAYMENTVOUCHER_SELECTED:
      return {
        ...state,
        selectedPaymentVoucher: action.payload,
        selectedPaymentVoucherId: action.payload.id
      };
    case PAYMENTVOUCHERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case PAYMENTVOUCHERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case PAYMENTVOUCHERS_LOAD_FAILED:
      return {
        ...state
      };
    case PAYMENTVOUCHER_LOAD:
      return {
        ...state
      };
    case PAYMENTVOUCHER_LOAD_SUCCESS:
      return {
        ...state
      };
    case PAYMENTVOUCHER_LOAD_FAILED:
      return {
        ...state
      };
    case PAYMENTVOUCHER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case PAYMENTVOUCHER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedPaymentVoucher: action.payload
      };
    case PAYMENTVOUCHER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PAYMENTVOUCHER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case PAYMENTVOUCHER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case PAYMENTVOUCHER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PAYMENTVOUCHER_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case PAYMENTVOUCHER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case PAYMENTVOUCHER_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PAYMENTVOUCHER_DELETE_ALL:
      return {
        ...state
      };
    case PAYMENTVOUCHER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PAYMENTVOUCHER_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case PAYMENTVOUCHERS_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case PAYMENTVOUCHERS_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case PAYMENTVOUCHERS_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
        case PAYMENTVOUCHERS_REPORT:
          return {
            ...state,
            reportPaymentVoucherData: []
          };
        case PAYMENTVOUCHERS_REPORT_SUCCESS:
          return {
            ...state,
            reportPaymentVoucherData: action.payload
          };
        case PAYMENTVOUCHERS_REPORT_FAILED:
          return {
            ...state,
            reportPaymentVoucherData: []
          };
    default:
      return state;
  }
}
