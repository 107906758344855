import {
  FORMRECEIVES_SELECT_ALL,
  FORMRECEIVES_UNSELECT_ALL,
  FORMRECEIVE_SELECTED,
  FORMRECEIVES_LOAD,
  FORMRECEIVES_LOAD_SUCCESS,
  FORMRECEIVES_LOAD_FAILED,
  FORMRECEIVE_LOAD,
  FORMRECEIVE_LOAD_SUCCESS,
  FORMRECEIVE_LOAD_FAILED,
  FORMRECEIVE_ADD,
  FORMRECEIVE_ADD_SUCCESS,
  FORMRECEIVE_ADD_FAILED,
  FORMRECEIVE_UPDATE,
  FORMRECEIVE_UPDATE_SUCCESS,
  FORMRECEIVE_UPDATE_FAILED,
  FORMRECEIVE_DELETE,
  FORMRECEIVE_DELETE_SUCCESS,
  FORMRECEIVE_DELETE_FAILED,
  FORMRECEIVE_DELETE_ALL,
  FORMRECEIVE_DELETE_ALL_SUCCESS,
  FORMRECEIVE_DELETE_ALL_FAILED,
  FORMRECEIVES_EXPORT_EXCEL,
  FORMRECEIVES_EXPORT_EXCEL_SUCCESS,
  FORMRECEIVES_EXPORT_EXCEL_FAILED,
  FORMRECEIVES_SET_FILTER
} from '../constants/ActionTypes';

export const selectAllFormReceives = () => {
  return {
    type: FORMRECEIVES_SELECT_ALL
  }
}

export const unselectAllFormReceives = () => {
  return {
    type: FORMRECEIVES_UNSELECT_ALL
  }
}

export const selectFormReceive = data => {
  return {
    type: FORMRECEIVE_SELECTED,
    payload: data
  }
}

export const loadFormReceives = (data) => {
  return {
    type: FORMRECEIVES_LOAD,
    payload: data
  };
}

export const loadFormReceivesSuccess = (data) => {
  return {
    type: FORMRECEIVES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadFormReceivesFailed = () => {
  return {
    type: FORMRECEIVES_LOAD_FAILED
  };
}

export const loadFormReceive = () => {
  return {
    type: FORMRECEIVE_LOAD
  };
}

export const loadFormReceiveSuccess = (data) => {
  return {
    type: FORMRECEIVE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadFormReceiveFailed = () => {
  return {
    type: FORMRECEIVE_LOAD_FAILED
  };
}


export const addFormReceive = (data) => {
  return {
    type: FORMRECEIVE_ADD,
    payload: data
  };
}


export const addFormReceiveSuccess = (filter) => {
  return {
    type: FORMRECEIVE_ADD_SUCCESS,
    message: 'FormReceive added successfully.',
    payload: filter
  };
}

export const addFormReceiveFailed = () => {
  return {
    type: FORMRECEIVE_ADD_FAILED
  };
}

export const updateFormReceive = (data) => {
  return {
    type: FORMRECEIVE_UPDATE,
    payload: data
  };
}


export const updateFormReceiveSuccess = (data) => {
  return {
    type: FORMRECEIVE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateFormReceiveFailed = (data) => {
  return {
    type: FORMRECEIVE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteFormReceive = (data) => {
  return {
    type: FORMRECEIVE_DELETE,
    payload: data
  };
}

export const deleteFormReceiveSuccess = () => {
  return {
    type: FORMRECEIVE_DELETE_SUCCESS,
    message: 'FormReceive deleted successfully.'
  };
}

export const deleteFormReceiveFailed = () => {
  return {
    type: FORMRECEIVE_DELETE_FAILED
  };
}

export const deleteAllFormReceives = (data) => {
  return {
    type: FORMRECEIVE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllFormReceivesSuccess = () => {
  return {
    type: FORMRECEIVE_DELETE_ALL_SUCCESS,
    message: 'FormReceive all deleted successfully.'
  };
}

export const deleteAllFormReceivesFailed = () => {
  return {
    type: FORMRECEIVE_DELETE_ALL_FAILED
  };
}

export const exportExcelFormReceives = (filter) => {
  return {
    type: FORMRECEIVES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelFormReceivesSuccess = (data) => {
  return {
    type: FORMRECEIVES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelFormReceivesFailed = () => {
  return {
    type: FORMRECEIVES_EXPORT_EXCEL_FAILED
  };
}

export const setFilter = (newFilter) => {
  return {
    type: FORMRECEIVES_SET_FILTER,
    payload: newFilter
  }
}
