import {
  SUPPLYFORMITEMS_SELECT_ALL,
  SUPPLYFORMITEMS_UNSELECT_ALL,
  SUPPLYFORMITEM_SELECTED,
  SUPPLYFORMITEMS_LOAD,
  SUPPLYFORMITEMS_LOAD_SUCCESS,
  SUPPLYFORMITEMS_LOAD_FAILED,
  SUPPLYFORMITEM_LOAD,
  SUPPLYFORMITEM_LOAD_SUCCESS,
  SUPPLYFORMITEM_LOAD_FAILED,
  SUPPLYFORMITEM_ADD,
  SUPPLYFORMITEM_ADD_SUCCESS,
  SUPPLYFORMITEM_ADD_FAILED,
  SUPPLYFORMITEM_UPDATE,
  SUPPLYFORMITEM_UPDATE_SUCCESS,
  SUPPLYFORMITEM_UPDATE_FAILED,
  SUPPLYFORMITEM_DELETE,
  SUPPLYFORMITEM_DELETE_SUCCESS,
  SUPPLYFORMITEM_DELETE_FAILED,
  SUPPLYFORMITEM_DELETE_ALL,
  SUPPLYFORMITEM_DELETE_ALL_SUCCESS,
  SUPPLYFORMITEM_DELETE_ALL_FAILED,
  SUPPLYFORMITEMS_EXPORT_EXCEL,
  SUPPLYFORMITEMS_EXPORT_EXCEL_SUCCESS,
  SUPPLYFORMITEMS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSupplyFormItem: null,
  selectedSupplyFormItemId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'receiveDate',
    descending: false,
    filterGroups: null
  },
  wastes: [],
  totalAmountResult: 0,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLYFORMITEMS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SUPPLYFORMITEMS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SUPPLYFORMITEM_SELECTED:
      return {
        ...state,
        selectedSupplyFormItem: action.payload,
        selectedSupplyFormItemId: action.payload.id
      };
    case SUPPLYFORMITEMS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SUPPLYFORMITEMS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SUPPLYFORMITEMS_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYFORMITEM_LOAD:
      return {
        ...state
      };
    case SUPPLYFORMITEM_LOAD_SUCCESS:
      return {
        ...state
      };
    case SUPPLYFORMITEM_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYFORMITEM_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYFORMITEM_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORMITEM_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORMITEM_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYFORMITEM_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORMITEM_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORMITEM_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYFORMITEM_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case SUPPLYFORMITEM_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYFORMITEM_DELETE_ALL:
      return {
        ...state
      };
    case SUPPLYFORMITEM_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SUPPLYFORMITEM_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case SUPPLYFORMITEMS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case SUPPLYFORMITEMS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case SUPPLYFORMITEMS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
