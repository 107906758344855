import {
  SALARYSETTINGS_SELECT_ALL,
  SALARYSETTINGS_UNSELECT_ALL,
  SALARYSETTING_SELECTED,
  SALARYSETTINGS_LOAD,
  SALARYSETTINGS_LOAD_SUCCESS,
  SALARYSETTINGS_LOAD_FAILED,
  SALARYSETTING_LOAD,
  SALARYSETTING_LOAD_SUCCESS,
  SALARYSETTING_LOAD_FAILED,
  SALARYSETTING_ADD,
  SALARYSETTING_ADD_SUCCESS,
  SALARYSETTING_ADD_FAILED,
  SALARYSETTING_UPDATE,
  SALARYSETTING_UPDATE_SUCCESS,
  SALARYSETTING_UPDATE_FAILED,
  SALARYSETTING_DELETE,
  SALARYSETTING_DELETE_SUCCESS,
  SALARYSETTING_DELETE_FAILED,
  SALARYSETTING_DELETE_ALL,
  SALARYSETTING_DELETE_ALL_SUCCESS,
  SALARYSETTING_DELETE_ALL_FAILED,
  SALARYSETTINGS_EXPORT_EXCEL,
  SALARYSETTINGS_EXPORT_EXCEL_SUCCESS,
  SALARYSETTINGS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSalarySettings = () => {
  return {
    type: SALARYSETTINGS_SELECT_ALL
  }
}

export const unselectAllSalarySettings = () => {
  return {
    type: SALARYSETTINGS_UNSELECT_ALL
  }
}

export const selectSalarySetting = data => {
  return {
    type: SALARYSETTING_SELECTED,
    payload: data
  }
}

export const loadSalarySettings = (data) => {
  return {
    type: SALARYSETTINGS_LOAD,
    payload: data
  };
}

export const loadSalarySettingsSuccess = (data) => {
  return {
    type: SALARYSETTINGS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSalarySettingsFailed = () => {
  return {
    type: SALARYSETTINGS_LOAD_FAILED
  };
}

export const loadSalarySetting = () => {
  return {
    type: SALARYSETTING_LOAD
  };
}

export const loadSalarySettingSuccess = (data) => {
  return {
    type: SALARYSETTING_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSalarySettingFailed = () => {
  return {
    type: SALARYSETTING_LOAD_FAILED
  };
}


export const addSalarySetting = (data) => {
  return {
    type: SALARYSETTING_ADD,
    payload: data
  };
}


export const addSalarySettingSuccess = (filter) => {
  return {
    type: SALARYSETTING_ADD_SUCCESS,
    message: 'SalarySetting added successfully.',
    payload: filter
  };
}

export const addSalarySettingFailed = () => {
  return {
    type: SALARYSETTING_ADD_FAILED
  };
}

export const updateSalarySetting = (data) => {
  return {
    type: SALARYSETTING_UPDATE,
    payload: data
  };
}


export const updateSalarySettingSuccess = (data) => {
  return {
    type: SALARYSETTING_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSalarySettingFailed = (data) => {
  return {
    type: SALARYSETTING_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSalarySetting = (data) => {
  return {
    type: SALARYSETTING_DELETE,
    payload: data
  };
}

export const deleteSalarySettingSuccess = () => {
  return {
    type: SALARYSETTING_DELETE_SUCCESS,
    message: 'SalarySetting deleted successfully.'
  };
}

export const deleteSalarySettingFailed = () => {
  return {
    type: SALARYSETTING_DELETE_FAILED
  };
}

export const deleteAllSalarySettings = (data) => {
  return {
    type: SALARYSETTING_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSalarySettingsSuccess = () => {
  return {
    type: SALARYSETTING_DELETE_ALL_SUCCESS,
    message: 'SalarySetting all deleted successfully.'
  };
}

export const deleteAllSalarySettingsFailed = () => {
  return {
    type: SALARYSETTING_DELETE_ALL_FAILED
  };
}

export const exportExcelSalarySettings = (filter) => {
  return {
    type:SALARYSETTINGS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSalarySettingsSuccess = (data) => {
  return {
    type:SALARYSETTINGS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSalarySettingsFailed = () => {
  return {
    type:SALARYSETTINGS_EXPORT_EXCEL_FAILED
  };
}
