import {
  EMPLOYEES_SELECT_ALL,
  EMPLOYEES_UNSELECT_ALL,
  EMPLOYEE_SELECTED,
  EMPLOYEES_LOAD,
  EMPLOYEES_LOAD_SUCCESS,
  EMPLOYEES_LOAD_FAILED,
  EMPLOYEE_LOAD,
  EMPLOYEE_LOAD_SUCCESS,
  EMPLOYEE_LOAD_FAILED,
  EMPLOYEE_ADD,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_ADD_FAILED,
  EMPLOYEE_UPDATE,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAILED,
  EMPLOYEE_DELETE,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAILED,
  EMPLOYEE_DELETE_ALL,
  EMPLOYEE_DELETE_ALL_SUCCESS,
  EMPLOYEE_DELETE_ALL_FAILED,
  EMPLOYEES_EXPORT_EXCEL,
  EMPLOYEES_EXPORT_EXCEL_SUCCESS,
  EMPLOYEES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedEmployee: null,
  selectedEmployeeId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'code',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case EMPLOYEES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case EMPLOYEE_SELECTED:
      return {
        ...state,
        selectedEmployee: action.payload,
        selectedEmployeeId: action.payload.id
      };
    case EMPLOYEES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case EMPLOYEES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case EMPLOYEES_LOAD_FAILED:
      return {
        ...state
      };
    case EMPLOYEE_LOAD:
      return {
        ...state
      };
    case EMPLOYEE_LOAD_SUCCESS:
      return {
        ...state
      };
    case EMPLOYEE_LOAD_FAILED:
      return {
        ...state
      };
    case EMPLOYEE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case EMPLOYEE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedEmployee: action.payload
      };
    case EMPLOYEE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case EMPLOYEE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case EMPLOYEE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case EMPLOYEE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case EMPLOYEE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case EMPLOYEE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case EMPLOYEE_DELETE_ALL:
      return {
        ...state,
        hideLoading: false
      };
    case EMPLOYEE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case EMPLOYEE_DELETE_ALL_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case EMPLOYEES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case EMPLOYEES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case EMPLOYEES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
