import {
  PRODUCTEXPORTVOUCHERS_SELECT_ALL,
  PRODUCTEXPORTVOUCHERS_UNSELECT_ALL,
  PRODUCTEXPORTVOUCHER_SELECTED,
  PRODUCTEXPORTVOUCHERS_LOAD,
  PRODUCTEXPORTVOUCHERS_LOAD_SUCCESS,
  PRODUCTEXPORTVOUCHERS_LOAD_FAILED,
  PRODUCTEXPORTVOUCHER_LOAD,
  PRODUCTEXPORTVOUCHER_LOAD_SUCCESS,
  PRODUCTEXPORTVOUCHER_LOAD_FAILED,
  PRODUCTEXPORTVOUCHER_ADD,
  PRODUCTEXPORTVOUCHER_ADD_SUCCESS,
  PRODUCTEXPORTVOUCHER_ADD_FAILED,
  PRODUCTEXPORTVOUCHER_UPDATE,
  PRODUCTEXPORTVOUCHER_UPDATE_SUCCESS,
  PRODUCTEXPORTVOUCHER_UPDATE_FAILED,
  PRODUCTEXPORTVOUCHER_DELETE,
  PRODUCTEXPORTVOUCHER_DELETE_SUCCESS,
  PRODUCTEXPORTVOUCHER_DELETE_FAILED,
  PRODUCTEXPORTVOUCHER_DELETE_ALL,
  PRODUCTEXPORTVOUCHER_DELETE_ALL_SUCCESS,
  PRODUCTEXPORTVOUCHER_DELETE_ALL_FAILED,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllProductExportVouchers = () => {
  return {
    type: PRODUCTEXPORTVOUCHERS_SELECT_ALL
  }
}

export const unselectAllProductExportVouchers = () => {
  return {
    type: PRODUCTEXPORTVOUCHERS_UNSELECT_ALL
  }
}

export const selectProductExportVoucher = data => {
  return {
    type: PRODUCTEXPORTVOUCHER_SELECTED,
    payload: data
  }
}

export const loadProductExportVouchers = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHERS_LOAD,
    payload: data
  };
}

export const loadProductExportVouchersSuccess = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadProductExportVouchersFailed = () => {
  return {
    type: PRODUCTEXPORTVOUCHERS_LOAD_FAILED
  };
}

export const loadProductExportVoucher = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_LOAD
  };
}

export const loadProductExportVoucherSuccess = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadProductExportVoucherFailed = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_LOAD_FAILED
  };
}


export const addProductExportVoucher = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_ADD,
    payload: data
  };
}


export const addProductExportVoucherSuccess = (filter) => {
  return {
    type: PRODUCTEXPORTVOUCHER_ADD_SUCCESS,
    message: 'ProductExportVoucher added successfully.',
    payload: filter
  };
}

export const addProductExportVoucherFailed = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_ADD_FAILED
  };
}

export const updateProductExportVoucher = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_UPDATE,
    payload: data
  };
}


export const updateProductExportVoucherSuccess = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateProductExportVoucherFailed = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteProductExportVoucher = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_DELETE,
    payload: data
  };
}

export const deleteProductExportVoucherSuccess = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_DELETE_SUCCESS,
    message: 'ProductExportVoucher deleted successfully.'
  };
}

export const deleteProductExportVoucherFailed = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_DELETE_FAILED
  };
}

export const deleteAllProductExportVouchers = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllProductExportVouchersSuccess = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_DELETE_ALL_SUCCESS,
    message: 'ProductExportVoucher all deleted successfully.'
  };
}

export const deleteAllProductExportVouchersFailed = () => {
  return {
    type: PRODUCTEXPORTVOUCHER_DELETE_ALL_FAILED
  };
}

export const exportExcelProductExportVouchers = (filter) => {
  return {
    type:PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelProductExportVouchersSuccess = (data) => {
  return {
    type: PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelProductExportVouchersFailed = () => {
  return {
    type: PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_FAILED
  };
}