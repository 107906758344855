import {
  FORMRECEIVES_SELECT_ALL,
  FORMRECEIVES_UNSELECT_ALL,
  FORMRECEIVE_SELECTED,
  FORMRECEIVES_LOAD,
  FORMRECEIVES_LOAD_SUCCESS,
  FORMRECEIVES_LOAD_FAILED,
  FORMRECEIVE_LOAD,
  FORMRECEIVE_LOAD_SUCCESS,
  FORMRECEIVE_LOAD_FAILED,
  FORMRECEIVE_ADD,
  FORMRECEIVE_ADD_SUCCESS,
  FORMRECEIVE_ADD_FAILED,
  FORMRECEIVE_UPDATE,
  FORMRECEIVE_UPDATE_SUCCESS,
  FORMRECEIVE_UPDATE_FAILED,
  FORMRECEIVE_DELETE,
  FORMRECEIVE_DELETE_SUCCESS,
  FORMRECEIVE_DELETE_FAILED,
  FORMRECEIVE_DELETE_ALL,
  FORMRECEIVE_DELETE_ALL_SUCCESS,
  FORMRECEIVE_DELETE_ALL_FAILED,
  FORMRECEIVES_EXPORT_EXCEL,
  FORMRECEIVES_EXPORT_EXCEL_SUCCESS,
  FORMRECEIVES_EXPORT_EXCEL_FAILED,
  FORMRECEIVES_SET_FILTER
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedFormReceive: null,
  selectedFormReceiveId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'receiveDate',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORMRECEIVES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case FORMRECEIVES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case FORMRECEIVE_SELECTED:
      return {
        ...state,
        selectedFormReceive: action.payload,
        selectedFormReceiveId: action.payload.id
      };
    case FORMRECEIVES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case FORMRECEIVES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case FORMRECEIVES_LOAD_FAILED:
      return {
        ...state
      };
    case FORMRECEIVE_LOAD:
      return {
        ...state
      };
    case FORMRECEIVE_LOAD_SUCCESS:
      return {
        ...state
      };
    case FORMRECEIVE_LOAD_FAILED:
      return {
        ...state
      };
    case FORMRECEIVE_ADD:
      return {
        ...state,
        hideLoading: false,
        selectedFormReceive: undefined
      };
    case FORMRECEIVE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedFormReceive: action.payload
      };
    case FORMRECEIVE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true,
        selectedFormReceive: undefined
      };
    case FORMRECEIVE_UPDATE:
      return {
        ...state,
        hideLoading: false,
        selectedFormReceive: undefined
      };
    case FORMRECEIVE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedFormReceive: action.payload
      };
    case FORMRECEIVE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true,
        selectedFormReceive: undefined
      };
    case FORMRECEIVE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case FORMRECEIVE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case FORMRECEIVE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case FORMRECEIVE_DELETE_ALL:
      return {
        ...state
      };
    case FORMRECEIVE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case FORMRECEIVE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case FORMRECEIVES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case FORMRECEIVES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case FORMRECEIVES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case FORMRECEIVES_SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
}
