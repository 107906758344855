import {
  SUPPLYFORMS_SELECT_ALL,
  SUPPLYFORMS_UNSELECT_ALL,
  SUPPLYFORM_SELECTED,
  SUPPLYFORMS_LOAD,
  SUPPLYFORMS_LOAD_SUCCESS,
  SUPPLYFORMS_LOAD_FAILED,
  SUPPLYFORM_LOAD,
  SUPPLYFORM_LOAD_SUCCESS,
  SUPPLYFORM_LOAD_FAILED,
  SUPPLYFORM_ADD,
  SUPPLYFORM_ADD_SUCCESS,
  SUPPLYFORM_ADD_FAILED,
  SUPPLYFORM_UPDATE,
  SUPPLYFORM_UPDATE_SUCCESS,
  SUPPLYFORM_UPDATE_FAILED,
  SUPPLYFORM_DELETE,
  SUPPLYFORM_DELETE_SUCCESS,
  SUPPLYFORM_DELETE_FAILED,
  SUPPLYFORM_DELETE_ALL,
  SUPPLYFORM_DELETE_ALL_SUCCESS,
  SUPPLYFORM_DELETE_ALL_FAILED,
  SUPPLYFORMS_EXPORT_EXCEL,
  SUPPLYFORMS_EXPORT_EXCEL_SUCCESS,
  SUPPLYFORMS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSupplyForms = () => {
  return {
    type: SUPPLYFORMS_SELECT_ALL
  }
}

export const unselectAllSupplyForms = () => {
  return {
    type: SUPPLYFORMS_UNSELECT_ALL
  }
}

export const selectSupplyForm = data => {
  return {
    type: SUPPLYFORM_SELECTED,
    payload: data
  }
}

export const loadSupplyForms = (data) => {
  return {
    type: SUPPLYFORMS_LOAD,
    payload: data
  };
}

export const loadSupplyFormsSuccess = (data) => {
  return {
    type: SUPPLYFORMS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSupplyFormsFailed = () => {
  return {
    type: SUPPLYFORMS_LOAD_FAILED
  };
}

export const loadSupplyForm = () => {
  return {
    type: SUPPLYFORM_LOAD
  };
}

export const loadSupplyFormSuccess = (data) => {
  return {
    type: SUPPLYFORM_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSupplyFormFailed = () => {
  return {
    type: SUPPLYFORM_LOAD_FAILED
  };
}

export const addSupplyForm = (data) => {
  return {
    type: SUPPLYFORM_ADD,
    payload: data
  };
}

export const addSupplyFormSuccess = (filter) => {
  return {
    type: SUPPLYFORM_ADD_SUCCESS,
    message: 'SupplyForm added successfully.',
    payload: filter
  };
}

export const addSupplyFormFailed = () => {
  return {
    type: SUPPLYFORM_ADD_FAILED
  };
}

export const updateSupplyForm = (data) => {
  return {
    type: SUPPLYFORM_UPDATE,
    payload: data
  };
}


export const updateSupplyFormSuccess = (data) => {
  return {
    type: SUPPLYFORM_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSupplyFormFailed = (data) => {
  return {
    type: SUPPLYFORM_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSupplyForm = (data) => {
  return {
    type: SUPPLYFORM_DELETE,
    payload: data
  };
}

export const deleteSupplyFormSuccess = () => {
  return {
    type: SUPPLYFORM_DELETE_SUCCESS,
    message: 'SupplyForm deleted successfully.'
  };
}

export const deleteSupplyFormFailed = () => {
  return {
    type: SUPPLYFORM_DELETE_FAILED
  };
}

export const deleteAllSupplyForms = (data) => {
  return {
    type: SUPPLYFORM_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSupplyFormsSuccess = () => {
  return {
    type: SUPPLYFORM_DELETE_ALL_SUCCESS,
    message: 'SupplyForm all deleted successfully.'
  };
}

export const deleteAllSupplyFormsFailed = () => {
  return {
    type: SUPPLYFORM_DELETE_ALL_FAILED
  };
}

export const exportExcelSupplyForms = (filter) => {
  return {
    type:SUPPLYFORMS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSupplyFormsSuccess = (data) => {
  return {
    type: SUPPLYFORMS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSupplyFormsFailed = () => {
  return {
    type: SUPPLYFORMS_EXPORT_EXCEL_FAILED
  };
}