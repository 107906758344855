import {
  SUPPLYVOUCHERITEMS_SELECT_ALL,
  SUPPLYVOUCHERITEMS_UNSELECT_ALL,
  SUPPLYVOUCHERITEM_SELECTED,
  SUPPLYVOUCHERITEMS_LOAD,
  SUPPLYVOUCHERITEMS_LOAD_SUCCESS,
  SUPPLYVOUCHERITEMS_LOAD_FAILED,
  SUPPLYVOUCHERITEM_LOAD,
  SUPPLYVOUCHERITEM_LOAD_SUCCESS,
  SUPPLYVOUCHERITEM_LOAD_FAILED,
  SUPPLYVOUCHERITEM_ADD,
  SUPPLYVOUCHERITEM_ADD_SUCCESS,
  SUPPLYVOUCHERITEM_ADD_FAILED,
  SUPPLYVOUCHERITEM_UPDATE,
  SUPPLYVOUCHERITEM_UPDATE_SUCCESS,
  SUPPLYVOUCHERITEM_UPDATE_FAILED,
  SUPPLYVOUCHERITEM_DELETE,
  SUPPLYVOUCHERITEM_DELETE_SUCCESS,
  SUPPLYVOUCHERITEM_DELETE_FAILED,
  SUPPLYVOUCHERITEM_DELETE_ALL,
  SUPPLYVOUCHERITEM_DELETE_ALL_SUCCESS,
  SUPPLYVOUCHERITEM_DELETE_ALL_FAILED,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL_SUCCESS,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';

export const selectAllSupplyVoucherItems = () => {
  return {
    type: SUPPLYVOUCHERITEMS_SELECT_ALL
  }
}

export const unselectAllSupplyVoucherItems = () => {
  return {
    type: SUPPLYVOUCHERITEMS_UNSELECT_ALL
  }
}

export const selectSupplyVoucherItem = data => {
  return {
    type: SUPPLYVOUCHERITEM_SELECTED,
    payload: data
  }
}

export const loadSupplyVoucherItems = (data) => {
  return {
    type: SUPPLYVOUCHERITEMS_LOAD,
    payload: data
  };
}

export const loadSupplyVoucherItemsSuccess = (data) => {
  return {
    type: SUPPLYVOUCHERITEMS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSupplyVoucherItemsFailed = () => {
  return {
    type: SUPPLYVOUCHERITEMS_LOAD_FAILED
  };
}

export const loadSupplyVoucherItem = () => {
  return {
    type: SUPPLYVOUCHERITEM_LOAD
  };
}

export const loadSupplyVoucherItemSuccess = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSupplyVoucherItemFailed = () => {
  return {
    type: SUPPLYVOUCHERITEM_LOAD_FAILED
  };
}


export const addSupplyVoucherItem = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_ADD,
    payload: data
  };
}


export const addSupplyVoucherItemSuccess = (filter) => {
  return {
    type: SUPPLYVOUCHERITEM_ADD_SUCCESS,
    message: 'SupplyVoucherItem added successfully.',
    payload: filter
  };
}

export const addSupplyVoucherItemFailed = () => {
  return {
    type: SUPPLYVOUCHERITEM_ADD_FAILED
  };
}

export const updateSupplyVoucherItem = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_UPDATE,
    payload: data
  };
}


export const updateSupplyVoucherItemSuccess = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSupplyVoucherItemFailed = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSupplyVoucherItem = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_DELETE,
    payload: data
  };
}

export const deleteSupplyVoucherItemSuccess = () => {
  return {
    type: SUPPLYVOUCHERITEM_DELETE_SUCCESS,
    message: 'SupplyVoucherItem deleted successfully.'
  };
}

export const deleteSupplyVoucherItemFailed = () => {
  return {
    type: SUPPLYVOUCHERITEM_DELETE_FAILED
  };
}

export const deleteAllSupplyVoucherItems = (data) => {
  return {
    type: SUPPLYVOUCHERITEM_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSupplyVoucherItemsSuccess = () => {
  return {
    type: SUPPLYVOUCHERITEM_DELETE_ALL_SUCCESS,
    message: 'SupplyVoucherItem all deleted successfully.'
  };
}

export const deleteAllSupplyVoucherItemsFailed = () => {
  return {
    type: SUPPLYVOUCHERITEM_DELETE_ALL_FAILED
  };
}

export const exportExcelSupplyVoucherItems = (filter) => {
  return {
    type: SUPPLYVOUCHERITEMS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSupplyVoucherItemsSuccess = (data) => {
  return {
    type: SUPPLYVOUCHERITEMS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSupplyVoucherItemsFailed = () => {
  return {
    type: SUPPLYVOUCHERITEMS_EXPORT_EXCEL_FAILED
  };
}
