import {
  RECEIPTVOUCHERS_SELECT_ALL,
  RECEIPTVOUCHERS_UNSELECT_ALL,
  RECEIPTVOUCHER_SELECTED,
  RECEIPTVOUCHERS_LOAD,
  RECEIPTVOUCHERS_LOAD_SUCCESS,
  RECEIPTVOUCHERS_LOAD_FAILED,
  RECEIPTVOUCHER_LOAD,
  RECEIPTVOUCHER_LOAD_SUCCESS,
  RECEIPTVOUCHER_LOAD_FAILED,
  RECEIPTVOUCHER_ADD,
  RECEIPTVOUCHER_ADD_SUCCESS,
  RECEIPTVOUCHER_ADD_FAILED,
  RECEIPTVOUCHER_UPDATE,
  RECEIPTVOUCHER_UPDATE_SUCCESS,
  RECEIPTVOUCHER_UPDATE_FAILED,
  RECEIPTVOUCHER_DELETE,
  RECEIPTVOUCHER_DELETE_SUCCESS,
  RECEIPTVOUCHER_DELETE_FAILED,
  RECEIPTVOUCHER_DELETE_ALL,
  RECEIPTVOUCHER_DELETE_ALL_SUCCESS,
  RECEIPTVOUCHER_DELETE_ALL_FAILED,
  RECEIPTVOUCHERS_EXPORT_EXCEL,
  RECEIPTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  RECEIPTVOUCHERS_EXPORT_EXCEL_FAILED,
  RECEIPTVOUCHERS_REPORT,
  RECEIPTVOUCHERS_REPORT_SUCCESS,
  RECEIPTVOUCHERS_REPORT_FAILED
} from '../constants/ActionTypes';

export const selectAllReceiptVouchers = () => {
  return {
    type: RECEIPTVOUCHERS_SELECT_ALL
  }
}

export const unselectAllReceiptVouchers = () => {
  return {
    type: RECEIPTVOUCHERS_UNSELECT_ALL
  }
}

export const selectReceiptVoucher = data => {
  return {
    type: RECEIPTVOUCHER_SELECTED,
    payload: data
  }
}

export const loadReceiptVouchers = (data) => {
  return {
    type: RECEIPTVOUCHERS_LOAD,
    payload: data
  };
}

export const loadReceiptVouchersSuccess = (data) => {
  return {
    type: RECEIPTVOUCHERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadReceiptVouchersFailed = () => {
  return {
    type: RECEIPTVOUCHERS_LOAD_FAILED
  };
}

export const loadReceiptVoucher = () => {
  return {
    type: RECEIPTVOUCHER_LOAD
  };
}

export const loadReceiptVoucherSuccess = (data) => {
  return {
    type: RECEIPTVOUCHER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadReceiptVoucherFailed = () => {
  return {
    type: RECEIPTVOUCHER_LOAD_FAILED
  };
}


export const addReceiptVoucher = (data) => {
  return {
    type: RECEIPTVOUCHER_ADD,
    payload: data
  };
}


export const addReceiptVoucherSuccess = (filter) => {
  return {
    type: RECEIPTVOUCHER_ADD_SUCCESS,
    message: 'ReceiptVoucher added successfully.',
    payload: filter
  };
}

export const addReceiptVoucherFailed = () => {
  return {
    type: RECEIPTVOUCHER_ADD_FAILED
  };
}

export const updateReceiptVoucher = (data) => {
  return {
    type: RECEIPTVOUCHER_UPDATE,
    payload: data
  };
}


export const updateReceiptVoucherSuccess = (data) => {
  return {
    type: RECEIPTVOUCHER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateReceiptVoucherFailed = (data) => {
  return {
    type: RECEIPTVOUCHER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteReceiptVoucher = (data) => {
  return {
    type: RECEIPTVOUCHER_DELETE,
    payload: data
  };
}

export const deleteReceiptVoucherSuccess = () => {
  return {
    type: RECEIPTVOUCHER_DELETE_SUCCESS,
    message: 'ReceiptVoucher deleted successfully.'
  };
}

export const deleteReceiptVoucherFailed = () => {
  return {
    type: RECEIPTVOUCHER_DELETE_FAILED
  };
}

export const deleteAllReceiptVouchers = (data) => {
  return {
    type: RECEIPTVOUCHER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllReceiptVouchersSuccess = () => {
  return {
    type: RECEIPTVOUCHER_DELETE_ALL_SUCCESS,
    message: 'ReceiptVoucher all deleted successfully.'
  };
}

export const deleteAllReceiptVouchersFailed = () => {
  return {
    type: RECEIPTVOUCHER_DELETE_ALL_FAILED
  };
}

export const exportExcelReceiptVouchers = (filter) => {
  return {
    type:RECEIPTVOUCHERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelReceiptVouchersSuccess = (data) => {
  return {
    type:RECEIPTVOUCHERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelReceiptVouchersFailed = () => {
  return {
    type:RECEIPTVOUCHERS_EXPORT_EXCEL_FAILED
  };
}

export const reportReceiptVouchers = (filter) => {
  return {
    type: RECEIPTVOUCHERS_REPORT,
    payload: filter
  }
}

export const reportReceiptVouchersSuccess = (data) => {
  return {
    type: RECEIPTVOUCHERS_REPORT_SUCCESS,
    payload: data
  };
}

export const reportReceiptVouchersFailed = () => {
  return {
    type: RECEIPTVOUCHERS_REPORT_FAILED
  };
}
