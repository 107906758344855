import {
  WASTES_SELECT_ALL,
  WASTES_UNSELECT_ALL,
  WASTE_SELECTED,
  WASTES_LOAD,
  WASTES_LOAD_SUCCESS,
  WASTES_LOAD_FAILED,
  WASTE_LOAD,
  WASTE_LOAD_SUCCESS,
  WASTE_LOAD_FAILED,
  WASTE_ADD,
  WASTE_ADD_SUCCESS,
  WASTE_ADD_FAILED,
  WASTE_UPDATE,
  WASTE_UPDATE_SUCCESS,
  WASTE_UPDATE_FAILED,
  WASTE_DELETE,
  WASTE_DELETE_SUCCESS,
  WASTE_DELETE_FAILED,
  WASTE_DELETE_ALL,
  WASTE_DELETE_ALL_SUCCESS,
  WASTE_DELETE_ALL_FAILED,
  WASTES_EXPORT_EXCEL,
  WASTES_EXPORT_EXCEL_SUCCESS,
  WASTES_EXPORT_EXCEL_FAILED,
  WASTES_SET_FILTER
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedWaste: null,
  selectedWasteId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WASTES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case WASTES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case WASTE_SELECTED:
      return {
        ...state,
        selectedWaste: action.payload,
        selectedWasteId: action.payload.id
      };
    case WASTES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case WASTES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case WASTES_LOAD_FAILED:
      return {
        ...state
      };
    case WASTE_LOAD:
      return {
        ...state
      };
    case WASTE_LOAD_SUCCESS:
      return {
        ...state
      };
    case WASTE_LOAD_FAILED:
      return {
        ...state
      };
    case WASTE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case WASTE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case WASTE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case WASTE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case WASTE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case WASTE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case WASTE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case WASTE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case WASTE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case WASTE_DELETE_ALL:
      return {
        ...state
      };
    case WASTE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case WASTE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case WASTES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case WASTES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case WASTES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case WASTES_SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
}
