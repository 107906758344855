import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSupplyVoucherItems as loadSupplyVoucherItemsAction,
  loadSupplyVoucherItemsSuccess,
  updateSupplyVoucherItemSuccess,
  addSupplyVoucherItemSuccess,
  deleteSupplyVoucherItemSuccess,
  deleteAllSupplyVoucherItemsSuccess,
  exportExcelSupplyVoucherItems,
  exportExcelSupplyVoucherItemsSuccess,
  exportExcelSupplyVoucherItemsFailed,
} from '../actions/SupplyVoucherItem';

import {
  SUPPLYVOUCHERITEMS_LOAD,
  SUPPLYVOUCHERITEM_ADD,
  SUPPLYVOUCHERITEM_UPDATE,
  SUPPLYVOUCHERITEM_DELETE,
  SUPPLYVOUCHERITEM_DELETE_ALL,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSupplyVoucherItemById = (id, callback) => {
  Api().get('supply-voucher-item/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllSupplyVoucherItems = (callback, params) => {
  if (!params) {
    params = {
      offset: 0,
      limit: GET_ALL_SIZE,
      orderBy: 'wasteCode',
      descending: false
    }
  }
  
  Api().post('supply-voucher-item/search', {
    ...params,
    offset: 0,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadSupplyVoucherItemsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`supply-voucher-item/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`supply-voucher-item`, { params })
    .then(data => data)
    .catch(error => error);
}

const addSupplyVoucherItemRequest = async (payload) =>
  await Api().post(`supply-voucher-item`, payload)
    .then(data => data)
    .catch(error => error);

const updateSupplyVoucherItemRequest = async (payload) =>
  await Api().patch(`supply-voucher-item/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSupplyVoucherItemRequest = async (id) =>
  await Api().delete(`supply-voucher-item/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSupplyVoucherItems({ payload }) {
  try {
    const data = yield call(loadSupplyVoucherItemsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadSupplyVoucherItemsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyVoucherItems() {
  yield takeEvery(SUPPLYVOUCHERITEMS_LOAD, loadSupplyVoucherItems);
}

function* loadSupplyVoucherItem({ selectedSupplyVoucherItemId }) {
  try {
    const data = yield call(loadSupplyVoucherItemsRequest, selectedSupplyVoucherItemId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadSupplyVoucherItemsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyVoucherItem() {
  yield takeEvery(SUPPLYVOUCHERITEMS_LOAD, loadSupplyVoucherItem);
}

function* addSupplyVoucherItem({ payload }) {
  try {
    const data = yield call(addSupplyVoucherItemRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addSupplyVoucherItemSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSupplyVoucherItem() {
  yield takeEvery(SUPPLYVOUCHERITEM_ADD, addSupplyVoucherItem);
}

function* updateSupplyVoucherItem({ payload }) {
  try {
    const data = yield call(updateSupplyVoucherItemRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateSupplyVoucherItemSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSupplyVoucherItem() {
  yield takeEvery(SUPPLYVOUCHERITEM_UPDATE, updateSupplyVoucherItem);
}

function* deleteSupplyVoucherItem({ payload }) {
  try {
    const data = yield call(deleteSupplyVoucherItemRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(deleteSupplyVoucherItemSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSupplyVoucherItems({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSupplyVoucherItemRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllSupplyVoucherItemsSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSupplyVoucherItem() {
  yield takeEvery(SUPPLYVOUCHERITEM_DELETE, deleteSupplyVoucherItem);
}

function* processDeleteAllSupplyVoucherItems() {
  yield takeEvery(SUPPLYVOUCHERITEM_DELETE_ALL, deleteAllSupplyVoucherItems);
}

const exportSupplyVoucherItemsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`supply-voucher-item/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportSupplyVoucherItems({ payload }) {
  try {
    const data = yield call(exportSupplyVoucherItemsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSupplyVoucherItemsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSupplyVoucherItems() {
  yield takeEvery(SUPPLYVOUCHERITEMS_EXPORT_EXCEL, exportSupplyVoucherItems);
}

export default function* SupplyVoucherItemSagas() {
  yield all([fork(processLoadSupplyVoucherItems),
  fork(processLoadSupplyVoucherItem),
  fork(processAddSupplyVoucherItem),
  fork(processUpdateSupplyVoucherItem),
  fork(processDeleteAllSupplyVoucherItems),
  fork(processExportSupplyVoucherItems),
  fork(processDeleteSupplyVoucherItem)]);
}
