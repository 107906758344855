import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadProductExportVouchers as loadProductExportVouchersAction,
  loadProductExportVouchersSuccess,
  addProductExportVoucherSuccess,
  updateProductExportVoucherSuccess,
  exportExcelProductExportVouchersSuccess
} from '../actions/ProductExportVoucher';

import {
  PRODUCTEXPORTVOUCHERS_LOAD,
  PRODUCTEXPORTVOUCHER_ADD,
  PRODUCTEXPORTVOUCHER_UPDATE,
  PRODUCTEXPORTVOUCHER_DELETE,
  PRODUCTEXPORTVOUCHER_DELETE_ALL,
  PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getProductExportVoucherById = (id, callback) => {
  Api().get('product-export-voucher/' + id).then(response => {
    callback(response.data);
  })
}

export const changeProductExportVoucherStatus = (model, callback) => {
  Api().post('product-export-voucher/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllProductExportVouchers = (callback) => {
  Api().post('product-export-voucher/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxProductExportVoucherCodeSorted = (callback) => {
  Api().post('product-export-voucher/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadProductExportVouchersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`product-export-voucher/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`product-export-voucher`, { params })
    .then(data => data)
    .catch(error => error);
}


const addProductExportVoucherRequest = async (payload) =>
  await Api().post(`product-export-voucher`, payload)
    .then(data => data)
    .catch(error => error);

const updateProductExportVoucherRequest = async (payload) =>
  await Api().patch(`product-export-voucher/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteProductExportVoucherRequest = async (id) =>
  await Api().delete(`product-export-voucher/${id}`)
    .then(data => data)
    .catch(error => error);

const exportProductExportVouchersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`product-export-voucher/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadProductExportVouchers({ payload }) {
  try {

    const data = yield call(loadProductExportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductExportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProductExportVouchers() {
  yield takeEvery(PRODUCTEXPORTVOUCHERS_LOAD, loadProductExportVouchers);
}

function* loadProductExportVoucher({ selectedProductExportVoucherId }) {
  try {

    const data = yield call(loadProductExportVouchersRequest, selectedProductExportVoucherId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductExportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProductExportVoucher() {
  yield takeEvery(PRODUCTEXPORTVOUCHERS_LOAD, loadProductExportVoucher);
}


function* addProductExportVoucher({ payload }) {
  try {
    const data = yield call(addProductExportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addProductExportVoucherSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddProductExportVoucher() {
  yield takeEvery(PRODUCTEXPORTVOUCHER_ADD, addProductExportVoucher);
}


function* updateProductExportVoucher({ payload }) {
  try {
    const data = yield call(updateProductExportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateProductExportVoucherSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateProductExportVoucher() {
  yield takeEvery(PRODUCTEXPORTVOUCHER_UPDATE, updateProductExportVoucher);
}

function* deleteProductExportVoucher({ payload }) {
  try {
    const data = yield call(deleteProductExportVoucherRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductExportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllProductExportVouchers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteProductExportVoucherRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadProductExportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteProductExportVoucher() {
  yield takeEvery(PRODUCTEXPORTVOUCHER_DELETE, deleteProductExportVoucher);
}

function* processDeleteAllProductExportVouchers() {
  yield takeEvery(PRODUCTEXPORTVOUCHER_DELETE_ALL, deleteAllProductExportVouchers);
}

function* exportProductExportVouchers({ payload }) {
  try {
    const data = yield call(exportProductExportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelProductExportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportProductExportVouchers() {
  yield takeEvery(PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL, exportProductExportVouchers);
}

export default function* ProductExportVoucherSagas() {
  yield all([fork(processLoadProductExportVouchers),
  fork(processLoadProductExportVoucher),
  fork(processAddProductExportVoucher),
  fork(processUpdateProductExportVoucher),
  fork(processDeleteAllProductExportVouchers),
  fork(processDeleteProductExportVoucher),
  fork(processExportProductExportVouchers)]);
}
