import {
  LISTABLESETTINGS_SELECT_ALL,
  LISTABLESETTINGS_UNSELECT_ALL,
  LISTABLESETTING_SELECTED,
  LISTABLESETTINGS_LOAD,
  LISTABLESETTINGS_LOAD_SUCCESS,
  LISTABLESETTINGS_LOAD_FAILED,
  LISTABLESETTING_LOAD,
  LISTABLESETTING_LOAD_SUCCESS,
  LISTABLESETTING_LOAD_FAILED,
  LISTABLESETTING_ADD,
  LISTABLESETTING_ADD_SUCCESS,
  LISTABLESETTING_ADD_FAILED,
  LISTABLESETTING_UPDATE,
  LISTABLESETTING_UPDATE_SUCCESS,
  LISTABLESETTING_UPDATE_FAILED,
  LISTABLESETTING_DELETE,
  LISTABLESETTING_DELETE_SUCCESS,
  LISTABLESETTING_DELETE_FAILED,
  LISTABLESETTING_DELETE_ALL,
  LISTABLESETTING_DELETE_ALL_SUCCESS,
  LISTABLESETTING_DELETE_ALL_FAILED,
  LISTABLESETTINGS_EXPORT_EXCEL,
  LISTABLESETTINGS_EXPORT_EXCEL_SUCCESS,
  LISTABLESETTINGS_EXPORT_EXCEL_FAILED,
  LISTABLESETTINGS_REPORT,
  LISTABLESETTINGS_REPORT_SUCCESS,
  LISTABLESETTINGS_REPORT_FAILED
} from '../constants/ActionTypes';

export const selectAllListableSettings = () => {
  return {
    type: LISTABLESETTINGS_SELECT_ALL
  }
}

export const unselectAllListableSettings = () => {
  return {
    type: LISTABLESETTINGS_UNSELECT_ALL
  }
}

export const selectListableSetting = data => {
  return {
    type: LISTABLESETTING_SELECTED,
    payload: data
  }
}

export const loadListableSettings = (data) => {
  return {
    type: LISTABLESETTINGS_LOAD,
    payload: data
  };
}

export const loadListableSettingsSuccess = (data) => {
  return {
    type: LISTABLESETTINGS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadListableSettingsFailed = () => {
  return {
    type: LISTABLESETTINGS_LOAD_FAILED
  };
}

export const loadListableSetting = () => {
  return {
    type: LISTABLESETTING_LOAD
  };
}

export const loadListableSettingSuccess = (data) => {
  return {
    type: LISTABLESETTING_LOAD_SUCCESS,
    payload: data
  };
}


export const loadListableSettingFailed = () => {
  return {
    type: LISTABLESETTING_LOAD_FAILED
  };
}


export const addListableSetting = (data) => {
  return {
    type: LISTABLESETTING_ADD,
    payload: data
  };
}


export const addListableSettingSuccess = (filter) => {
  return {
    type: LISTABLESETTING_ADD_SUCCESS,
    message: 'ListableSetting added successfully.',
    payload: filter
  };
}

export const addListableSettingFailed = () => {
  return {
    type: LISTABLESETTING_ADD_FAILED
  };
}

export const updateListableSetting = (data) => {
  return {
    type: LISTABLESETTING_UPDATE,
    payload: data
  };
}


export const updateListableSettingSuccess = (data) => {
  return {
    type: LISTABLESETTING_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateListableSettingFailed = (data) => {
  return {
    type: LISTABLESETTING_UPDATE_FAILED,
    payload: data
  };
}

export const deleteListableSetting = (data) => {
  return {
    type: LISTABLESETTING_DELETE,
    payload: data
  };
}

export const deleteListableSettingSuccess = () => {
  return {
    type: LISTABLESETTING_DELETE_SUCCESS,
    message: 'ListableSetting deleted successfully.'
  };
}

export const deleteListableSettingFailed = () => {
  return {
    type: LISTABLESETTING_DELETE_FAILED
  };
}

export const deleteAllListableSettings = (data) => {
  return {
    type: LISTABLESETTING_DELETE_ALL,
    payload: data
  };
}

export const deleteAllListableSettingsSuccess = () => {
  return {
    type: LISTABLESETTING_DELETE_ALL_SUCCESS,
    message: 'ListableSetting all deleted successfully.'
  };
}

export const deleteAllListableSettingsFailed = () => {
  return {
    type: LISTABLESETTING_DELETE_ALL_FAILED
  };
}

export const exportExcelListableSettings = (filter) => {
  return {
    type:LISTABLESETTINGS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelListableSettingsSuccess = (data) => {
  return {
    type:LISTABLESETTINGS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelListableSettingsFailed = () => {
  return {
    type:LISTABLESETTINGS_EXPORT_EXCEL_FAILED
  };
}

export const reportListableSettings = (filter) => {
  return {
    type: LISTABLESETTINGS_REPORT,
    payload: filter
  }
}

export const reportListableSettingsSuccess = (data) => {
  return {
    type: LISTABLESETTINGS_REPORT_SUCCESS,
    payload: data
  };
}

export const reportListableSettingsFailed = () => {
  return {
    type: LISTABLESETTINGS_REPORT_FAILED
  };
}
