import {
  HEALTHDECLARATIONS_SELECT_ALL,
  HEALTHDECLARATIONS_UNSELECT_ALL,
  HEALTHDECLARATION_SELECTED,
  HEALTHDECLARATIONS_LOAD,
  HEALTHDECLARATIONS_LOAD_SUCCESS,
  HEALTHDECLARATIONS_LOAD_FAILED,
  HEALTHDECLARATION_LOAD,
  HEALTHDECLARATION_LOAD_SUCCESS,
  HEALTHDECLARATION_LOAD_FAILED,
  HEALTHDECLARATION_ADD,
  HEALTHDECLARATION_ADD_SUCCESS,
  HEALTHDECLARATION_ADD_FAILED,
  HEALTHDECLARATION_UPDATE,
  HEALTHDECLARATION_UPDATE_SUCCESS,
  HEALTHDECLARATION_UPDATE_FAILED,
  HEALTHDECLARATION_DELETE,
  HEALTHDECLARATION_DELETE_SUCCESS,
  HEALTHDECLARATION_DELETE_FAILED,
  HEALTHDECLARATION_DELETE_ALL,
  HEALTHDECLARATION_DELETE_ALL_SUCCESS,
  HEALTHDECLARATION_DELETE_ALL_FAILED,
  HEALTHDECLARATIONS_EXPORT_EXCEL,
  HEALTHDECLARATIONS_EXPORT_EXCEL_SUCCESS,
  HEALTHDECLARATIONS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllHealthDeclarations = () => {
  return {
    type: HEALTHDECLARATIONS_SELECT_ALL
  }
}

export const unselectAllHealthDeclarations = () => {
  return {
    type: HEALTHDECLARATIONS_UNSELECT_ALL
  }
}

export const selectHealthDeclaration = data => {
  return {
    type: HEALTHDECLARATION_SELECTED,
    payload: data
  }
}

export const loadHealthDeclarations = (data) => {
  return {
    type: HEALTHDECLARATIONS_LOAD,
    payload: data
  };
}

export const loadHealthDeclarationsSuccess = (data) => {
  return {
    type: HEALTHDECLARATIONS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadHealthDeclarationsFailed = () => {
  return {
    type: HEALTHDECLARATIONS_LOAD_FAILED
  };
}

export const loadHealthDeclaration = () => {
  return {
    type: HEALTHDECLARATION_LOAD
  };
}

export const loadHealthDeclarationSuccess = (data) => {
  return {
    type: HEALTHDECLARATION_LOAD_SUCCESS,
    payload: data
  };
}


export const loadHealthDeclarationFailed = () => {
  return {
    type: HEALTHDECLARATION_LOAD_FAILED
  };
}


export const addHealthDeclaration = (data) => {
  return {
    type: HEALTHDECLARATION_ADD,
    payload: data
  };
}


export const addHealthDeclarationSuccess = (filter) => {
  return {
    type: HEALTHDECLARATION_ADD_SUCCESS,
    message: 'HealthDeclaration added successfully.',
    payload: filter
  };
}

export const addHealthDeclarationFailed = () => {
  return {
    type: HEALTHDECLARATION_ADD_FAILED
  };
}

export const updateHealthDeclaration = (data) => {
  return {
    type: HEALTHDECLARATION_UPDATE,
    payload: data
  };
}

export const updateHealthDeclarationSuccess = (data) => {
  return {
    type: HEALTHDECLARATION_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateHealthDeclarationFailed = (data) => {
  return {
    type: HEALTHDECLARATION_UPDATE_FAILED,
    payload: data
  };
}

export const deleteHealthDeclaration = (data) => {
  return {
    type: HEALTHDECLARATION_DELETE,
    payload: data
  };
}

export const deleteHealthDeclarationSuccess = () => {
  return {
    type: HEALTHDECLARATION_DELETE_SUCCESS,
    message: 'HealthDeclaration deleted successfully.'
  };
}

export const deleteHealthDeclarationFailed = () => {
  return {
    type: HEALTHDECLARATION_DELETE_FAILED
  };
}

export const deleteAllHealthDeclarations = (data) => {
  return {
    type: HEALTHDECLARATION_DELETE_ALL,
    payload: data
  };
}

export const deleteAllHealthDeclarationsSuccess = () => {
  return {
    type: HEALTHDECLARATION_DELETE_ALL_SUCCESS,
    message: 'HealthDeclaration all deleted successfully.'
  };
}

export const deleteAllHealthDeclarationsFailed = () => {
  return {
    type: HEALTHDECLARATION_DELETE_ALL_FAILED
  };
}

export const exportExcelHealthDeclarations = (filter) => {
  return {
    type:HEALTHDECLARATIONS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelHealthDeclarationsSuccess = (data) => {
  return {
    type:HEALTHDECLARATIONS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelHealthDeclarationsFailed = () => {
  return {
    type:HEALTHDECLARATIONS_EXPORT_EXCEL_FAILED
  };
}
