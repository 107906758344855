import {
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_SELECT_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_SELECTED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllFormProcessingFormReceiveQuotationWastes = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_SELECT_ALL
  }
}

export const unselectAllFormProcessingFormReceiveQuotationWastes = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL
  }
}

export const selectFormProcessingFormReceiveQuotationWaste = data => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_SELECTED,
    payload: data
  }
}

export const loadFormProcessingFormReceiveQuotationWastes = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD,
    payload: data
  };
}

export const loadFormProcessingFormReceiveQuotationWastesSuccess = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadFormProcessingFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD_FAILED
  };
}

export const loadFormProcessingFormReceiveQuotationWaste = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD
  };
}

export const loadFormProcessingFormReceiveQuotationWasteSuccess = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadFormProcessingFormReceiveQuotationWasteFailed = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_LOAD_FAILED
  };
}


export const addFormProcessingFormReceiveQuotationWaste = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD,
    payload: data
  };
}


export const addFormProcessingFormReceiveQuotationWasteSuccess = (filter) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS,
    message: 'FormProcessingFormReceiveQuotationWaste added successfully.',
    payload: filter
  };
}

export const addFormProcessingFormReceiveQuotationWasteFailed = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD_FAILED
  };
}

export const updateFormProcessingFormReceiveQuotationWaste = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE,
    payload: data
  };
}


export const updateFormProcessingFormReceiveQuotationWasteSuccess = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateFormProcessingFormReceiveQuotationWasteFailed = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteFormProcessingFormReceiveQuotationWaste = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE,
    payload: data
  };
}

export const deleteFormProcessingFormReceiveQuotationWasteSuccess = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS,
    message: 'FormProcessingFormReceiveQuotationWaste deleted successfully.'
  };
}

export const deleteFormProcessingFormReceiveQuotationWasteFailed = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_FAILED
  };
}

export const deleteAllFormProcessingFormReceiveQuotationWastes = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllFormProcessingFormReceiveQuotationWastesSuccess = () => {
  return { 
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS,
    message: 'FormProcessingFormReceiveQuotationWaste all deleted successfully.'
  };
}

export const deleteAllFormProcessingFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED
  };
}


export const exportExcelFormProcessingFormReceiveQuotationWastes = (filter) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelFormProcessingFormReceiveQuotationWastesSuccess = (data) => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelFormProcessingFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED
  };
}
