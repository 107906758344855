import {
  SUPPLYVOUCHERITEMS_SELECT_ALL,
  SUPPLYVOUCHERITEMS_UNSELECT_ALL,
  SUPPLYVOUCHERITEM_SELECTED,
  SUPPLYVOUCHERITEMS_LOAD,
  SUPPLYVOUCHERITEMS_LOAD_SUCCESS,
  SUPPLYVOUCHERITEMS_LOAD_FAILED,
  SUPPLYVOUCHERITEM_LOAD,
  SUPPLYVOUCHERITEM_LOAD_SUCCESS,
  SUPPLYVOUCHERITEM_LOAD_FAILED,
  SUPPLYVOUCHERITEM_ADD,
  SUPPLYVOUCHERITEM_ADD_SUCCESS,
  SUPPLYVOUCHERITEM_ADD_FAILED,
  SUPPLYVOUCHERITEM_UPDATE,
  SUPPLYVOUCHERITEM_UPDATE_SUCCESS,
  SUPPLYVOUCHERITEM_UPDATE_FAILED,
  SUPPLYVOUCHERITEM_DELETE,
  SUPPLYVOUCHERITEM_DELETE_SUCCESS,
  SUPPLYVOUCHERITEM_DELETE_FAILED,
  SUPPLYVOUCHERITEM_DELETE_ALL,
  SUPPLYVOUCHERITEM_DELETE_ALL_SUCCESS,
  SUPPLYVOUCHERITEM_DELETE_ALL_FAILED,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL_SUCCESS,
  SUPPLYVOUCHERITEMS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSupplyVoucherItem: null,
  selectedSupplyVoucherItemId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'receiveDate',
    descending: false,
    filterGroups: null
  },
  wastes: [],
  totalAmountResult: 0,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLYVOUCHERITEMS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SUPPLYVOUCHERITEMS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SUPPLYVOUCHERITEM_SELECTED:
      return {
        ...state,
        selectedSupplyVoucherItem: action.payload,
        selectedSupplyVoucherItemId: action.payload.id
      };
    case SUPPLYVOUCHERITEMS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SUPPLYVOUCHERITEMS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SUPPLYVOUCHERITEMS_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYVOUCHERITEM_LOAD:
      return {
        ...state
      };
    case SUPPLYVOUCHERITEM_LOAD_SUCCESS:
      return {
        ...state
      };
    case SUPPLYVOUCHERITEM_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYVOUCHERITEM_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYVOUCHERITEM_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYVOUCHERITEM_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYVOUCHERITEM_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYVOUCHERITEM_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYVOUCHERITEM_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYVOUCHERITEM_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYVOUCHERITEM_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case SUPPLYVOUCHERITEM_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYVOUCHERITEM_DELETE_ALL:
      return {
        ...state
      };
    case SUPPLYVOUCHERITEM_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SUPPLYVOUCHERITEM_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case SUPPLYVOUCHERITEMS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case SUPPLYVOUCHERITEMS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case SUPPLYVOUCHERITEMS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
