import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {connect} from "react-redux";
import {setInitUrl} from "./actions/Auth";

const approvePaths = ['/customer-registration'];

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Iframe = React.lazy(() => import('./views/HealthDeclaration/HealthDeclaration'));
const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: {from: props.location}
          }}
        />}
  />;
class App extends Component {
  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }
  
  render() {
    const {match, location, initURL, authUser} = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        if (!approvePaths.includes(location.pathname)) {
          return ( <Redirect to={'/login'}/> );
        }
      } else if (initURL === '' || initURL === '/' || initURL === '/login') {
        return ( <Redirect to={'/notifications'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    return (
      <Switch>
        <Route exact path="/health-declaration" name="Khai báo y tế" render={props => <Iframe {...props} />} />
        <Route exact path="/login" name="Đăng nhập" render={props => <Login {...props} />} />
        {/* <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} /> */}
        <RestrictedRoute path={`${match.url}`} authUser={authUser} component={DefaultLayout} />
      </Switch>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser, initURL} = auth;
  return {authUser, initURL}
};
export default connect(mapStateToProps, {setInitUrl})(App);
