import {
  FORMPROCESSINGS_SELECT_ALL,
  FORMPROCESSINGS_UNSELECT_ALL,
  FORMPROCESSING_SELECTED,
  FORMPROCESSINGS_LOAD,
  FORMPROCESSINGS_LOAD_SUCCESS,
  FORMPROCESSINGS_LOAD_FAILED,
  FORMPROCESSING_LOAD,
  FORMPROCESSING_LOAD_SUCCESS,
  FORMPROCESSING_LOAD_FAILED,
  FORMPROCESSING_ADD,
  FORMPROCESSING_ADD_SUCCESS,
  FORMPROCESSING_ADD_FAILED,
  FORMPROCESSING_UPDATE,
  FORMPROCESSING_UPDATE_SUCCESS,
  FORMPROCESSING_UPDATE_FAILED,
  FORMPROCESSING_DELETE,
  FORMPROCESSING_DELETE_SUCCESS,
  FORMPROCESSING_DELETE_FAILED,
  FORMPROCESSING_DELETE_ALL,
  FORMPROCESSING_DELETE_ALL_SUCCESS,
  FORMPROCESSING_DELETE_ALL_FAILED,
  FORMPROCESSINGS_EXPORT_EXCEL,
  FORMPROCESSINGS_EXPORT_EXCEL_SUCCESS,
  FORMPROCESSINGS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllFormProcessings = () => {
  return {
    type: FORMPROCESSINGS_SELECT_ALL
  }
}

export const unselectAllFormProcessings = () => {
  return {
    type: FORMPROCESSINGS_UNSELECT_ALL
  }
}

export const selectFormProcessing = data => {
  return {
    type: FORMPROCESSING_SELECTED,
    payload: data
  }
}

export const loadFormProcessings = (data) => {
  return {
    type: FORMPROCESSINGS_LOAD,
    payload: data
  };
}

export const loadFormProcessingsSuccess = (data) => {
  return {
    type: FORMPROCESSINGS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadFormProcessingsFailed = () => {
  return {
    type: FORMPROCESSINGS_LOAD_FAILED
  };
}

export const loadFormProcessing = () => {
  return {
    type: FORMPROCESSING_LOAD
  };
}

export const loadFormProcessingSuccess = (data) => {
  return {
    type: FORMPROCESSING_LOAD_SUCCESS,
    payload: data
  };
}


export const loadFormProcessingFailed = () => {
  return {
    type: FORMPROCESSING_LOAD_FAILED
  };
}


export const addFormProcessing = (data) => {
  return {
    type: FORMPROCESSING_ADD,
    payload: data
  };
}


export const addFormProcessingSuccess = (filter) => {
  return {
    type: FORMPROCESSING_ADD_SUCCESS,
    message: 'FormProcessing added successfully.',
    payload: filter
  };
}

export const addFormProcessingFailed = () => {
  return {
    type: FORMPROCESSING_ADD_FAILED
  };
}

export const updateFormProcessing = (data) => {
  return {
    type: FORMPROCESSING_UPDATE,
    payload: data
  };
}


export const updateFormProcessingSuccess = (data) => {
  return {
    type: FORMPROCESSING_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateFormProcessingFailed = (data) => {
  return {
    type: FORMPROCESSING_UPDATE_FAILED,
    payload: data
  };
}

export const deleteFormProcessing = (data) => {
  return {
    type: FORMPROCESSING_DELETE,
    payload: data
  };
}

export const deleteFormProcessingSuccess = () => {
  return {
    type: FORMPROCESSING_DELETE_SUCCESS,
    message: 'FormProcessing deleted successfully.'
  };
}

export const deleteFormProcessingFailed = () => {
  return {
    type: FORMPROCESSING_DELETE_FAILED
  };
}

export const deleteAllFormProcessings = (data) => {
  return {
    type: FORMPROCESSING_DELETE_ALL,
    payload: data
  };
}

export const deleteAllFormProcessingsSuccess = () => {
  return {
    type: FORMPROCESSING_DELETE_ALL_SUCCESS,
    message: 'FormProcessing all deleted successfully.'
  };
}

export const deleteAllFormProcessingsFailed = () => {
  return {
    type: FORMPROCESSING_DELETE_ALL_FAILED
  };
}

export const exportExcelFormProcessings = (filter) => {
  return {
    type: FORMPROCESSINGS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelFormProcessingsSuccess = (data) => {
  return {
    type: FORMPROCESSINGS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelFormProcessingsFailed = () => {
  return {
    type: FORMPROCESSINGS_EXPORT_EXCEL_FAILED
  };
}
