import React from 'react';
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import App from "./App";
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import configureStore, { history } from "./store/store";
import Loading from './components/Loading';

export const store = configureStore();
const loading = () => <Loading />;

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <Router>
            <React.Suspense fallback={loading()}>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </React.Suspense>
          </Router>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
