import {
  SALARYHISTORIES_SELECT_ALL,
  SALARYHISTORIES_UNSELECT_ALL,
  SALARYHISTORY_SELECTED,
  SALARYHISTORIES_LOAD,
  SALARYHISTORIES_LOAD_SUCCESS,
  SALARYHISTORIES_LOAD_FAILED,
  SALARYHISTORY_LOAD,
  SALARYHISTORY_LOAD_SUCCESS,
  SALARYHISTORY_LOAD_FAILED,
  SALARYHISTORY_ADD,
  SALARYHISTORY_ADD_SUCCESS,
  SALARYHISTORY_ADD_FAILED,
  SALARYHISTORY_UPDATE,
  SALARYHISTORY_UPDATE_SUCCESS,
  SALARYHISTORY_UPDATE_FAILED,
  SALARYHISTORY_DELETE,
  SALARYHISTORY_DELETE_SUCCESS,
  SALARYHISTORY_DELETE_FAILED,
  SALARYHISTORY_DELETE_ALL,
  SALARYHISTORY_DELETE_ALL_SUCCESS,
  SALARYHISTORY_DELETE_ALL_FAILED,
  SALARYHISTORIES_EXPORT_EXCEL,
  SALARYHISTORIES_EXPORT_EXCEL_SUCCESS,
  SALARYHISTORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSalaryHistory: null,
  selectedSalaryHistoryId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SALARYHISTORIES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SALARYHISTORIES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SALARYHISTORY_SELECTED:
      return {
        ...state,
        selectedSalaryHistory: action.payload,
        selectedSalaryHistoryId: action.payload.id
      };
    case SALARYHISTORIES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SALARYHISTORIES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SALARYHISTORIES_LOAD_FAILED:
      return {
        ...state
      };
    case SALARYHISTORY_LOAD:
      return {
        ...state
      };
    case SALARYHISTORY_LOAD_SUCCESS:
      return {
        ...state
      };
    case SALARYHISTORY_LOAD_FAILED:
      return {
        ...state
      };
    case SALARYHISTORY_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SALARYHISTORY_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SALARYHISTORY_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SALARYHISTORY_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SALARYHISTORY_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SALARYHISTORY_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SALARYHISTORY_DELETE:
      return {
        ...state
      };
    case SALARYHISTORY_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SALARYHISTORY_DELETE_FAILED:
      return {
        ...state
      };
    case SALARYHISTORY_DELETE_ALL:
      return {
        ...state
      };
    case SALARYHISTORY_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SALARYHISTORY_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case SALARYHISTORIES_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case SALARYHISTORIES_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case SALARYHISTORIES_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    default:
      return state;
  }
}
