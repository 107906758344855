export const MOMENT_DATE_FORMAT = "DD/MM/YYYY";
export const FULL_MOMENT_DATE_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_TIME_FORMAT = "dd/MM/yyyy h:mm aa";
export const SQL_DATE_FORMAT = "YYYY/MM/DD";
export const SHORT_DATE_TIME = "MM/YYYY"
export const FULL_SQL_DATE_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const HASH_CODE_FORMAT = "YYYYMMDDHHmmss";
export const FILE_NAME_HASH = 'YYYYMMDDHHmmsszzz';
export const LANGUAGE_CODES = [{ code: 'en', name: 'English' }, { code: 'vi', name: 'Việt Nam' }];
export const DEFAULT_LANGUAGE = "vi";
export const MAX_CODE = 1000000;
export const GET_ALL_SIZE = 100000;
export const GET_MAXIMUM_SIZE = 1000000;
export const PAGE_SIZES = 30;
export const PAGING_PADDING = 10;

export const MAX_CHUNK_SIZE = (5 * 1024 * 1024); // Max size when upload big file (5 MB)
export const MEDIA_STATUS = {
    INITILAZED: 0,
    UPLOADING: 1,
    PROCESSING: 2,
    READY: 4,
    URLREADY: 8
}

export const DATA_TYPE = {
    ACTION: 'action',
    SELECT: 'select',
    CHECKBOX: 'checkbox',
    DATE_TIME: 'datetime',
    HEADER: 'header',
    FOOTER: 'footer',
    SIGNATURE: 'signature',
    TEXT: 'text',
    TEXTAREA: 'textarea',
    LABEL: 'label',
    EDITOR: 'editor',
    MAIL: 'mail',
    PHONE: 'phone',
    SUGGESTION: 'suggestion',
    UPLOAD: 'upload',
    UPLOAD_ALBUM: 'upload_album',
    NUMBER: 'number',
    TAGINPUTS: 'taginputs',
    TABLE: 'table',
    CUSTOM: 'custom',
    COL2: 'col2',
    TEXT_DISABLE: 'text_disable',
}

export const ACTION_STATUSES = {
    NORMAL: 0,
    DELETED: 1,
    INSERTED: 2,
    UPDATED: 3
}

export const NOTIFY_STATUS = {
    NEW: 0,
    VIEW: 1,
    DONE: 2
}

export const SUPPLY_FORM_STATUS = {
    INPROGRESS: 0,
    DONE: 1,
    CANCEL: 2,
}

export const VOUCHER_STATUS = {
    INPROGRESS: 0,
    DONE: 1,
    CANCEL: 2,
}

export const QUOTATION_STATUS = {
    INPROGRESS: 0,
    DONE: 1,
    CANCEL: 2,
}

export const APPROVAL_STATUS = {
    NEW: 1,
    DONE: 2,
    CANCEL: 3,
    PENDING: 4
}

export const SALARY_CONSTANT = {
    CKPCD: 0,
    CBHXH: 1,
    CBHYT: 2,
    CBHTN: 3,
    EKPCD: 4,
    EBHXH: 5,
    EBHYT: 6,
    EBHTN: 7,
}

export const FORM_PROCESSING_TYPE = {
    BDHQ: 0,
    HR: 1,
    LD1: 2,
    LKDT: 3,
    AQCT: 4,
    SRTP: 5,
    TCDN: 6,
    TCDM: 7,
    TRPL: 8,
    XLNT: 9,
    LD2: 10,
    SC: 11,
    CG: 12,
}

export const FORM_RECEIVE_TYPE = {
    CN: 0,
    NH: 1,
    NB: 2,
    PL: 3,
}

export const FORM_RECEIVE_REVIEW_STATUS = {
    NEW: 0,
    DONE: 1,
    RENEW: 2,
    CANCEL: 3,
    INPROGRESS: 4,
}

export const FORM_RECEIVE_PAYSTATUS = {
    NEW: 0,
    PAID: 1,
    WAIT: 2,
}

export const DOCUMENT_STATUS = {
    NEW: 0,
    INPROGRESS: 1,
    DONE: 2,
    RENEW: 3,
    CANCEL: 4,
}

export const SUPPLY_FORM_TYPE = {
    IMPORT: 0,
    EXPORT: 1
}

export const LISTABLE_SETTING_STATUS = {
    INPROGRESS: 0,
    CANCEL: 1
}

export const LISTABLE_STATUS = {
    NEW: 0,
    INPROGRESS: 1,
    PAID: 2,
    CANCEL: 3
}

export const LEDGER_TYPES = {
    RECEIPT: 1,
    PAYMENT: 2
}

export const ACTIONS = {
    GET: 'GET',
    LIST: 'LIST',
    MY_LIST: 'MY_LIST',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DUPLICATE: 'DUPLICATE',
    DELETE: 'DELETE',
    COUNT: 'COUNT',
    EXPORT_EXCEL: 'EXPORT_EXCEL',
    REPORT: 'REPORT',
    SELECT: 'SELECT',
    CHANGEDATE: 'CHANGEDATE',
};

export const ACTIONS_TITLE = {
    GET: 'Xem chi tiết',
    LIST: 'Xem danh sách',
    MY_LIST: 'Xem danh sách của tôi',
    CREATE: 'Tạo mới',
    UPDATE: 'Chỉnh sửa',
    DUPLICATE: 'Nhân bản',
    DELETE: 'Xóa',
    COUNT: 'Số lượng',
    EXPORT_EXCEL: 'Xuất excel',
    REPORT: 'Xem báo cáo',
    SELECT: 'Chọn item',
    CHANGEDATE: 'Thêm ngày',
    CREATE_FILE: 'Tạo file',
};

export const MODULES = {
    NOTIFICATION: 'NOTIFICATION',
    REPORT: 'REPORT',
    REPORT_FORM_RECEIVE: 'REPORT_FORM_RECEIVE',
    REPORT_FORM_RECEIVE_AQCT: 'REPORT_FORM_RECEIVE_AQCT',
    REPORT_FORM_RECEIVE_BDHQ: 'REPORT_FORM_RECEIVE_BDHQ',
    REPORT_FORM_RECEIVE_CG: 'REPORT_FORM_RECEIVE_CG',
    REPORT_FORM_RECEIVE_HR: 'REPORT_FORM_RECEIVE_HR',
    REPORT_FORM_RECEIVE_LDA: 'REPORT_FORM_RECEIVE_LDA',
    REPORT_FORM_RECEIVE_LD: 'REPORT_FORM_RECEIVE_LD',
    REPORT_FORM_RECEIVE_LKDT: 'REPORT_FORM_RECEIVE_LKDT',
    REPORT_FORM_RECEIVE_PL: 'REPORT_FORM_RECEIVE_PL',
    REPORT_FORM_RECEIVE_SC: 'REPORT_FORM_RECEIVE_SC',
    REPORT_FORM_RECEIVE_SRTP: 'REPORT_FORM_RECEIVE_SRTP',
    REPORT_FORM_RECEIVE_TCDM: 'REPORT_FORM_RECEIVE_TCDM',
    REPORT_FORM_RECEIVE_TCDN: 'REPORT_FORM_RECEIVE_TCDN',
    REPORT_FORM_RECEIVE_TRPL: 'REPORT_FORM_RECEIVE_TRPL',
    REPORT_FORM_RECEIVE_XLNT: 'REPORT_FORM_RECEIVE_XLNT',
    APPROVAL: 'APPROVAL',
    USER: 'USER',
    ROLE: 'ROLE',
    SETTING: 'SETTING',
    COMPANY: 'COMPANY',
    DEPARTMENT: 'DEPARTMENT',
    WORK_LOCATION: 'WORK_LOCATION',
    EMPLOYEE: 'EMPLOYEE',
    CUSTOMER: 'CUSTOMER',
    QUOTATION: 'QUOTATION',
    CONTRACT: 'CONTRACT',
    SUPPLY: 'SUPPLY',
    SUPPLY_EXPORT_VOUCHER: 'SUPPLY_EXPORT_VOUCHER',
    SUPPLY_IMPORT_VOUCHER: 'SUPPLY_IMPORT_VOUCHER',
    WASTE: 'WASTE',
    LISTABLE: 'LISTABLE',
    LISTABLE_SETTING: 'LISTABLE_SETTING',
    HEALTH_DECLARATION: 'HEALTH_DECLARATION',
    FORM_RECEIVE: 'FORM_RECEIVE',
    FORM_RECEIVE_CN: 'FORM_RECEIVE_CN',
    FORM_RECEIVE_NB: 'FORM_RECEIVE_NB',
    FORM_RECEIVE_PL: 'FORM_RECEIVE_PL',
    FORM_RECEIVE_REVIEW: 'FORM_RECEIVE_REVIEW',
    FORM_PROCESSING: 'FORM_PROCESSING',
    FORM_PROCESSING_AQCT: 'FORM_PROCESSING_AQCT',
    FORM_PROCESSING_BDHQ: 'FORM_PROCESSING_BDHQ',
    FORM_PROCESSING_TRANSFER: 'FORM_PROCESSING_TRANSFER',
    FORM_PROCESSING_HR: 'FORM_PROCESSING_HR',
    FORM_PROCESSING_LD: 'FORM_PROCESSING_LD',
    FORM_PROCESSING_LDA: 'FORM_PROCESSING_LDA',
    FORM_PROCESSING_LKDT: 'FORM_PROCESSING_LKDT',
    FORM_PROCESSING_SC: 'FORM_PROCESSING_SC',
    FORM_PROCESSING_SRTP: 'FORM_PROCESSING_SRTP',
    FORM_PROCESSING_TCDM: 'FORM_PROCESSING_TCDM',
    FORM_PROCESSING_TCDN: 'FORM_PROCESSING_TCDN',
    FORM_PROCESSING_TRPL: 'FORM_PROCESSING_TRPL',
    FORM_PROCESSING_XLNT: 'FORM_PROCESSING_XLNT',
    FORM_PROCESSING_CHEM: 'FORM_PROCESSING_CHEM',
    FORM_RECEIVE_QUOTATION_WASTES: 'FORM_RECEIVE_QUOTATION_WASTES',
    FORM_PROCESSING_FORM_RECEIVE_QUOTATION_WASTES: 'FORM_PROCESSING_FORM_RECEIVE_QUOTATION_WASTES',
    PARTNER: 'PARTNER',
    QUOTATION_WASTE: 'QUOTATION_WASTE',
    DOCUMENT: 'DOCUMENT',
    DOCUMENT_CATEGORY: 'DOCUMENT_CATEGORY',
    TIME_KEEP: 'TIME_KEEP',
};

export const MODULES_TITLE = {
    NOTIFICATION: 'Thông báo',
    REPORT: 'Tổng hợp',
    REPORT_FORM_RECEIVE: 'Tổng hợp thu gom',
    REPORT_FORM_RECEIVE_AQCT: 'Tổng hợp AQCT',
    REPORT_FORM_RECEIVE_BDHQ: 'Tổng hợp BDHQ',
    REPORT_FORM_RECEIVE_CG: 'Tổng hợp CG',
    REPORT_FORM_RECEIVE_HR: 'Tổng hợp HR',
    REPORT_FORM_RECEIVE_LDA: 'Tổng hợp LDA',
    REPORT_FORM_RECEIVE_LD: 'Tổng hợp LD',
    REPORT_FORM_RECEIVE_LKDT: 'Tổng hợp LKDT',
    REPORT_FORM_RECEIVE_PL: 'Tổng hợp PL',
    REPORT_FORM_RECEIVE_SC: 'Tổng hợp SC',
    REPORT_FORM_RECEIVE_SRTP: 'Tổng hợp SRTP',
    REPORT_FORM_RECEIVE_TCDM: 'Tổng hợp TCDM',
    REPORT_FORM_RECEIVE_TCDN: 'Tổng hợp TCDN',
    REPORT_FORM_RECEIVE_TRPL: 'Tổng hợp TRPL',
    REPORT_FORM_RECEIVE_XLNT: 'Tổng hợp XLNT',
    APPROVAL: 'Hệ thống ký duyệt',
    USER: 'Người dùng',
    ROLE: 'Phân quyền',
    SETTING: 'Cài đặt dữ liệu',
    COMPANY: 'Quản lý công ty',
    DEPARTMENT: 'Quản lý phòng ban',
    WORK_LOCATION: 'Thiết lập vị trí làm việc',
    EMPLOYEE: 'Quản lý nhân sự',
    CUSTOMER: 'Quản lý khách hàng',
    QUOTATION: 'Quản lý báo giá',
    CONTRACT: 'Quản lý hợp đồng',
    SUPPLY: 'Quản lý kho vật tư',
    SUPPLY_EXPORT_VOUCHER: 'Quản lý xuất kho vật tư',
    SUPPLY_IMPORT_VOUCHER: 'Quản lý  nhập kho vật tư',
    WASTE: 'Quản lý danh mục rác',
    LISTABLE: 'Bảng kê thu gom rác',
    LISTABLE_SETTING: 'Thiết lập bảng kê',
    HEALTH_DECLARATION: 'Khai báo y tế',
    FORM_RECEIVE: 'BB thu gom CTNH',
    FORM_RECEIVE_CN: 'BB thu gom CTCN',
    FORM_RECEIVE_NB: 'BB thu gom CTNB',
    FORM_RECEIVE_PL: 'BB thu mua PL',
    FORM_RECEIVE_REVIEW: 'Kiểm tra biên bản thu gom',
    FORM_PROCESSING: 'BB xử lý',
    FORM_PROCESSING_AQCT: 'NKVH Ắc quy chì',
    FORM_PROCESSING_BDHQ: 'NKVH Bóng đèn Huỳnh Quang',
    FORM_PROCESSING_TRANSFER: 'NKVH Chuyển giao',
    FORM_PROCESSING_HR: 'NKVH Hóa rắn',
    FORM_PROCESSING_LD: 'NKVH Lò đốt 1',
    FORM_PROCESSING_LDA: 'NKVH Lò đốt 2',
    FORM_PROCESSING_LKDT: 'NKVH Linh kiện điện tử',
    FORM_PROCESSING_SC: 'NKVH Sơ chế',
    FORM_PROCESSING_SRTP: 'NKVH Súc rửa thùng phuy',
    FORM_PROCESSING_TCDM: 'NKVH Tái chế dung môi',
    FORM_PROCESSING_TCDN: 'NKVH Tái chế dầu nhớt',
    FORM_PROCESSING_TRPL: 'NKVH Tẩy rửa phế liệu',
    FORM_PROCESSING_XLNT: 'NKVH Xử lý nước thải',
    FORM_PROCESSING_CHEM: 'Tổng hợp hóa chất',
    FORM_RECEIVE_QUOTATION_WASTES: 'Chất thải trên biên bản thu gom',
    FORM_PROCESSING_FORM_RECEIVE_QUOTATION_WASTES: 'Chất thải trên biên bản xử lý',
    PARTNER: 'Cộng sự',
    QUOTATION_WASTE: 'Chất thải thuộc Báo giá',
    DOCUMENT: 'Biên bản hành chính',
    DOCUMENT_CATEGORY: 'Thư mục biên bản hành chính',
    TIME_KEEP: 'Chấm công',
};

export const ACTION_BUTTON_TYPES = {
    SITE_MAP: {
        type: 'SITE_MAP',
        icon: 'fa fa-sitemap',
        color: 'success',
        tooltip: 'dark',
        text: 'Cây tổ chức'
    },
    LIST_VIEW: {
        type: 'LIST_VIEW',
        icon: 'icon-list',
        color: 'success',
        tooltip: 'dark',
        text: 'Xem danh sách',
        claim: ACTIONS.LIST
    },
    VIEW: {
        type: 'VIEW',
        icon: 'icon-eye',
        color: 'default',
        tooltip: 'dark',
        text: 'Xem',
        claim: ACTIONS.GET
    },
    ADD: {
        type: 'ADD',
        icon: 'icon-plus',
        color: 'success',
        tooltip: 'success',
        text: 'Thêm',
        claim: ACTIONS.CREATE
    },
    SAVE: {
        type: 'SAVE',
        icon: 'icon-cloud-download',
        color: 'success',
        tooltip: 'success',
        text: 'Lưu'
    },
    EDIT: {
        type: 'EDIT',
        icon: 'fa fa-edit',
        color: 'primary',
        tooltip: 'info',
        text: 'Sửa',
        claim: ACTIONS.UPDATE
    },
    DUPLICATE: {
        type: 'DUPLICATE',
        icon: 'far fa-clone',
        color: 'warning',
        tooltip: 'info',
        text: 'Nhân bản',
        claim: ACTIONS.DUPLICATE
    },
    REFRESH: {
        type: 'REFRESH',
        icon: 'icon-refresh',
        color: 'info',
        tooltip: 'info',
        text: 'Làm mới'
    },
    CHANGEDATE: {
        type: 'CHANGEDATE',
        icon: 'fa fa-calendar',
        color: 'info',
        tooltip: 'info',
        text: 'Cập nhật ngày',
        claim: ACTIONS.CHANGEDATE
    },
    LOCK: {
        type: 'LOCK',
        icon: 'icon-lock',
        color: 'danger',
        tooltip: 'error',
        text: 'Khóa'
    },
    UNLOCK: {
        type: 'UNLOCK',
        icon: 'icon-lock-open',
        color: 'success',
        tooltip: 'success',
        text: 'Mở khóa'
    },
    CHANGEROLE: {
        type: 'CHANGEROLE',
        icon: 'fa fa-shield',
        color: 'warning',
        tooltip: 'warning',
        text: 'Phân quyền'
    },
    SALARYPAY: {
        type: 'SALARYPAY',
        icon: 'fas fa-gift',
        color: 'warning',
        tooltip: 'warning',
        text: 'Tính lương',
        claim: ACTIONS.CREATE
    },
    ADVANCESALARY: {
        type: 'ADVANCESALARY',
        icon: 'fa fa-money',
        color: 'danger',
        tooltip: 'danger',
        text: 'Tạm ứng lương',
        claim: ACTIONS.CREATE
    },
    BACK: {
        type: 'BACK',
        icon: 'icon-action-undo',
        color: 'secondary',
        tooltip: 'dark',
        text: 'Quay lại'
    },
    DELETE: {
        type: 'DELETE',
        icon: 'icon-trash',
        color: 'danger',
        tooltip: 'error',
        text: 'Xóa',
        claim: ACTIONS.DELETE
    },
    SELECT: {
        type: 'SELECT',
        icon: 'fa fa-paper-plane',
        color: 'warning',
        tooltip: 'warning',
        text: 'Chuyển sang xử lý',
        claim: ACTIONS.SELECT
    },
    FORM_RECEIVE: {
        type: 'FORM_RECEIVE',
        icon: 'far fa-file-alt',
        color: 'success',
        tooltip: 'dark',
        text: 'Thêm biên bản thu gom rác',
        module: MODULES.FORM_RECEIVE,
        claim: ACTIONS.CREATE
    },
    FORM_RECEIVE_NB: {
        type: 'FORM_RECEIVE',
        icon: 'far fa-file-alt',
        color: 'success',
        tooltip: 'dark',
        text: 'Thêm biên bản thu gom rác',
        module: MODULES.FORM_RECEIVE_NB,
        claim: ACTIONS.CREATE
    },
    QUOTATION: {
        type: 'QUOTATION',
        icon: 'icon-plus',
        color: 'success',
        tooltip: 'dark',
        text: 'Thêm báo giá',
        module: MODULES.QUOTATION,
        claim: ACTIONS.CREATE
    },
    CONTRACT: {
        type: 'CONTRACT',
        icon: 'icon-plus',
        color: 'success',
        tooltip: 'dark',
        text: 'Thêm hợp đồng',
        module: MODULES.CONTRACT,
        claim: ACTIONS.CREATE
    },
    SUBCONTRACT: {
        type: 'SUB_CONTRACT',
        icon: 'icon-list',
        color: 'success',
        tooltip: 'dark',
        text: 'Phụ lục hợp đồng',
        claim: ACTIONS.LIST
    },
    PAYMENT: {
        type: 'PAYMENT',
        icon: 'icon-plus',
        color: 'success',
        tooltip: 'dark',
        text: 'Thêm nộp phí',
        module: MODULES.PAYMENT,
        claim: ACTIONS.CREATE
    },
    SEND: {
        type: 'SEND',
        icon: 'fa fa-rocket',
        color: 'primary',
        tooltip: 'dark',
        text: 'Gửi đi'
    },
    SEARCH: {
        type: 'SEARCH',
        icon: 'fa fa-find',
        color: 'danger',
        tooltip: 'dark',
        text: 'Tìm kiếm'
    },
    CANCEL: {
        type: 'CANCEL',
        icon: 'fa fa-close',
        color: 'danger',
        tooltip: 'dark',
        text: 'Hủy'
    },
    PRINT: {
        type: 'PRINT',
        icon: 'fas fa-print',
        color: 'default',
        tooltip: 'dark',
        text: 'Duyệt'
    },
    LISTABLE: {
        type: 'LISTABLE',
        icon: 'icon-plus',
        color: 'danger',
        tooltip: 'dark',
        text: 'Thiết lập bảng kê',
        module: MODULES.LISTABLE,
        claim: ACTIONS.CREATE
    },
    APPROVAL: {
        type: 'APPROVAL',
        icon: 'fas fa-file-signature',
        color: 'success',
        tooltip: 'dark',
        text: 'Duyệt'
    },
    APPROVAL_END: {
        type: 'APPROVAL',
        icon: 'fas fa-file-signature',
        color: 'primary',
        tooltip: 'dark',
        text: 'Duyệt kết thúc'
    },
    CREATE_FILE: {
        type: 'FILE',
        icon: 'fa fa-file-text-o',
        color: 'dark',
        tooltip: 'dark',
        text: 'Tạo biên bản'
    }
}