import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadProductImportVouchers as loadProductImportVouchersAction,
  loadProductImportVouchersSuccess,
  addProductImportVoucherSuccess,
  updateProductImportVoucherSuccess,
  exportExcelProductImportVouchersSuccess
} from '../actions/ProductImportVoucher';

import {
  PRODUCTIMPORTVOUCHERS_LOAD,
  PRODUCTIMPORTVOUCHER_ADD,
  PRODUCTIMPORTVOUCHER_UPDATE,
  PRODUCTIMPORTVOUCHER_DELETE,
  PRODUCTIMPORTVOUCHER_DELETE_ALL,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getProductImportVoucherById = (id, callback) => {
  Api().get('product-import-voucher/' + id).then(response => {
    callback(response.data);
  })
}

export const changeProductImportVoucherStatus = (model, callback) => {
  Api().post('product-import-voucher/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllProductImportVouchers = (callback) => {
  Api().post('product-import-voucher/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxProductImportVoucherCodeSorted = (callback) => {
  Api().post('product-import-voucher/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadProductImportVouchersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`product-import-voucher/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`product-import-voucher`, { params })
    .then(data => data)
    .catch(error => error);
}


const addProductImportVoucherRequest = async (payload) =>
  await Api().post(`product-import-voucher`, payload)
    .then(data => data)
    .catch(error => error);

const updateProductImportVoucherRequest = async (payload) =>
  await Api().patch(`product-import-voucher/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteProductImportVoucherRequest = async (id) =>
  await Api().delete(`product-import-voucher/${id}`)
    .then(data => data)
    .catch(error => error);

const exportProductImportVouchersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`product-import-voucher/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadProductImportVouchers({ payload }) {
  try {

    const data = yield call(loadProductImportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductImportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProductImportVouchers() {
  yield takeEvery(PRODUCTIMPORTVOUCHERS_LOAD, loadProductImportVouchers);
}

function* loadProductImportVoucher({ selectedProductImportVoucherId }) {
  try {

    const data = yield call(loadProductImportVouchersRequest, selectedProductImportVoucherId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductImportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProductImportVoucher() {
  yield takeEvery(PRODUCTIMPORTVOUCHERS_LOAD, loadProductImportVoucher);
}


function* addProductImportVoucher({ payload }) {
  try {
    const data = yield call(addProductImportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addProductImportVoucherSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddProductImportVoucher() {
  yield takeEvery(PRODUCTIMPORTVOUCHER_ADD, addProductImportVoucher);
}


function* updateProductImportVoucher({ payload }) {
  try {
    const data = yield call(updateProductImportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateProductImportVoucherSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateProductImportVoucher() {
  yield takeEvery(PRODUCTIMPORTVOUCHER_UPDATE, updateProductImportVoucher);
}

function* deleteProductImportVoucher({ payload }) {
  try {
    const data = yield call(deleteProductImportVoucherRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductImportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllProductImportVouchers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteProductImportVoucherRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadProductImportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteProductImportVoucher() {
  yield takeEvery(PRODUCTIMPORTVOUCHER_DELETE, deleteProductImportVoucher);
}

function* processDeleteAllProductImportVouchers() {
  yield takeEvery(PRODUCTIMPORTVOUCHER_DELETE_ALL, deleteAllProductImportVouchers);
}

function* exportProductImportVouchers({ payload }) {
  try {
    const data = yield call(exportProductImportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelProductImportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportProductImportVouchers() {
  yield takeEvery(PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL, exportProductImportVouchers);
}

export default function* ProductImportVoucherSagas() {
  yield all([fork(processLoadProductImportVouchers),
  fork(processLoadProductImportVoucher),
  fork(processAddProductImportVoucher),
  fork(processUpdateProductImportVoucher),
  fork(processDeleteAllProductImportVouchers),
  fork(processDeleteProductImportVoucher),
  fork(processExportProductImportVouchers)]);
}
