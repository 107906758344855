import {
  LEDGERS_SELECT_ALL,
  LEDGERS_UNSELECT_ALL,
  LEDGER_SELECTED,
  LEDGERS_LOAD,
  LEDGERS_LOAD_SUCCESS,
  LEDGERS_LOAD_FAILED,
  LEDGER_LOAD,
  LEDGER_LOAD_SUCCESS,
  LEDGER_LOAD_FAILED,
  LEDGER_ADD,
  LEDGER_ADD_SUCCESS,
  LEDGER_ADD_FAILED,
  LEDGER_UPDATE,
  LEDGER_UPDATE_SUCCESS,
  LEDGER_UPDATE_FAILED,
  LEDGER_DELETE,
  LEDGER_DELETE_SUCCESS,
  LEDGER_DELETE_FAILED,
  LEDGER_DELETE_ALL,
  LEDGER_DELETE_ALL_SUCCESS,
  LEDGER_DELETE_ALL_FAILED,
  LEDGERS_EXPORT_EXCEL,
  LEDGERS_EXPORT_EXCEL_SUCCESS,
  LEDGERS_EXPORT_EXCEL_FAILED,
  LEDGERS_REPORT,
  LEDGERS_REPORT_SUCCESS,
  LEDGERS_REPORT_FAILED,
  LEDGERS_REPORTSUM,
  LEDGERS_REPORTSUM_SUCCESS,
  LEDGERS_REPORTSUM_FAILED
} from '../constants/ActionTypes';

export const selectAllLedgers = () => {
  return {
    type: LEDGERS_SELECT_ALL
  }
}

export const unselectAllLedgers = () => {
  return {
    type: LEDGERS_UNSELECT_ALL
  }
}

export const selectLedger = data => {
  return {
    type: LEDGER_SELECTED,
    payload: data
  }
}

export const loadLedgers = (data) => {
  return {
    type: LEDGERS_LOAD,
    payload: data
  };
}

export const loadLedgersSuccess = (data) => {
  return {
    type: LEDGERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadLedgersFailed = () => {
  return {
    type: LEDGERS_LOAD_FAILED
  };
}

export const loadLedger = () => {
  return {
    type: LEDGER_LOAD
  };
}

export const loadLedgerSuccess = (data) => {
  return {
    type: LEDGER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadLedgerFailed = () => {
  return {
    type: LEDGER_LOAD_FAILED
  };
}


export const addLedger = (data) => {
  return {
    type: LEDGER_ADD,
    payload: data
  };
}


export const addLedgerSuccess = (filter) => {
  return {
    type: LEDGER_ADD_SUCCESS,
    message: 'Ledger added successfully.',
    payload: filter
  };
}

export const addLedgerFailed = () => {
  return {
    type: LEDGER_ADD_FAILED
  };
}

export const updateLedger = (data) => {
  return {
    type: LEDGER_UPDATE,
    payload: data
  };
}


export const updateLedgerSuccess = (data) => {
  return {
    type: LEDGER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateLedgerFailed = (data) => {
  return {
    type: LEDGER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteLedger = (data) => {
  return {
    type: LEDGER_DELETE,
    payload: data
  };
}

export const deleteLedgerSuccess = () => {
  return {
    type: LEDGER_DELETE_SUCCESS,
    message: 'Ledger deleted successfully.'
  };
}

export const deleteLedgerFailed = () => {
  return {
    type: LEDGER_DELETE_FAILED
  };
}

export const deleteAllLedgers = (data) => {
  return {
    type: LEDGER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllLedgersSuccess = () => {
  return {
    type: LEDGER_DELETE_ALL_SUCCESS,
    message: 'Ledger all deleted successfully.'
  };
}

export const deleteAllLedgersFailed = () => {
  return {
    type: LEDGER_DELETE_ALL_FAILED
  };
}

export const exportExcelLedgers = (filter) => {
  return {
    type:LEDGERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelLedgersSuccess = (data) => {
  return {
    type:LEDGERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelLedgersFailed = () => {
  return {
    type:LEDGERS_EXPORT_EXCEL_FAILED
  };
}

export const reportLedgers = (filter) => {
  return {
    type: LEDGERS_REPORT,
    payload: filter
  }
}

export const reportLedgersSuccess = (data) => {
  return {
    type: LEDGERS_REPORT_SUCCESS,
    payload: data
  };
}

export const reportLedgersFailed = () => {
  return {
    type: LEDGERS_REPORT_FAILED
  };
}

export const reportSumLedgersSuccess = (data) => {
  return {
    type: LEDGERS_REPORTSUM_SUCCESS,
    payload: data
  };
}