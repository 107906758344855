import { all } from "redux-saga/effects";
import settingSagas from './Setting';
import companySagas from './Company';
import departmentSagas from './Department';
import employeeSagas from './Employee';
import personalPaperSagas from './PersonalPaper';
import paymentSagas from './Payment';
import contractSagas from './Contract';
import quotationSagas from './Quotation';
import workLocationSagas from './WorkLocation';
import customerSagas from './Customer';
import customerRegSagas from './CustomerReg';
import leaveTrackSagas from './LeaveTrack';
import leaveTrackHistorySagas from './LeaveTrackHistory';
import sampleSages from './Sample';
import authSaga from './Auth';
import userSagas from './User';
import roleSagas from './Role';
import jobSagas from './Job';
import mailSagas from './Mail';
import mailToUserSagas from './MailToUser';
import templateSagas from './Template';
import invoiceSagas from './Invoice';
import serialNumberSagas from './SerialNumber';
import albumSagas from './Album';
import mediaSagas from './Media';
import librarySagas from './Library';
import notificationSagas from './Notification';
import partnerSagas from './Partner';
import projectSagas from './Project';
import documentSagas from './Document';
import documentCategorySagas from './DocumentCategory';
import personalMailSagas from './PersonalMail';
import productSagas from './Product';
import supplySagas from './Supply';
import salarySettingSagas from './SalarySetting';
import salaryHistorySagas from './SalaryHistory';
import approvalSagas from './Approval';
import advanceSalarySagas from './AdvanceSalary';
import ledgerSagas from './Ledger';
import paymentVoucherSagas from './PaymentVoucher';
import receiptVoucherSagas from './ReceiptVoucher';
import productImportVoucherSagas from './ProductImportVoucher';
import productExportVoucherSagas from './ProductExportVoucher';
import supplyImportVoucherSagas from './SupplyImportVoucher';
import supplyExportVoucherSagas from './SupplyExportVoucher';
import wasteSagas from './Waste';
import quotationWasteSagas from './QuotationWaste';
import formReceiveQuotationWasteSagas from './FormReceiveQuotationWaste';
import formReceiveSagas from './FormReceive';
import listableSagas from './Listable';
import listableSettingSagas from './ListableSetting';
import healthDeclarationSagas from './HealthDeclaration';
import formProcessingSagas from './FormProcessing';
import formProcessingFormReceiveQuotationWasteSagas from './FormProcessingFormReceiveQuotationWaste';
import supplyVoucherItemSagas from './SupplyVoucherItem';
import supplyFormSagas from './SupplyForm';
import supplyFormItemSagas from './SupplyFormItem';
import timeKeepSagas from './TimeKeep';

export default function* rootSaga(getState) {
  yield all([
    settingSagas(),
    companySagas(),
    departmentSagas(),
    employeeSagas(),
    personalPaperSagas(),
    paymentSagas(),
    contractSagas(),
    quotationSagas(),
    workLocationSagas(),
    customerSagas(),
    customerRegSagas(),
    leaveTrackSagas(),
    leaveTrackHistorySagas(),
    sampleSages(),
    authSaga(),
    userSagas(),
    roleSagas(),
    jobSagas(),
    mailSagas(),
    mailToUserSagas(),
    templateSagas(),
    invoiceSagas(),
    serialNumberSagas(),
    albumSagas(),
    mediaSagas(),
    librarySagas(),
    notificationSagas(),
    partnerSagas(),
    projectSagas(),
    documentSagas(),
    documentCategorySagas(),
    personalMailSagas(),
    productSagas(),
    supplySagas(),
    salarySettingSagas(),
    salaryHistorySagas(),
    approvalSagas(),
    advanceSalarySagas(),
    ledgerSagas(),
    paymentVoucherSagas(),
    receiptVoucherSagas(),
    productImportVoucherSagas(),
    productExportVoucherSagas(),
    supplyImportVoucherSagas(),
    supplyExportVoucherSagas(),
    wasteSagas(),
    quotationWasteSagas(),
    formReceiveQuotationWasteSagas(),
    formReceiveSagas(),
    listableSagas(),
    listableSettingSagas(),
    healthDeclarationSagas(),
    formProcessingSagas(),
    formProcessingFormReceiveQuotationWasteSagas(),
    supplyVoucherItemSagas(),
    supplyFormSagas(),
    supplyFormItemSagas(),
   timeKeepSagas(),
  ]);
}
