import {
  SUPPLYIMPORTVOUCHERS_SELECT_ALL,
  SUPPLYIMPORTVOUCHERS_UNSELECT_ALL,
  SUPPLYIMPORTVOUCHER_SELECTED,
  SUPPLYIMPORTVOUCHERS_LOAD,
  SUPPLYIMPORTVOUCHERS_LOAD_SUCCESS,
  SUPPLYIMPORTVOUCHERS_LOAD_FAILED,
  SUPPLYIMPORTVOUCHER_LOAD,
  SUPPLYIMPORTVOUCHER_LOAD_SUCCESS,
  SUPPLYIMPORTVOUCHER_LOAD_FAILED,
  SUPPLYIMPORTVOUCHER_ADD,
  SUPPLYIMPORTVOUCHER_ADD_SUCCESS,
  SUPPLYIMPORTVOUCHER_ADD_FAILED,
  SUPPLYIMPORTVOUCHER_UPDATE,
  SUPPLYIMPORTVOUCHER_UPDATE_SUCCESS,
  SUPPLYIMPORTVOUCHER_UPDATE_FAILED,
  SUPPLYIMPORTVOUCHER_DELETE,
  SUPPLYIMPORTVOUCHER_DELETE_SUCCESS,
  SUPPLYIMPORTVOUCHER_DELETE_FAILED,
  SUPPLYIMPORTVOUCHER_DELETE_ALL,
  SUPPLYIMPORTVOUCHER_DELETE_ALL_SUCCESS,
  SUPPLYIMPORTVOUCHER_DELETE_ALL_FAILED,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSupplyImportVoucher: null,
  selectedSupplyImportVoucherId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLYIMPORTVOUCHERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SUPPLYIMPORTVOUCHERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SUPPLYIMPORTVOUCHER_SELECTED:
      return {
        ...state,
        selectedSupplyImportVoucher: action.payload,
        selectedSupplyImportVoucherId: action.payload.id
      };
    case SUPPLYIMPORTVOUCHERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SUPPLYIMPORTVOUCHERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SUPPLYIMPORTVOUCHERS_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYIMPORTVOUCHER_LOAD:
      return {
        ...state
      };
    case SUPPLYIMPORTVOUCHER_LOAD_SUCCESS:
      return {
        ...state
      };
    case SUPPLYIMPORTVOUCHER_LOAD_FAILED:
      return {
        ...state
      };
    case SUPPLYIMPORTVOUCHER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYIMPORTVOUCHER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedSupplyImportVoucher: action.payload
      };
    case SUPPLYIMPORTVOUCHER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYIMPORTVOUCHER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYIMPORTVOUCHER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYIMPORTVOUCHER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYIMPORTVOUCHER_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case SUPPLYIMPORTVOUCHER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case SUPPLYIMPORTVOUCHER_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SUPPLYIMPORTVOUCHER_DELETE_ALL:
      return {
        ...state
      };
    case SUPPLYIMPORTVOUCHER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SUPPLYIMPORTVOUCHER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
