import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadListables as loadListablesAction,
  loadListablesSuccess,
  addListableSuccess,
  updateListableSuccess,
  exportExcelListables,
  exportExcelListablesSuccess,
  exportExcelListablesFailed
} from '../actions/Listable';

import {
  LISTABLES_LOAD,
  LISTABLE_ADD,
  LISTABLE_UPDATE,
  LISTABLE_DELETE,
  LISTABLE_DELETE_ALL,
  LISTABLES_EXPORT_EXCEL,
  LISTABLES_EXPORT_EXCEL_SUCCESS,
  LISTABLES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getListableById = (id, callback) => {
  Api().get('listable/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllListables = (callback) => {
  Api().post('listable/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxListableCodeSorted = (callback, params) => {
  if (!params) {
    params = { 
      offset: 0,
      limit: 1,
      orderBy: "codeSorted",
      descending: true
    }
  }
  Api().post('listable/search', { 
    ...params,
    offset: 0,
    limit: 1
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadListablesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`listable/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`listable`, { params })
    .then(data => data)
    .catch(error => error);
}

const addListableRequest = async (payload) =>
  await Api().post(`listable`, payload)
    .then(data => data)
    .catch(error => error);

const updateListableRequest = async (payload) =>
  await Api().patch(`listable/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteListableRequest = async (id) =>
  await Api().delete(`listable/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadListables({ payload }) {
  try {
    const data = yield call(loadListablesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadListablesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadListables() {
  yield takeEvery(LISTABLES_LOAD, loadListables);
}

function* loadListable({ selectedListableId }) {
  try {
    const data = yield call(loadListablesRequest, selectedListableId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadListablesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadListable() {
  yield takeEvery(LISTABLES_LOAD, loadListable);
}


function* addListable({ payload }) {
  try {
    const data = yield call(addListableRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addListableSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddListable() {
  yield takeEvery(LISTABLE_ADD, addListable);
}


function* updateListable({ payload }) {
  try {
    const data = yield call(updateListableRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateListableSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateListable() {
  yield takeEvery(LISTABLE_UPDATE, updateListable);
}

function* deleteListable({ payload }) {
  try {
    const data = yield call(deleteListableRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadListablesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllListables({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteListableRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadListablesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteListable() {
  yield takeEvery(LISTABLE_DELETE, deleteListable);
}

function* processDeleteAllListables() {
  yield takeEvery(LISTABLE_DELETE_ALL, deleteAllListables);
}

const exportListablesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`listable/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportListables({ payload }) {
  try {
    const data = yield call(exportListablesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelListablesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportListables() {
  yield takeEvery(LISTABLES_EXPORT_EXCEL, exportListables);
}

export default function* ListableSagas() {
  yield all([fork(processLoadListables),
  fork(processLoadListable),
  fork(processAddListable),
  fork(processUpdateListable),
  fork(processDeleteAllListables),
  fork(processDeleteListable),
  fork(processExportListables)
]);
}
