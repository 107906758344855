import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTimeKeeps as loadTimeKeepsAction,
  loadTimeKeepsSuccess,
  addTimeKeepSuccess,
  updateTimeKeepSuccess,
  exportExcelTimeKeeps,
  exportExcelTimeKeepsSuccess,
  exportExcelTimeKeepsFailed
} from '../actions/TimeKeep';

import {
  TIMEKEEPS_LOAD,
  TIMEKEEP_ADD,
  TIMEKEEP_UPDATE,
  TIMEKEEP_DELETE,
  TIMEKEEP_DELETE_ALL,
  TIMEKEEPS_EXPORT_EXCEL,
  TIMEKEEPS_EXPORT_EXCEL_SUCCESS,
  TIMEKEEPS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTimeKeepById = (id, callback) => {
  Api().get('time-keep/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllTimeKeeps = (callback) => {
  Api().post('time-keep/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const getMaxTimeKeepCodeSorted = (processingType, callback) => {
  Api().post('time-keep/search', {
    offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'processingType',
        operator: '=',
        value: processingType
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadTimeKeepsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`time-keep/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`time-keep`, { params })
    .then(data => data)
    .catch(error => error);
}

const addTimeKeepRequest = async (payload) =>
  await Api().post(`time-keep`, payload)
    .then(data => data)
    .catch(error => error);

const updateTimeKeepRequest = async (payload) =>
  await Api().patch(`time-keep/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTimeKeepRequest = async (id) =>
  await Api().delete(`time-keep/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadTimeKeeps({ payload }) {
  try {
    const data = yield call(loadTimeKeepsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadTimeKeepsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTimeKeeps() {
  yield takeEvery(TIMEKEEPS_LOAD, loadTimeKeeps);
}

function* loadTimeKeep({ selectedTimeKeepId }) {
  try {
    const data = yield call(loadTimeKeepsRequest, selectedTimeKeepId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadTimeKeepsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTimeKeep() {
  yield takeEvery(TIMEKEEPS_LOAD, loadTimeKeep);
}


function* addTimeKeep({ payload }) {
  try {
    const data = yield call(addTimeKeepRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addTimeKeepSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTimeKeep() {
  yield takeEvery(TIMEKEEP_ADD, addTimeKeep);
}


function* updateTimeKeep({ payload }) {
  try {
    const data = yield call(updateTimeKeepRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateTimeKeepSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTimeKeep() {
  yield takeEvery(TIMEKEEP_UPDATE, updateTimeKeep);
}

function* deleteTimeKeep({ payload }) {
  try {
    const data = yield call(deleteTimeKeepRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadTimeKeepsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTimeKeeps({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTimeKeepRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTimeKeepsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTimeKeep() {
  yield takeEvery(TIMEKEEP_DELETE, deleteTimeKeep);
}

function* processDeleteAllTimeKeeps() {
  yield takeEvery(TIMEKEEP_DELETE_ALL, deleteAllTimeKeeps);
}

const exportTimeKeepsRequest = async (params) => {
  return await Api({ responseType: 'blob' }).post(`time-keep/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportTimeKeeps({ payload }) {
  try {
    const data = yield call(exportTimeKeepsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(exportExcelTimeKeepsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportTimeKeeps() {
  yield takeEvery(TIMEKEEPS_EXPORT_EXCEL, exportTimeKeeps);
}

export default function* TimeKeepSagas() {
  yield all([fork(processLoadTimeKeeps),
  fork(processLoadTimeKeep),
  fork(processAddTimeKeep),
  fork(processUpdateTimeKeep),
  fork(processDeleteAllTimeKeeps),
  fork(processDeleteTimeKeep),
  fork(processExportTimeKeeps)
  ]);
}
