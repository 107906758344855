import {
  HEALTHDECLARATIONS_SELECT_ALL,
  HEALTHDECLARATIONS_UNSELECT_ALL,
  HEALTHDECLARATION_SELECTED,
  HEALTHDECLARATIONS_LOAD,
  HEALTHDECLARATIONS_LOAD_SUCCESS,
  HEALTHDECLARATIONS_LOAD_FAILED,
  HEALTHDECLARATION_LOAD,
  HEALTHDECLARATION_LOAD_SUCCESS,
  HEALTHDECLARATION_LOAD_FAILED,
  HEALTHDECLARATION_ADD,
  HEALTHDECLARATION_ADD_SUCCESS,
  HEALTHDECLARATION_ADD_FAILED,
  HEALTHDECLARATION_UPDATE,
  HEALTHDECLARATION_UPDATE_SUCCESS,
  HEALTHDECLARATION_UPDATE_FAILED,
  HEALTHDECLARATION_DELETE,
  HEALTHDECLARATION_DELETE_SUCCESS,
  HEALTHDECLARATION_DELETE_FAILED,
  HEALTHDECLARATION_DELETE_ALL,
  HEALTHDECLARATION_DELETE_ALL_SUCCESS,
  HEALTHDECLARATION_DELETE_ALL_FAILED,
  HEALTHDECLARATIONS_EXPORT_EXCEL,
  HEALTHDECLARATIONS_EXPORT_EXCEL_SUCCESS,
  HEALTHDECLARATIONS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedHealthDeclaration: null,
  selectedHealthDeclarationId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case HEALTHDECLARATIONS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case HEALTHDECLARATIONS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case HEALTHDECLARATION_SELECTED:
      return {
        ...state,
        selectedHealthDeclaration: action.payload,
        selectedHealthDeclarationId: action.payload.id
      };
    case HEALTHDECLARATIONS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case HEALTHDECLARATIONS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case HEALTHDECLARATIONS_LOAD_FAILED:
      return {
        ...state
      };
    case HEALTHDECLARATION_LOAD:
      return {
        ...state
      };
    case HEALTHDECLARATION_LOAD_SUCCESS:
      return {
        ...state
      };
    case HEALTHDECLARATION_LOAD_FAILED:
      return {
        ...state
      };
    case HEALTHDECLARATION_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case HEALTHDECLARATION_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case HEALTHDECLARATION_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case HEALTHDECLARATION_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case HEALTHDECLARATION_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case HEALTHDECLARATION_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case HEALTHDECLARATION_DELETE:
      return {
        ...state
      };
    case HEALTHDECLARATION_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case HEALTHDECLARATION_DELETE_FAILED:
      return {
        ...state
      };
    case HEALTHDECLARATION_DELETE_ALL:
      return {
        ...state
      };
    case HEALTHDECLARATION_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case HEALTHDECLARATION_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case HEALTHDECLARATIONS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case HEALTHDECLARATIONS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case HEALTHDECLARATIONS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
