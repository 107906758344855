import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadDocumentCategories as loadDocumentCategoriesAction,
  loadDocumentCategoriesSuccess,
  addDocumentCategorySuccess,
  updateDocumentCategorySuccess,
  exportExcelDocumentCategories,
  exportExcelDocumentCategoriesSuccess,
  exportExcelDocumentCategoriesFailed
} from '../actions/DocumentCategory';

import {
  DOCUMENTCATEGORIES_LOAD,
  DOCUMENTCATEGORY_ADD,
  DOCUMENTCATEGORY_UPDATE,
  DOCUMENTCATEGORY_DELETE,
  DOCUMENTCATEGORY_DELETE_ALL,
  DOCUMENTCATEGORIES_EXPORT_EXCEL,
  DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS,
  DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getDocumentCategoryById = (id, callback) => {
  Api().get('document-category/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllDocumentCategories = (callback, params) => {
  Api().post('document-category/search', {
    ...params,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadDocumentCategoriesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`document-category/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`document-category`, { params })
    .then(data => data)
    .catch(error => error);
}

const addDocumentCategoryRequest = async (payload) =>
  await Api().post(`document-category`, payload)
    .then(data => data)
    .catch(error => error);

const updateDocumentCategoryRequest = async (payload) =>
  await Api().patch(`document-category/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteDocumentCategoryRequest = async (id) =>
  await Api().delete(`document-category/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadDocumentCategories({ payload }) {
  try {
    const data = yield call(loadDocumentCategoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadDocumentCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadDocumentCategories() {
  yield takeEvery(DOCUMENTCATEGORIES_LOAD, loadDocumentCategories);
}

function* loadDocumentCategory({ selectedDocumentCategoryId }) {
  try {
    const data = yield call(loadDocumentCategoriesRequest, selectedDocumentCategoryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadDocumentCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadDocumentCategory() {
  yield takeEvery(DOCUMENTCATEGORIES_LOAD, loadDocumentCategory);
}


function* addDocumentCategory({ payload }) {
  try {
    const data = yield call(addDocumentCategoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addDocumentCategorySuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddDocumentCategory() {
  yield takeEvery(DOCUMENTCATEGORY_ADD, addDocumentCategory);
}


function* updateDocumentCategory({ payload }) {
  try {
    const data = yield call(updateDocumentCategoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateDocumentCategorySuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateDocumentCategory() {
  yield takeEvery(DOCUMENTCATEGORY_UPDATE, updateDocumentCategory);
}

function* deleteDocumentCategory({ payload }) {
  try {
    const data = yield call(deleteDocumentCategoryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadDocumentCategoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllDocumentCategories({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteDocumentCategoryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadDocumentCategoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteDocumentCategory() {
  yield takeEvery(DOCUMENTCATEGORY_DELETE, deleteDocumentCategory);
}

function* processDeleteAllDocumentCategories() {
  yield takeEvery(DOCUMENTCATEGORY_DELETE_ALL, deleteAllDocumentCategories);
}

const exportDocumentCategoriesRequest = async (params) => {
  return await Api({ responseType: 'blob' }).post(`document-category/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportDocumentCategories({ payload }) {
  try {
    const data = yield call(exportDocumentCategoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(exportExcelDocumentCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportDocumentCategories() {
  yield takeEvery(DOCUMENTCATEGORIES_EXPORT_EXCEL, exportDocumentCategories);
}

export default function* DocumentCategorySagas() {
  yield all([fork(processLoadDocumentCategories),
  fork(processLoadDocumentCategory),
  fork(processAddDocumentCategory),
  fork(processUpdateDocumentCategory),
  fork(processDeleteAllDocumentCategories),
  fork(processDeleteDocumentCategory),
  fork(processExportDocumentCategories)
  ]);
}
