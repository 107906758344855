import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSupplies as loadSuppliesAction,
  loadSuppliesSuccess,
  addSupplySuccess,
  updateSupplySuccess,
  exportExcelSupplies,
  exportExcelSuppliesSuccess,
  exportExcelSuppliesFailed
} from '../actions/Supply';

import {
  SUPPLIES_LOAD,
  SUPPLY_ADD,
  SUPPLY_UPDATE,
  SUPPLY_DELETE,
  SUPPLY_DELETE_ALL,
  SUPPLIES_EXPORT_EXCEL,
  SUPPLIES_EXPORT_EXCEL_SUCCESS,
  SUPPLIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSupplyById = (id, callback) => {
  Api().get('supply/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllSupplies = (callback) => {
  Api().post('supply/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const multipleUpdateSupply = (model, callback) => {
  Api().post('supply/multiple-update', model).then(response => {
    callback(response);
  })
}

export const getSuppliesByCompany = (companyId, callback) => {
  Api().post('supply/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

export const getMaxSupplyCodeSorted = (callback) => {
  Api().post('supply/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadSuppliesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`supply/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`supply`, { params })
    .then(data => data)
    .catch(error => error);
}

const addSupplyRequest = async (payload) =>
  await Api().post(`supply`, payload)
    .then(data => data)
    .catch(error => error);

const updateSupplyRequest = async (payload) =>
  await Api().patch(`supply/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSupplyRequest = async (id) =>
  await Api().delete(`supply/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSupplies({ payload }) {
  try {
    const data = yield call(loadSuppliesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSuppliesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplies() {
  yield takeEvery(SUPPLIES_LOAD, loadSupplies);
}

function* loadSupply({ selectedSupplyId }) {
  try {
    const data = yield call(loadSuppliesRequest, selectedSupplyId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSuppliesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupply() {
  yield takeEvery(SUPPLIES_LOAD, loadSupply);
}


function* addSupply({ payload }) {
  try {
    const data = yield call(addSupplyRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSupplySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSupply() {
  yield takeEvery(SUPPLY_ADD, addSupply);
}


function* updateSupply({ payload }) {
  try {
    const data = yield call(updateSupplyRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSupplySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSupply() {
  yield takeEvery(SUPPLY_UPDATE, updateSupply);
}

function* deleteSupply({ payload }) {
  try {
    const data = yield call(deleteSupplyRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSuppliesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSupplies({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSupplyRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSuppliesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSupply() {
  yield takeEvery(SUPPLY_DELETE, deleteSupply);
}

function* processDeleteAllSupplies() {
  yield takeEvery(SUPPLY_DELETE_ALL, deleteAllSupplies);
}


const exportSuppliesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`supply/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportSupplies({ payload }) {
  try {
    const data = yield call(exportSuppliesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSuppliesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSupplies() {
  yield takeEvery(SUPPLIES_EXPORT_EXCEL, exportSupplies);
}

export default function* SupplySagas() {
  yield all([fork(processLoadSupplies),
  fork(processLoadSupply),
  fork(processAddSupply),
  fork(processUpdateSupply),
  fork(processDeleteAllSupplies),
  fork(processDeleteSupply),
  fork(processExportSupplies)
]);
}
