import {
  WASTES_SELECT_ALL,
  WASTES_UNSELECT_ALL,
  WASTE_SELECTED,
  WASTES_LOAD,
  WASTES_LOAD_SUCCESS,
  WASTES_LOAD_FAILED,
  WASTE_LOAD,
  WASTE_LOAD_SUCCESS,
  WASTE_LOAD_FAILED,
  WASTE_ADD,
  WASTE_ADD_SUCCESS,
  WASTE_ADD_FAILED,
  WASTE_UPDATE,
  WASTE_UPDATE_SUCCESS,
  WASTE_UPDATE_FAILED,
  WASTE_DELETE,
  WASTE_DELETE_SUCCESS,
  WASTE_DELETE_FAILED,
  WASTE_DELETE_ALL,
  WASTE_DELETE_ALL_SUCCESS,
  WASTE_DELETE_ALL_FAILED,
  WASTES_EXPORT_EXCEL,
  WASTES_EXPORT_EXCEL_SUCCESS,
  WASTES_EXPORT_EXCEL_FAILED,
  WASTES_SET_FILTER
} from '../constants/ActionTypes';

export const selectAllWastes = () => {
  return {
    type: WASTES_SELECT_ALL
  }
}

export const unselectAllWastes = () => {
  return {
    type: WASTES_UNSELECT_ALL
  }
}

export const selectWaste = data => {
  return {
    type: WASTE_SELECTED,
    payload: data
  }
}

export const loadWastes = (data) => {
  return {
    type: WASTES_LOAD,
    payload: data
  };
}

export const loadWastesSuccess = (data) => {
  return {
    type: WASTES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadWastesFailed = () => {
  return {
    type: WASTES_LOAD_FAILED
  };
}

export const loadWaste = () => {
  return {
    type: WASTE_LOAD
  };
}

export const loadWasteSuccess = (data) => {
  return {
    type: WASTE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadWasteFailed = () => {
  return {
    type: WASTE_LOAD_FAILED
  };
}


export const addWaste = (data) => {
  return {
    type: WASTE_ADD,
    payload: data
  };
}


export const addWasteSuccess = (filter) => {
  return {
    type: WASTE_ADD_SUCCESS,
    message: 'Waste added successfully.',
    payload: filter
  };
}

export const addWasteFailed = () => {
  return {
    type: WASTE_ADD_FAILED
  };
}

export const updateWaste = (data) => {
  return {
    type: WASTE_UPDATE,
    payload: data
  };
}


export const updateWasteSuccess = (data) => {
  return {
    type: WASTE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateWasteFailed = (data) => {
  return {
    type: WASTE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteWaste = (data) => {
  return {
    type: WASTE_DELETE,
    payload: data
  };
}

export const deleteWasteSuccess = () => {
  return {
    type: WASTE_DELETE_SUCCESS,
    message: 'Waste deleted successfully.'
  };
}

export const deleteWasteFailed = () => {
  return {
    type: WASTE_DELETE_FAILED
  };
}

export const deleteAllWastes = (data) => {
  return {
    type: WASTE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllWastesSuccess = () => {
  return {
    type: WASTE_DELETE_ALL_SUCCESS,
    message: 'Waste all deleted successfully.'
  };
}

export const deleteAllWastesFailed = () => {
  return {
    type: WASTE_DELETE_ALL_FAILED
  };
}

export const exportExcelWastes = (filter) => {
  return {
    type: WASTES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelWastesSuccess = (data) => {
  return {
    type: WASTES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelWastesFailed = () => {
  return {
    type: WASTES_EXPORT_EXCEL_FAILED
  };
}

export const setFilter = (newFilter) => {
  return {
    type: WASTES_SET_FILTER,
    payload: newFilter
  }
}
