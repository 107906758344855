import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSalarySettings as loadSalarySettingsAction,
  loadSalarySettingsSuccess,
  addSalarySettingSuccess,
  updateSalarySettingSuccess,
  exportExcelSalarySettings,
  exportExcelSalarySettingsSuccess,
  exportExcelSalarySettingsFailed
} from '../actions/SalarySetting';

import {
  SALARYSETTINGS_LOAD,
  SALARYSETTING_ADD,
  SALARYSETTING_UPDATE,
  SALARYSETTING_DELETE,
  SALARYSETTING_DELETE_ALL,
  SALARYSETTINGS_EXPORT_EXCEL,
  SALARYSETTINGS_EXPORT_EXCEL_SUCCESS,
  SALARYSETTINGS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSalarySettingById = (id, callback) => {
  Api().get('salarySetting/' + id).then(response => {
    callback(response.data);
  })
}

export const getSalarySettingByEmployeeId = (employeeId, callback) => {
  Api().get('salarySetting/employee/' + employeeId).then(response => {
    callback(response.data);
  })
}

export const getAllSalarySettings = (callback) => {
  Api().post('salarySetting/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getSalarySettingsByCompany = (type, company, callback) => {
  Api().post('salarySetting/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxSalarySettingCodeSorted = (callback) => {
  Api().post('salarySetting/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadSalarySettingsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`salarySetting/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`salarySetting`, { params })
    .then(data => data)
    .catch(error => error);
}

const addSalarySettingRequest = async (payload) =>
  await Api().post(`salarySetting`, payload)
    .then(data => data)
    .catch(error => error);

const updateSalarySettingRequest = async (payload) =>
  await Api().patch(`salarySetting/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSalarySettingRequest = async (id) =>
  await Api().delete(`salarySetting/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSalarySettings({ payload }) {
  try {
    const data = yield call(loadSalarySettingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSalarySettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSalarySettings() {
  yield takeEvery(SALARYSETTINGS_LOAD, loadSalarySettings);
}

function* loadSalarySetting({ selectedSalarySettingId }) {
  try {
    const data = yield call(loadSalarySettingsRequest, selectedSalarySettingId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSalarySettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSalarySetting() {
  yield takeEvery(SALARYSETTINGS_LOAD, loadSalarySetting);
}


function* addSalarySetting({ payload }) {
  try {
    const data = yield call(addSalarySettingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSalarySettingSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSalarySetting() {
  yield takeEvery(SALARYSETTING_ADD, addSalarySetting);
}


function* updateSalarySetting({ payload }) {
  try {
    const data = yield call(updateSalarySettingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSalarySettingSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSalarySetting() {
  yield takeEvery(SALARYSETTING_UPDATE, updateSalarySetting);
}

function* deleteSalarySetting({ payload }) {
  try {
    const data = yield call(deleteSalarySettingRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSalarySettingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSalarySettings({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSalarySettingRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSalarySettingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSalarySetting() {
  yield takeEvery(SALARYSETTING_DELETE, deleteSalarySetting);
}

function* processDeleteAllSalarySettings() {
  yield takeEvery(SALARYSETTING_DELETE_ALL, deleteAllSalarySettings);
}


const exportSalarySettingsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`salarySetting/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportSalarySettings({ payload }) {
  try {
    const data = yield call(exportSalarySettingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSalarySettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSalarySettings() {
  yield takeEvery(SALARYSETTINGS_EXPORT_EXCEL, exportSalarySettings);
}

export default function* SalarySettingSagas() {
  yield all([fork(processLoadSalarySettings),
  fork(processLoadSalarySetting),
  fork(processAddSalarySetting),
  fork(processUpdateSalarySetting),
  fork(processDeleteAllSalarySettings),
  fork(processDeleteSalarySetting),
  fork(processExportSalarySettings)
]);
}
