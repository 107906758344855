import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadLedgers as loadLedgersAction,
  loadLedgersSuccess,
  addLedgerSuccess,
  updateLedgerSuccess,
  exportExcelLedgersSuccess,
  reportLedgersSuccess,
  reportSumLedgersSuccess
} from '../actions/Ledger';

import {
  LEDGERS_LOAD,
  LEDGER_ADD,
  LEDGER_UPDATE,
  LEDGER_DELETE,
  LEDGER_DELETE_ALL,
  LEDGERS_EXPORT_EXCEL,
  LEDGERS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getLedgerById = (id, callback) => {
  Api().get('ledger/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllLedgers = (callback) => {
  Api().post('ledger/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const addOrUpdateLedger = (model, callback) => {
  Api().post('ledger/add-or-update', model).then(response => {
    callback(response);
  })
}

export const getLedgersByCompany = (type, company, callback) => {
  Api().post('ledger/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

export const getMaxLedgerCodeSorted = (callback) => {
  Api().post('ledger/search', {
    offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadLedgersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`ledger/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`ledger`, { params })
    .then(data => data)
    .catch(error => error);
}

const addLedgerRequest = async (payload) =>
  await Api().post(`ledger`, payload)
    .then(data => data)
    .catch(error => error);

const updateLedgerRequest = async (payload) =>
  await Api().patch(`ledger/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteLedgerRequest = async (id) =>
  await Api().delete(`ledger/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadLedgers({ payload }) {
  try {
    const data = yield call(loadLedgersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadLedgersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLedgers() {
  yield takeEvery(LEDGERS_LOAD, loadLedgers);
}

function* loadLedger({ selectedLedgerId }) {
  try {
    const data = yield call(loadLedgersRequest, selectedLedgerId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadLedgersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLedger() {
  yield takeEvery(LEDGERS_LOAD, loadLedger);
}


function* addLedger({ payload }) {
  try {
    const data = yield call(addLedgerRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addLedgerSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddLedger() {
  yield takeEvery(LEDGER_ADD, addLedger);
}


function* updateLedger({ payload }) {
  try {
    const data = yield call(updateLedgerRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateLedgerSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateLedger() {
  yield takeEvery(LEDGER_UPDATE, updateLedger);
}

function* deleteLedger({ payload }) {
  try {
    const data = yield call(deleteLedgerRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadLedgersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllLedgers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteLedgerRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadLedgersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteLedger() {
  yield takeEvery(LEDGER_DELETE, deleteLedger);
}

function* processDeleteAllLedgers() {
  yield takeEvery(LEDGER_DELETE_ALL, deleteAllLedgers);
}


const exportLedgersRequest = async (params) => {
  return await Api({ responseType: 'blob' }).post(`ledger/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportLedgers({ payload }) {
  try {
    const data = yield call(exportLedgersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(exportExcelLedgersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportLedgers() {
  yield takeEvery(LEDGERS_EXPORT_EXCEL, exportLedgers);
}


const reportLedgersRequest = async (params) => {
  return await Api().post(`ledger/report`, params)
    .then(data => data)
    .catch(error => error);
}

function* reportLedgers({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportLedgersRequest, payload);

    if (data.status == 401 || data.response && data.response.status == 401) {
      yield put(userSignOut());
    } else {
      if (payload.type == 'month') {
        yield put(reportLedgersSuccess({ data: data.data, filter: payload }));
      } else {
        yield put(reportSumLedgersSuccess(data.data));
      }
    }
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportLedgers() {
  yield takeEvery(LEDGERS_REPORT, reportLedgers);
}

export default function* LedgerSagas() {
  yield all([fork(processLoadLedgers),
  fork(processLoadLedger),
  fork(processAddLedger),
  fork(processUpdateLedger),
  fork(processDeleteAllLedgers),
  fork(processDeleteLedger),
  fork(processExportLedgers),
  fork(processReportLedgers)
  ]);
}
