import {
  PAYMENTVOUCHERS_SELECT_ALL,
  PAYMENTVOUCHERS_UNSELECT_ALL,
  PAYMENTVOUCHER_SELECTED,
  PAYMENTVOUCHERS_LOAD,
  PAYMENTVOUCHERS_LOAD_SUCCESS,
  PAYMENTVOUCHERS_LOAD_FAILED,
  PAYMENTVOUCHER_LOAD,
  PAYMENTVOUCHER_LOAD_SUCCESS,
  PAYMENTVOUCHER_LOAD_FAILED,
  PAYMENTVOUCHER_ADD,
  PAYMENTVOUCHER_ADD_SUCCESS,
  PAYMENTVOUCHER_ADD_FAILED,
  PAYMENTVOUCHER_UPDATE,
  PAYMENTVOUCHER_UPDATE_SUCCESS,
  PAYMENTVOUCHER_UPDATE_FAILED,
  PAYMENTVOUCHER_DELETE,
  PAYMENTVOUCHER_DELETE_SUCCESS,
  PAYMENTVOUCHER_DELETE_FAILED,
  PAYMENTVOUCHER_DELETE_ALL,
  PAYMENTVOUCHER_DELETE_ALL_SUCCESS,
  PAYMENTVOUCHER_DELETE_ALL_FAILED,
  PAYMENTVOUCHERS_EXPORT_EXCEL,
  PAYMENTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  PAYMENTVOUCHERS_EXPORT_EXCEL_FAILED,
  PAYMENTVOUCHERS_REPORT,
  PAYMENTVOUCHERS_REPORT_SUCCESS,
  PAYMENTVOUCHERS_REPORT_FAILED
} from '../constants/ActionTypes';

export const selectAllPaymentVouchers = () => {
  return {
    type: PAYMENTVOUCHERS_SELECT_ALL
  }
}

export const unselectAllPaymentVouchers = () => {
  return {
    type: PAYMENTVOUCHERS_UNSELECT_ALL
  }
}

export const selectPaymentVoucher = data => {
  return {
    type: PAYMENTVOUCHER_SELECTED,
    payload: data
  }
}

export const loadPaymentVouchers = (data) => {
  return {
    type: PAYMENTVOUCHERS_LOAD,
    payload: data
  };
}

export const loadPaymentVouchersSuccess = (data) => {
  return {
    type: PAYMENTVOUCHERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadPaymentVouchersFailed = () => {
  return {
    type: PAYMENTVOUCHERS_LOAD_FAILED
  };
}

export const loadPaymentVoucher = () => {
  return {
    type: PAYMENTVOUCHER_LOAD
  };
}

export const loadPaymentVoucherSuccess = (data) => {
  return {
    type: PAYMENTVOUCHER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadPaymentVoucherFailed = () => {
  return {
    type: PAYMENTVOUCHER_LOAD_FAILED
  };
}


export const addPaymentVoucher = (data) => {
  return {
    type: PAYMENTVOUCHER_ADD,
    payload: data
  };
}


export const addPaymentVoucherSuccess = (filter) => {
  return {
    type: PAYMENTVOUCHER_ADD_SUCCESS,
    message: 'PaymentVoucher added successfully.',
    payload: filter
  };
}

export const addPaymentVoucherFailed = () => {
  return {
    type: PAYMENTVOUCHER_ADD_FAILED
  };
}

export const updatePaymentVoucher = (data) => {
  return {
    type: PAYMENTVOUCHER_UPDATE,
    payload: data
  };
}


export const updatePaymentVoucherSuccess = (data) => {
  return {
    type: PAYMENTVOUCHER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updatePaymentVoucherFailed = (data) => {
  return {
    type: PAYMENTVOUCHER_UPDATE_FAILED,
    payload: data
  };
}

export const deletePaymentVoucher = (data) => {
  return {
    type: PAYMENTVOUCHER_DELETE,
    payload: data
  };
}

export const deletePaymentVoucherSuccess = () => {
  return {
    type: PAYMENTVOUCHER_DELETE_SUCCESS,
    message: 'PaymentVoucher deleted successfully.'
  };
}

export const deletePaymentVoucherFailed = () => {
  return {
    type: PAYMENTVOUCHER_DELETE_FAILED
  };
}

export const deleteAllPaymentVouchers = (data) => {
  return {
    type: PAYMENTVOUCHER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllPaymentVouchersSuccess = () => {
  return {
    type: PAYMENTVOUCHER_DELETE_ALL_SUCCESS,
    message: 'PaymentVoucher all deleted successfully.'
  };
}

export const deleteAllPaymentVouchersFailed = () => {
  return {
    type: PAYMENTVOUCHER_DELETE_ALL_FAILED
  };
}

export const exportExcelPaymentVouchers = (filter) => {
  return {
    type:PAYMENTVOUCHERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelPaymentVouchersSuccess = (data) => {
  return {
    type:PAYMENTVOUCHERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelPaymentVouchersFailed = () => {
  return {
    type:PAYMENTVOUCHERS_EXPORT_EXCEL_FAILED
  };
}

export const reportPaymentVouchers = (filter) => {
  return {
    type: PAYMENTVOUCHERS_REPORT,
    payload: filter
  }
}

export const reportPaymentVouchersSuccess = (data) => {
  return {
    type: PAYMENTVOUCHERS_REPORT_SUCCESS,
    payload: data
  };
}

export const reportPaymentVouchersFailed = () => {
  return {
    type: PAYMENTVOUCHERS_REPORT_FAILED
  };
}
