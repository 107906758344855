// // /****** localhost ******* */
// export const ApiUrl = "http://localhost:5000/api";
// export const ApiStaticUrl = "http://localhost:5000/medias";

/****** Dev version ********/
// export const ApiUrl = "http://api.giaiphaptoancau.vn/api";
// export const ApiStaticUrl = "http://api.giaiphaptoancau.vn/medias";

/****** Backup version ******* */ //bi loi ngay 05.05.2023
// export const ApiUrl = "http://apivietkhai.xap.vn/api";
// export const ApiStaticUrl = "http://apivietkhai.xap.vn/medias";

/****** Realtime version ******* */
export const ApiUrl = "http://vietkhai.giaiphaptoancau.vn/api";
export const ApiStaticUrl = "http://vietkhai.giaiphaptoancau.vn/medias";
