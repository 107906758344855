import {
  PRODUCTIMPORTVOUCHERS_SELECT_ALL,
  PRODUCTIMPORTVOUCHERS_UNSELECT_ALL,
  PRODUCTIMPORTVOUCHER_SELECTED,
  PRODUCTIMPORTVOUCHERS_LOAD,
  PRODUCTIMPORTVOUCHERS_LOAD_SUCCESS,
  PRODUCTIMPORTVOUCHERS_LOAD_FAILED,
  PRODUCTIMPORTVOUCHER_LOAD,
  PRODUCTIMPORTVOUCHER_LOAD_SUCCESS,
  PRODUCTIMPORTVOUCHER_LOAD_FAILED,
  PRODUCTIMPORTVOUCHER_ADD,
  PRODUCTIMPORTVOUCHER_ADD_SUCCESS,
  PRODUCTIMPORTVOUCHER_ADD_FAILED,
  PRODUCTIMPORTVOUCHER_UPDATE,
  PRODUCTIMPORTVOUCHER_UPDATE_SUCCESS,
  PRODUCTIMPORTVOUCHER_UPDATE_FAILED,
  PRODUCTIMPORTVOUCHER_DELETE,
  PRODUCTIMPORTVOUCHER_DELETE_SUCCESS,
  PRODUCTIMPORTVOUCHER_DELETE_FAILED,
  PRODUCTIMPORTVOUCHER_DELETE_ALL,
  PRODUCTIMPORTVOUCHER_DELETE_ALL_SUCCESS,
  PRODUCTIMPORTVOUCHER_DELETE_ALL_FAILED,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedProductImportVoucher: null,
  selectedProductImportVoucherId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRODUCTIMPORTVOUCHERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case PRODUCTIMPORTVOUCHERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case PRODUCTIMPORTVOUCHER_SELECTED:
      return {
        ...state,
        selectedProductImportVoucher: action.payload,
        selectedProductImportVoucherId: action.payload.id
      };
    case PRODUCTIMPORTVOUCHERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case PRODUCTIMPORTVOUCHERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case PRODUCTIMPORTVOUCHERS_LOAD_FAILED:
      return {
        ...state
      };
    case PRODUCTIMPORTVOUCHER_LOAD:
      return {
        ...state
      };
    case PRODUCTIMPORTVOUCHER_LOAD_SUCCESS:
      return {
        ...state
      };
    case PRODUCTIMPORTVOUCHER_LOAD_FAILED:
      return {
        ...state
      };
    case PRODUCTIMPORTVOUCHER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCTIMPORTVOUCHER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedProductImportVoucher: action.payload
      };
    case PRODUCTIMPORTVOUCHER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTIMPORTVOUCHER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCTIMPORTVOUCHER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTIMPORTVOUCHER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTIMPORTVOUCHER_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case PRODUCTIMPORTVOUCHER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case PRODUCTIMPORTVOUCHER_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case PRODUCTIMPORTVOUCHER_DELETE_ALL:
      return {
        ...state
      };
    case PRODUCTIMPORTVOUCHER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PRODUCTIMPORTVOUCHER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    default:
      return state;
  }
}
