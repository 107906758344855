import {
  PRODUCTIMPORTVOUCHERS_SELECT_ALL,
  PRODUCTIMPORTVOUCHERS_UNSELECT_ALL,
  PRODUCTIMPORTVOUCHER_SELECTED,
  PRODUCTIMPORTVOUCHERS_LOAD,
  PRODUCTIMPORTVOUCHERS_LOAD_SUCCESS,
  PRODUCTIMPORTVOUCHERS_LOAD_FAILED,
  PRODUCTIMPORTVOUCHER_LOAD,
  PRODUCTIMPORTVOUCHER_LOAD_SUCCESS,
  PRODUCTIMPORTVOUCHER_LOAD_FAILED,
  PRODUCTIMPORTVOUCHER_ADD,
  PRODUCTIMPORTVOUCHER_ADD_SUCCESS,
  PRODUCTIMPORTVOUCHER_ADD_FAILED,
  PRODUCTIMPORTVOUCHER_UPDATE,
  PRODUCTIMPORTVOUCHER_UPDATE_SUCCESS,
  PRODUCTIMPORTVOUCHER_UPDATE_FAILED,
  PRODUCTIMPORTVOUCHER_DELETE,
  PRODUCTIMPORTVOUCHER_DELETE_SUCCESS,
  PRODUCTIMPORTVOUCHER_DELETE_FAILED,
  PRODUCTIMPORTVOUCHER_DELETE_ALL,
  PRODUCTIMPORTVOUCHER_DELETE_ALL_SUCCESS,
  PRODUCTIMPORTVOUCHER_DELETE_ALL_FAILED,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllProductImportVouchers = () => {
  return {
    type: PRODUCTIMPORTVOUCHERS_SELECT_ALL
  }
}

export const unselectAllProductImportVouchers = () => {
  return {
    type: PRODUCTIMPORTVOUCHERS_UNSELECT_ALL
  }
}

export const selectProductImportVoucher = data => {
  return {
    type: PRODUCTIMPORTVOUCHER_SELECTED,
    payload: data
  }
}

export const loadProductImportVouchers = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHERS_LOAD,
    payload: data
  };
}

export const loadProductImportVouchersSuccess = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadProductImportVouchersFailed = () => {
  return {
    type: PRODUCTIMPORTVOUCHERS_LOAD_FAILED
  };
}

export const loadProductImportVoucher = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_LOAD
  };
}

export const loadProductImportVoucherSuccess = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadProductImportVoucherFailed = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_LOAD_FAILED
  };
}


export const addProductImportVoucher = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_ADD,
    payload: data
  };
}


export const addProductImportVoucherSuccess = (filter) => {
  return {
    type: PRODUCTIMPORTVOUCHER_ADD_SUCCESS,
    message: 'ProductImportVoucher added successfully.',
    payload: filter
  };
}

export const addProductImportVoucherFailed = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_ADD_FAILED
  };
}

export const updateProductImportVoucher = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_UPDATE,
    payload: data
  };
}


export const updateProductImportVoucherSuccess = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateProductImportVoucherFailed = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteProductImportVoucher = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_DELETE,
    payload: data
  };
}

export const deleteProductImportVoucherSuccess = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_DELETE_SUCCESS,
    message: 'ProductImportVoucher deleted successfully.'
  };
}

export const deleteProductImportVoucherFailed = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_DELETE_FAILED
  };
}

export const deleteAllProductImportVouchers = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllProductImportVouchersSuccess = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_DELETE_ALL_SUCCESS,
    message: 'ProductImportVoucher all deleted successfully.'
  };
}

export const deleteAllProductImportVouchersFailed = () => {
  return {
    type: PRODUCTIMPORTVOUCHER_DELETE_ALL_FAILED
  };
}

export const exportExcelProductImportVouchers = (filter) => {
  return {
    type:PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelProductImportVouchersSuccess = (data) => {
  return {
    type: PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelProductImportVouchersFailed = () => {
  return {
    type: PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_FAILED
  };
}