import {
  SUPPLYIMPORTVOUCHERS_SELECT_ALL,
  SUPPLYIMPORTVOUCHERS_UNSELECT_ALL,
  SUPPLYIMPORTVOUCHER_SELECTED,
  SUPPLYIMPORTVOUCHERS_LOAD,
  SUPPLYIMPORTVOUCHERS_LOAD_SUCCESS,
  SUPPLYIMPORTVOUCHERS_LOAD_FAILED,
  SUPPLYIMPORTVOUCHER_LOAD,
  SUPPLYIMPORTVOUCHER_LOAD_SUCCESS,
  SUPPLYIMPORTVOUCHER_LOAD_FAILED,
  SUPPLYIMPORTVOUCHER_ADD,
  SUPPLYIMPORTVOUCHER_ADD_SUCCESS,
  SUPPLYIMPORTVOUCHER_ADD_FAILED,
  SUPPLYIMPORTVOUCHER_UPDATE,
  SUPPLYIMPORTVOUCHER_UPDATE_SUCCESS,
  SUPPLYIMPORTVOUCHER_UPDATE_FAILED,
  SUPPLYIMPORTVOUCHER_DELETE,
  SUPPLYIMPORTVOUCHER_DELETE_SUCCESS,
  SUPPLYIMPORTVOUCHER_DELETE_FAILED,
  SUPPLYIMPORTVOUCHER_DELETE_ALL,
  SUPPLYIMPORTVOUCHER_DELETE_ALL_SUCCESS,
  SUPPLYIMPORTVOUCHER_DELETE_ALL_FAILED,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSupplyImportVouchers = () => {
  return {
    type: SUPPLYIMPORTVOUCHERS_SELECT_ALL
  }
}

export const unselectAllSupplyImportVouchers = () => {
  return {
    type: SUPPLYIMPORTVOUCHERS_UNSELECT_ALL
  }
}

export const selectSupplyImportVoucher = data => {
  return {
    type: SUPPLYIMPORTVOUCHER_SELECTED,
    payload: data
  }
}

export const loadSupplyImportVouchers = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHERS_LOAD,
    payload: data
  };
}

export const loadSupplyImportVouchersSuccess = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSupplyImportVouchersFailed = () => {
  return {
    type: SUPPLYIMPORTVOUCHERS_LOAD_FAILED
  };
}

export const loadSupplyImportVoucher = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_LOAD
  };
}

export const loadSupplyImportVoucherSuccess = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSupplyImportVoucherFailed = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_LOAD_FAILED
  };
}


export const addSupplyImportVoucher = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_ADD,
    payload: data
  };
}


export const addSupplyImportVoucherSuccess = (filter) => {
  return {
    type: SUPPLYIMPORTVOUCHER_ADD_SUCCESS,
    message: 'SupplyImportVoucher added successfully.',
    payload: filter
  };
}

export const addSupplyImportVoucherFailed = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_ADD_FAILED
  };
}

export const updateSupplyImportVoucher = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_UPDATE,
    payload: data
  };
}


export const updateSupplyImportVoucherSuccess = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSupplyImportVoucherFailed = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSupplyImportVoucher = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_DELETE,
    payload: data
  };
}

export const deleteSupplyImportVoucherSuccess = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_DELETE_SUCCESS,
    message: 'SupplyImportVoucher deleted successfully.'
  };
}

export const deleteSupplyImportVoucherFailed = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_DELETE_FAILED
  };
}

export const deleteAllSupplyImportVouchers = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSupplyImportVouchersSuccess = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_DELETE_ALL_SUCCESS,
    message: 'SupplyImportVoucher all deleted successfully.'
  };
}

export const deleteAllSupplyImportVouchersFailed = () => {
  return {
    type: SUPPLYIMPORTVOUCHER_DELETE_ALL_FAILED
  };
}

export const exportExcelSupplyImportVouchers = (filter) => {
  return {
    type:SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSupplyImportVouchersSuccess = (data) => {
  return {
    type: SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSupplyImportVouchersFailed = () => {
  return {
    type: SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_FAILED
  };
}