import {
  TIMEKEEPS_SELECT_ALL,
  TIMEKEEPS_UNSELECT_ALL,
  TIMEKEEP_SELECTED,
  TIMEKEEPS_LOAD,
  TIMEKEEPS_LOAD_SUCCESS,
  TIMEKEEPS_LOAD_FAILED,
  TIMEKEEP_LOAD,
  TIMEKEEP_LOAD_SUCCESS,
  TIMEKEEP_LOAD_FAILED,
  TIMEKEEP_ADD,
  TIMEKEEP_ADD_SUCCESS,
  TIMEKEEP_ADD_FAILED,
  TIMEKEEP_UPDATE,
  TIMEKEEP_UPDATE_SUCCESS,
  TIMEKEEP_UPDATE_FAILED,
  TIMEKEEP_DELETE,
  TIMEKEEP_DELETE_SUCCESS,
  TIMEKEEP_DELETE_FAILED,
  TIMEKEEP_DELETE_ALL,
  TIMEKEEP_DELETE_ALL_SUCCESS,
  TIMEKEEP_DELETE_ALL_FAILED,
  TIMEKEEPS_EXPORT_EXCEL,
  TIMEKEEPS_EXPORT_EXCEL_SUCCESS,
  TIMEKEEPS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';

export const selectAllTimeKeeps = () => {
  return {
    type: TIMEKEEPS_SELECT_ALL
  }
}

export const unselectAllTimeKeeps = () => {
  return {
    type: TIMEKEEPS_UNSELECT_ALL
  }
}

export const selectTimeKeep = data => {
  return {
    type: TIMEKEEP_SELECTED,
    payload: data
  }
}

export const loadTimeKeeps = (data) => {
  return {
    type: TIMEKEEPS_LOAD,
    payload: data
  };
}

export const loadTimeKeepsSuccess = (data) => {
  return {
    type: TIMEKEEPS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadTimeKeepsFailed = () => {
  return {
    type: TIMEKEEPS_LOAD_FAILED
  };
}

export const loadTimeKeep = () => {
  return {
    type: TIMEKEEP_LOAD
  };
}

export const loadTimeKeepSuccess = (data) => {
  return {
    type: TIMEKEEP_LOAD_SUCCESS,
    payload: data
  };
}


export const loadTimeKeepFailed = () => {
  return {
    type: TIMEKEEP_LOAD_FAILED
  };
}


export const addTimeKeep = (data) => {
  return {
    type: TIMEKEEP_ADD,
    payload: data
  };
}


export const addTimeKeepSuccess = (filter) => {
  return {
    type: TIMEKEEP_ADD_SUCCESS,
    message: 'TimeKeep added successfully.',
    payload: filter
  };
}

export const addTimeKeepFailed = () => {
  return {
    type: TIMEKEEP_ADD_FAILED
  };
}

export const updateTimeKeep = (data) => {
  return {
    type: TIMEKEEP_UPDATE,
    payload: data
  };
}


export const updateTimeKeepSuccess = (data) => {
  return {
    type: TIMEKEEP_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateTimeKeepFailed = (data) => {
  return {
    type: TIMEKEEP_UPDATE_FAILED,
    payload: data
  };
}

export const deleteTimeKeep = (data) => {
  return {
    type: TIMEKEEP_DELETE,
    payload: data
  };
}

export const deleteTimeKeepSuccess = () => {
  return {
    type: TIMEKEEP_DELETE_SUCCESS,
    message: 'TimeKeep deleted successfully.'
  };
}

export const deleteTimeKeepFailed = () => {
  return {
    type: TIMEKEEP_DELETE_FAILED
  };
}

export const deleteAllTimeKeeps = (data) => {
  return {
    type: TIMEKEEP_DELETE_ALL,
    payload: data
  };
}

export const deleteAllTimeKeepsSuccess = () => {
  return {
    type: TIMEKEEP_DELETE_ALL_SUCCESS,
    message: 'TimeKeep all deleted successfully.'
  };
}

export const deleteAllTimeKeepsFailed = () => {
  return {
    type: TIMEKEEP_DELETE_ALL_FAILED
  };
}

export const exportExcelTimeKeeps = (filter) => {
  return {
    type: TIMEKEEPS_EXPORT_EXCEL,
    payload: filter
  }
}

export const exportExcelTimeKeepsSuccess = (data) => {
  return {
    type: TIMEKEEPS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelTimeKeepsFailed = () => {
  return {
    type: TIMEKEEPS_EXPORT_EXCEL_FAILED
  };
}