import {
  QUOTATIONWASTES_SELECT_ALL,
  QUOTATIONWASTES_UNSELECT_ALL,
  QUOTATIONWASTE_SELECTED,
  QUOTATIONWASTES_LOAD,
  QUOTATIONWASTES_LOAD_SUCCESS,
  QUOTATIONWASTES_LOAD_FAILED,
  QUOTATIONWASTE_LOAD,
  QUOTATIONWASTE_LOAD_SUCCESS,
  QUOTATIONWASTE_LOAD_FAILED,
  QUOTATIONWASTE_ADD,
  QUOTATIONWASTE_ADD_SUCCESS,
  QUOTATIONWASTE_ADD_FAILED,
  QUOTATIONWASTE_UPDATE,
  QUOTATIONWASTE_UPDATE_SUCCESS,
  QUOTATIONWASTE_UPDATE_FAILED,
  QUOTATIONWASTE_DELETE,
  QUOTATIONWASTE_DELETE_SUCCESS,
  QUOTATIONWASTE_DELETE_FAILED,
  QUOTATIONWASTE_DELETE_ALL,
  QUOTATIONWASTE_DELETE_ALL_SUCCESS,
  QUOTATIONWASTE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllQuotationWastes = () => {
  return {
    type: QUOTATIONWASTES_SELECT_ALL
  }
}

export const unselectAllQuotationWastes = () => {
  return {
    type: QUOTATIONWASTES_UNSELECT_ALL
  }
}

export const selectQuotationWaste = data => {
  return {
    type: QUOTATIONWASTE_SELECTED,
    payload: data
  }
}

export const loadQuotationWastes = (data) => {
  return {
    type: QUOTATIONWASTES_LOAD,
    payload: data
  };
}

export const loadQuotationWastesSuccess = (data) => {
  return {
    type: QUOTATIONWASTES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadQuotationWastesFailed = () => {
  return {
    type: QUOTATIONWASTES_LOAD_FAILED
  };
}

export const loadQuotationWaste = () => {
  return {
    type: QUOTATIONWASTE_LOAD
  };
}

export const loadQuotationWasteSuccess = (data) => {
  return {
    type: QUOTATIONWASTE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadQuotationWasteFailed = () => {
  return {
    type: QUOTATIONWASTE_LOAD_FAILED
  };
}


export const addQuotationWaste = (data) => {
  return {
    type: QUOTATIONWASTE_ADD,
    payload: data
  };
}


export const addQuotationWasteSuccess = (filter) => {
  return {
    type: QUOTATIONWASTE_ADD_SUCCESS,
    message: 'QuotationWaste added successfully.',
    payload: filter
  };
}

export const addQuotationWasteFailed = () => {
  return {
    type: QUOTATIONWASTE_ADD_FAILED
  };
}

export const updateQuotationWaste = (data) => {
  return {
    type: QUOTATIONWASTE_UPDATE,
    payload: data
  };
}


export const updateQuotationWasteSuccess = (data) => {
  return {
    type: QUOTATIONWASTE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateQuotationWasteFailed = (data) => {
  return {
    type: QUOTATIONWASTE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteQuotationWaste = (data) => {
  return {
    type: QUOTATIONWASTE_DELETE,
    payload: data
  };
}

export const deleteQuotationWasteSuccess = () => {
  return {
    type: QUOTATIONWASTE_DELETE_SUCCESS,
    message: 'QuotationWaste deleted successfully.'
  };
}

export const deleteQuotationWasteFailed = () => {
  return {
    type: QUOTATIONWASTE_DELETE_FAILED
  };
}

export const deleteAllQuotationWastes = (data) => {
  return {
    type: QUOTATIONWASTE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllQuotationWastesSuccess = () => {
  return {
    type: QUOTATIONWASTE_DELETE_ALL_SUCCESS,
    message: 'QuotationWaste all deleted successfully.'
  };
}

export const deleteAllQuotationWastesFailed = () => {
  return {
    type: QUOTATIONWASTE_DELETE_ALL_FAILED
  };
}
