import {
  NOTIFICATIONS_SELECT_ALL,
  NOTIFICATIONS_UNSELECT_ALL,
  NOTIFICATION_SELECTED,
  NOTIFICATIONS_LOAD,
  NOTIFICATIONS_LOAD_SUCCESS,
  NOTIFICATIONS_LOAD_FAILED,
  NOTIFICATION_LOAD,
  NOTIFICATION_LOAD_SUCCESS,
  NOTIFICATION_LOAD_FAILED,
  NOTIFICATION_ADD,
  NOTIFICATION_ADD_SUCCESS,
  NOTIFICATION_ADD_FAILED,
  NOTIFICATION_UPDATE,
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_FAILED,
  NOTIFICATION_DELETE,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAILED,
  NOTIFICATION_DELETE_ALL,
  NOTIFICATION_DELETE_ALL_SUCCESS,
  NOTIFICATION_DELETE_ALL_FAILED,
  NOTIFICATIONS_COUNT_SUCCESS
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  notify: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedNotification: null,
  selectedNotificationId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true,
  numberOfNotifications: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case NOTIFICATIONS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case NOTIFICATION_SELECTED:
      return {
        ...state,
        selectedNotification: action.payload,
        selectedNotificationId: action.payload.id
      };
    case NOTIFICATIONS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case NOTIFICATIONS_LOAD_SUCCESS:
      if (action.isNotify) {
        return {
          ...state,
          notify: {...state.notify,
            records: action.payload.offset != 0 ? state.notify.records.concat(action.payload.records) : action.payload.records
          }
        }
      } else {
        return {
          ...state,
          paging: action.payload
        };
      }
    case NOTIFICATIONS_LOAD_FAILED:
      return {
        ...state
      };
    case NOTIFICATIONS_COUNT_SUCCESS: 
      return {
        ...state,
        numberOfNotifications: action.payload
      }
    case NOTIFICATION_LOAD:
      return {
        ...state
      };
    case NOTIFICATION_LOAD_SUCCESS:
      return {
        ...state
      };
    case NOTIFICATION_LOAD_FAILED:
      return {
        ...state
      };
    case NOTIFICATION_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case NOTIFICATION_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case NOTIFICATION_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case NOTIFICATION_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case NOTIFICATION_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case NOTIFICATION_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case NOTIFICATION_DELETE:
      return {
        ...state
      };
    case NOTIFICATION_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case NOTIFICATION_DELETE_FAILED:
      return {
        ...state
      };
    case NOTIFICATION_DELETE_ALL:
      return {
        ...state
      };
    case NOTIFICATION_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case NOTIFICATION_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
