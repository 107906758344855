import {
  SUPPLYEXPORTVOUCHERS_SELECT_ALL,
  SUPPLYEXPORTVOUCHERS_UNSELECT_ALL,
  SUPPLYEXPORTVOUCHER_SELECTED,
  SUPPLYEXPORTVOUCHERS_LOAD,
  SUPPLYEXPORTVOUCHERS_LOAD_SUCCESS,
  SUPPLYEXPORTVOUCHERS_LOAD_FAILED,
  SUPPLYEXPORTVOUCHER_LOAD,
  SUPPLYEXPORTVOUCHER_LOAD_SUCCESS,
  SUPPLYEXPORTVOUCHER_LOAD_FAILED,
  SUPPLYEXPORTVOUCHER_ADD,
  SUPPLYEXPORTVOUCHER_ADD_SUCCESS,
  SUPPLYEXPORTVOUCHER_ADD_FAILED,
  SUPPLYEXPORTVOUCHER_UPDATE,
  SUPPLYEXPORTVOUCHER_UPDATE_SUCCESS,
  SUPPLYEXPORTVOUCHER_UPDATE_FAILED,
  SUPPLYEXPORTVOUCHER_DELETE,
  SUPPLYEXPORTVOUCHER_DELETE_SUCCESS,
  SUPPLYEXPORTVOUCHER_DELETE_FAILED,
  SUPPLYEXPORTVOUCHER_DELETE_ALL,
  SUPPLYEXPORTVOUCHER_DELETE_ALL_SUCCESS,
  SUPPLYEXPORTVOUCHER_DELETE_ALL_FAILED,
  SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL,
  SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
  SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSupplyExportVouchers = () => {
  return {
    type: SUPPLYEXPORTVOUCHERS_SELECT_ALL
  }
}

export const unselectAllSupplyExportVouchers = () => {
  return {
    type: SUPPLYEXPORTVOUCHERS_UNSELECT_ALL
  }
}

export const selectSupplyExportVoucher = data => {
  return {
    type: SUPPLYEXPORTVOUCHER_SELECTED,
    payload: data
  }
}

export const loadSupplyExportVouchers = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHERS_LOAD,
    payload: data
  };
}

export const loadSupplyExportVouchersSuccess = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSupplyExportVouchersFailed = () => {
  return {
    type: SUPPLYEXPORTVOUCHERS_LOAD_FAILED
  };
}

export const loadSupplyExportVoucher = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_LOAD
  };
}

export const loadSupplyExportVoucherSuccess = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSupplyExportVoucherFailed = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_LOAD_FAILED
  };
}


export const addSupplyExportVoucher = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_ADD,
    payload: data
  };
}


export const addSupplyExportVoucherSuccess = (filter) => {
  return {
    type: SUPPLYEXPORTVOUCHER_ADD_SUCCESS,
    message: 'SupplyExportVoucher added successfully.',
    payload: filter
  };
}

export const addSupplyExportVoucherFailed = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_ADD_FAILED
  };
}

export const updateSupplyExportVoucher = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_UPDATE,
    payload: data
  };
}


export const updateSupplyExportVoucherSuccess = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSupplyExportVoucherFailed = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSupplyExportVoucher = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_DELETE,
    payload: data
  };
}

export const deleteSupplyExportVoucherSuccess = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_DELETE_SUCCESS,
    message: 'SupplyExportVoucher deleted successfully.'
  };
}

export const deleteSupplyExportVoucherFailed = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_DELETE_FAILED
  };
}

export const deleteAllSupplyExportVouchers = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSupplyExportVouchersSuccess = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_DELETE_ALL_SUCCESS,
    message: 'SupplyExportVoucher all deleted successfully.'
  };
}

export const deleteAllSupplyExportVouchersFailed = () => {
  return {
    type: SUPPLYEXPORTVOUCHER_DELETE_ALL_FAILED
  };
}

export const exportExcelSupplyExportVouchers = (filter) => {
  return {
    type:SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSupplyExportVouchersSuccess = (data) => {
  return {
    type: SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSupplyExportVouchersFailed = () => {
  return {
    type: SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_FAILED
  };
}