import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadFormReceives as loadFormReceivesAction,
  loadFormReceivesSuccess,
  addFormReceiveSuccess,
  updateFormReceiveSuccess,
  exportExcelFormReceives,
  exportExcelFormReceivesSuccess,
  exportExcelFormReceivesFailed
} from '../actions/FormReceive';

import {
  FORMRECEIVES_LOAD,
  FORMRECEIVE_ADD,
  FORMRECEIVE_UPDATE,
  FORMRECEIVE_DELETE,
  FORMRECEIVE_DELETE_ALL,
  FORMRECEIVES_EXPORT_EXCEL,
  FORMRECEIVES_EXPORT_EXCEL_SUCCESS,
  FORMRECEIVES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getFormReceiveById = (id, callback) => {
  Api().get('form-receive/' + id).then(response => {
    callback(response.data);
  })
}

export const changeFormReceiveStatus = (model, callback) => {
  Api().post('form-receive/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllFormReceives = (callback) => {
  Api().post('form-receive/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxFormReceiveCodeSorted = (type, callback) => {
  Api().post('form-receive/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'type',
        operator: '=',
        value: type
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadFormReceivesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`form-receive/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`form-receive`, { params })
    .then(data => data)
    .catch(error => error);
}

const addFormReceiveRequest = async (payload) =>
  await Api().post(`form-receive`, payload)
    .then(data => data)
    .catch(error => error);

const updateFormReceiveRequest = async (payload) =>
  await Api().patch(`form-receive/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const updatePatchFormReceiveRequest = async (payload) =>
  await Api().patch(`form-receive/update/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteFormReceiveRequest = async (id) =>
  await Api().delete(`form-receive/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadFormReceives({ payload }) {
  try {
    const data = yield call(loadFormReceivesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadFormReceivesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormReceives() {
  yield takeEvery(FORMRECEIVES_LOAD, loadFormReceives);
}

function* loadFormReceive({ selectedFormReceiveId }) {
  try {
    const data = yield call(loadFormReceivesRequest, selectedFormReceiveId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadFormReceivesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormReceive() {
  yield takeEvery(FORMRECEIVES_LOAD, loadFormReceive);
}


function* addFormReceive({ payload }) {
  try {
    const data = yield call(addFormReceiveRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addFormReceiveSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddFormReceive() {
  yield takeEvery(FORMRECEIVE_ADD, addFormReceive);
}


function* updateFormReceive({ payload }) {
  try {
    const data = yield call(updateFormReceiveRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateFormReceiveSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateFormReceive() {
  yield takeEvery(FORMRECEIVE_UPDATE, updateFormReceive);
}

function* deleteFormReceive({ payload }) {
  try {
    const data = yield call(deleteFormReceiveRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadFormReceivesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllFormReceives({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteFormReceiveRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadFormReceivesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteFormReceive() {
  yield takeEvery(FORMRECEIVE_DELETE, deleteFormReceive);
}

function* processDeleteAllFormReceives() {
  yield takeEvery(FORMRECEIVE_DELETE_ALL, deleteAllFormReceives);
}

const exportFormReceivesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`form-receive/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportFormReceives({ payload }) {
  try {
    const data = yield call(exportFormReceivesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelFormReceivesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportFormReceives() {
  yield takeEvery(FORMRECEIVES_EXPORT_EXCEL, exportFormReceives);
}

export default function* FormReceiveSagas() {
  yield all([fork(processLoadFormReceives),
  fork(processLoadFormReceive),
  fork(processAddFormReceive),
  fork(processUpdateFormReceive),
  fork(processDeleteAllFormReceives),
  fork(processDeleteFormReceive),
  fork(processExportFormReceives)
]);
}
