import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadFormProcessings as loadFormProcessingsAction,
  loadFormProcessingsSuccess,
  addFormProcessingSuccess,
  updateFormProcessingSuccess,
  exportExcelFormProcessings,
  exportExcelFormProcessingsSuccess,
  exportExcelFormProcessingsFailed
} from '../actions/FormProcessing';

import {
  FORMPROCESSINGS_LOAD,
  FORMPROCESSING_ADD,
  FORMPROCESSING_UPDATE,
  FORMPROCESSING_DELETE,
  FORMPROCESSING_DELETE_ALL,
  FORMPROCESSINGS_EXPORT_EXCEL,
  FORMPROCESSINGS_EXPORT_EXCEL_SUCCESS,
  FORMPROCESSINGS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getFormProcessingById = (id, callback) => {
  Api().get('form-processing/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllFormProcessings = (callback) => {
  Api().post('form-processing/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const getMaxFormProcessingCodeSorted = (processingType, callback) => {
  Api().post('form-processing/search', {
    offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'processingType',
        operator: '=',
        value: processingType
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadFormProcessingsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`form-processing/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`form-processing`, { params })
    .then(data => data)
    .catch(error => error);
}

const addFormProcessingRequest = async (payload) =>
  await Api().post(`form-processing`, payload)
    .then(data => data)
    .catch(error => error);

const updateFormProcessingRequest = async (payload) =>
  await Api().patch(`form-processing/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteFormProcessingRequest = async (id) =>
  await Api().delete(`form-processing/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadFormProcessings({ payload }) {
  try {
    const data = yield call(loadFormProcessingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormProcessingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormProcessings() {
  yield takeEvery(FORMPROCESSINGS_LOAD, loadFormProcessings);
}

function* loadFormProcessing({ selectedFormProcessingId }) {
  try {
    const data = yield call(loadFormProcessingsRequest, selectedFormProcessingId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormProcessingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormProcessing() {
  yield takeEvery(FORMPROCESSINGS_LOAD, loadFormProcessing);
}


function* addFormProcessing({ payload }) {
  try {
    const data = yield call(addFormProcessingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addFormProcessingSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddFormProcessing() {
  yield takeEvery(FORMPROCESSING_ADD, addFormProcessing);
}


function* updateFormProcessing({ payload }) {
  try {
    const data = yield call(updateFormProcessingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateFormProcessingSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateFormProcessing() {
  yield takeEvery(FORMPROCESSING_UPDATE, updateFormProcessing);
}

function* deleteFormProcessing({ payload }) {
  try {
    const data = yield call(deleteFormProcessingRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormProcessingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllFormProcessings({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteFormProcessingRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadFormProcessingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteFormProcessing() {
  yield takeEvery(FORMPROCESSING_DELETE, deleteFormProcessing);
}

function* processDeleteAllFormProcessings() {
  yield takeEvery(FORMPROCESSING_DELETE_ALL, deleteAllFormProcessings);
}

const exportFormProcessingsRequest = async (params) => {
  return await Api({ responseType: 'blob' }).post(`form-processing/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportFormProcessings({ payload }) {
  try {
    const data = yield call(exportFormProcessingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(exportExcelFormProcessingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportFormProcessings() {
  yield takeEvery(FORMPROCESSINGS_EXPORT_EXCEL, exportFormProcessings);
}

export default function* FormProcessingSagas() {
  yield all([fork(processLoadFormProcessings),
  fork(processLoadFormProcessing),
  fork(processAddFormProcessing),
  fork(processUpdateFormProcessing),
  fork(processDeleteAllFormProcessings),
  fork(processDeleteFormProcessing),
  fork(processExportFormProcessings)
  ]);
}
