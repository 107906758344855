import {
  SALARYHISTORIES_SELECT_ALL,
  SALARYHISTORIES_UNSELECT_ALL,
  SALARYHISTORY_SELECTED,
  SALARYHISTORIES_LOAD,
  SALARYHISTORIES_LOAD_SUCCESS,
  SALARYHISTORIES_LOAD_FAILED,
  SALARYHISTORY_LOAD,
  SALARYHISTORY_LOAD_SUCCESS,
  SALARYHISTORY_LOAD_FAILED,
  SALARYHISTORY_ADD,
  SALARYHISTORY_ADD_SUCCESS,
  SALARYHISTORY_ADD_FAILED,
  SALARYHISTORY_UPDATE,
  SALARYHISTORY_UPDATE_SUCCESS,
  SALARYHISTORY_UPDATE_FAILED,
  SALARYHISTORY_DELETE,
  SALARYHISTORY_DELETE_SUCCESS,
  SALARYHISTORY_DELETE_FAILED,
  SALARYHISTORY_DELETE_ALL,
  SALARYHISTORY_DELETE_ALL_SUCCESS,
  SALARYHISTORY_DELETE_ALL_FAILED,
  SALARYHISTORIES_EXPORT_EXCEL,
  SALARYHISTORIES_EXPORT_EXCEL_SUCCESS,
  SALARYHISTORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSalaryHistories = () => {
  return {
    type: SALARYHISTORIES_SELECT_ALL
  }
}

export const unselectAllSalaryHistories = () => {
  return {
    type: SALARYHISTORIES_UNSELECT_ALL
  }
}

export const selectSalaryHistory = data => {
  return {
    type: SALARYHISTORY_SELECTED,
    payload: data
  }
}

export const loadSalaryHistories = (data) => {
  return {
    type: SALARYHISTORIES_LOAD,
    payload: data
  };
}

export const loadSalaryHistoriesSuccess = (data) => {
  return {
    type: SALARYHISTORIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSalaryHistoriesFailed = () => {
  return {
    type: SALARYHISTORIES_LOAD_FAILED
  };
}

export const loadSalaryHistory = () => {
  return {
    type: SALARYHISTORY_LOAD
  };
}

export const loadSalaryHistorySuccess = (data) => {
  return {
    type: SALARYHISTORY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSalaryHistoryFailed = () => {
  return {
    type: SALARYHISTORY_LOAD_FAILED
  };
}


export const addSalaryHistory = (data) => {
  return {
    type: SALARYHISTORY_ADD,
    payload: data
  };
}


export const addSalaryHistorySuccess = (filter) => {
  return {
    type: SALARYHISTORY_ADD_SUCCESS,
    message: 'SalaryHistory added successfully.',
    payload: filter
  };
}

export const addSalaryHistoryFailed = () => {
  return {
    type: SALARYHISTORY_ADD_FAILED
  };
}

export const updateSalaryHistory = (data) => {
  return {
    type: SALARYHISTORY_UPDATE,
    payload: data
  };
}


export const updateSalaryHistorySuccess = (data) => {
  return {
    type: SALARYHISTORY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSalaryHistoryFailed = (data) => {
  return {
    type: SALARYHISTORY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSalaryHistory = (data) => {
  return {
    type: SALARYHISTORY_DELETE,
    payload: data
  };
}

export const deleteSalaryHistorySuccess = () => {
  return {
    type: SALARYHISTORY_DELETE_SUCCESS,
    message: 'SalaryHistory deleted successfully.'
  };
}

export const deleteSalaryHistoryFailed = () => {
  return {
    type: SALARYHISTORY_DELETE_FAILED
  };
}

export const deleteAllSalaryHistories = (data) => {
  return {
    type: SALARYHISTORY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSalaryHistoriesSuccess = () => {
  return {
    type: SALARYHISTORY_DELETE_ALL_SUCCESS,
    message: 'SalaryHistory all deleted successfully.'
  };
}

export const deleteAllSalaryHistoriesFailed = () => {
  return {
    type: SALARYHISTORY_DELETE_ALL_FAILED
  };
}

export const exportExcelSalaryHistories = (filter) => {
  return {
    type:SALARYHISTORIES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSalaryHistoriesSuccess = (data) => {
  return {
    type:SALARYHISTORIES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSalaryHistoriesFailed = () => {
  return {
    type:SALARYHISTORIES_EXPORT_EXCEL_FAILED
  };
}
