import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadFormProcessingFormReceiveQuotationWastes as loadFormProcessingFormReceiveQuotationWastesAction,
  loadFormProcessingFormReceiveQuotationWastesSuccess,
  updateFormProcessingFormReceiveQuotationWasteSuccess,
  addFormProcessingFormReceiveQuotationWasteSuccess,
  deleteFormProcessingFormReceiveQuotationWasteSuccess,
  deleteAllFormProcessingFormReceiveQuotationWastesSuccess,
  exportExcelFormProcessingFormReceiveQuotationWastes,
  exportExcelFormProcessingFormReceiveQuotationWastesSuccess,
  exportExcelFormProcessingFormReceiveQuotationWastesFailed
} from '../actions/FormProcessingFormReceiveQuotationWaste';

import {
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
  FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getFormProcessingFormReceiveQuotationWasteById = (id, callback) => {
  Api().get('form-processing-form-receive-quotation-waste/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllFormProcessingFormReceiveQuotationWastes = (callback, params) => {
  if (!params) {
    params = {
      offset: 0,
      limit: GET_ALL_SIZE,
      orderBy: 'wasteCode',
      descending: false
    }
  }
  
  Api().post('form-processing-form-receive-quotation-waste/search', {
    ...params,
    offset: 0,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadFormProcessingFormReceiveQuotationWastesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`form-processing-form-receive-quotation-waste/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`form-processing-form-receive-quotation-waste`, { params })
    .then(data => data)
    .catch(error => error);
}

const addFormProcessingFormReceiveQuotationWasteRequest = async (payload) =>
  await Api().post(`form-processing-form-receive-quotation-waste`, payload)
    .then(data => data)
    .catch(error => error);

const updateFormProcessingFormReceiveQuotationWasteRequest = async (payload) =>
  await Api().patch(`form-processing-form-receive-quotation-waste/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteFormProcessingFormReceiveQuotationWasteRequest = async (id) =>
  await Api().delete(`form-processing-form-receive-quotation-waste/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadFormProcessingFormReceiveQuotationWastes({ payload }) {
  try {
    const data = yield call(loadFormProcessingFormReceiveQuotationWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormProcessingFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormProcessingFormReceiveQuotationWastes() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD, loadFormProcessingFormReceiveQuotationWastes);
}

function* loadFormProcessingFormReceiveQuotationWaste({ selectedFormProcessingFormReceiveQuotationWasteId }) {
  try {
    const data = yield call(loadFormProcessingFormReceiveQuotationWastesRequest, selectedFormProcessingFormReceiveQuotationWasteId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadFormProcessingFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadFormProcessingFormReceiveQuotationWaste() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_LOAD, loadFormProcessingFormReceiveQuotationWaste);
}

function* addFormProcessingFormReceiveQuotationWaste({ payload }) {
  try {
    const data = yield call(addFormProcessingFormReceiveQuotationWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addFormProcessingFormReceiveQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddFormProcessingFormReceiveQuotationWaste() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_ADD, addFormProcessingFormReceiveQuotationWaste);
}

function* updateFormProcessingFormReceiveQuotationWaste({ payload }) {
  try {
    const data = yield call(updateFormProcessingFormReceiveQuotationWasteRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateFormProcessingFormReceiveQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateFormProcessingFormReceiveQuotationWaste() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_UPDATE, updateFormProcessingFormReceiveQuotationWaste);
}

function* deleteFormProcessingFormReceiveQuotationWaste({ payload }) {
  try {
    const data = yield call(deleteFormProcessingFormReceiveQuotationWasteRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(deleteFormProcessingFormReceiveQuotationWasteSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllFormProcessingFormReceiveQuotationWastes({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteFormProcessingFormReceiveQuotationWasteRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllFormProcessingFormReceiveQuotationWastesSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteFormProcessingFormReceiveQuotationWaste() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE, deleteFormProcessingFormReceiveQuotationWaste);
}

function* processDeleteAllFormProcessingFormReceiveQuotationWastes() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTE_DELETE_ALL, deleteAllFormProcessingFormReceiveQuotationWastes);
}

const exportFormProcessingFormReceiveQuotationWastesRequest = async (params) => {
  return await Api({ responseType: 'blob' }).post(`form-processing-form-receive-quotation-waste/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportFormProcessingFormReceiveQuotationWastes({ payload }) {
  try {
    const data = yield call(exportFormProcessingFormReceiveQuotationWastesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(exportExcelFormProcessingFormReceiveQuotationWastesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportFormProcessingFormReceiveQuotationWastes() {
  yield takeEvery(FORMPROCESSINGFORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL, exportFormProcessingFormReceiveQuotationWastes);
}

export default function* FormProcessingFormReceiveQuotationWasteSagas() {
  yield all([fork(processLoadFormProcessingFormReceiveQuotationWastes),
  fork(processLoadFormProcessingFormReceiveQuotationWaste),
  fork(processAddFormProcessingFormReceiveQuotationWaste),
  fork(processUpdateFormProcessingFormReceiveQuotationWaste),
  fork(processDeleteAllFormProcessingFormReceiveQuotationWastes),
  fork(processDeleteFormProcessingFormReceiveQuotationWaste),
  fork(processExportFormProcessingFormReceiveQuotationWastes)
]);
}
