import {
  PERSONALMAILS_SELECT_ALL,
  PERSONALMAILS_UNSELECT_ALL,
  PERSONALMAIL_SELECTED,
  PERSONALMAILS_LOAD,
  PERSONALMAILS_LOAD_SUCCESS,
  PERSONALMAILS_LOAD_FAILED,
  PERSONALMAIL_LOAD,
  PERSONALMAIL_LOAD_SUCCESS,
  PERSONALMAIL_LOAD_FAILED,
  PERSONALMAIL_ADD,
  PERSONALMAIL_ADD_SUCCESS,
  PERSONALMAIL_ADD_FAILED,
  PERSONALMAIL_UPDATE,
  PERSONALMAIL_UPDATE_SUCCESS,
  PERSONALMAIL_UPDATE_FAILED,
  PERSONALMAIL_DELETE,
  PERSONALMAIL_DELETE_SUCCESS,
  PERSONALMAIL_DELETE_FAILED,
  PERSONALMAIL_DELETE_ALL,
  PERSONALMAIL_DELETE_ALL_SUCCESS,
  PERSONALMAIL_DELETE_ALL_FAILED,
  PERSONALMAILS_EXPORT_EXCEL,
  PERSONALMAILS_EXPORT_EXCEL_SUCCESS,
  PERSONALMAILS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllPersonalMails = () => {
  return {
    type: PERSONALMAILS_SELECT_ALL
  }
}

export const unselectAllPersonalMails = () => {
  return {
    type: PERSONALMAILS_UNSELECT_ALL
  }
}

export const selectPersonalMail = data => {
  return {
    type: PERSONALMAIL_SELECTED,
    payload: data
  }
}

export const loadPersonalMails = (data) => {
  return {
    type: PERSONALMAILS_LOAD,
    payload: data
  };
}

export const loadPersonalMailsSuccess = (data) => {
  return {
    type: PERSONALMAILS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadPersonalMailsFailed = () => {
  return {
    type: PERSONALMAILS_LOAD_FAILED
  };
}

export const loadPersonalMail = () => {
  return {
    type: PERSONALMAIL_LOAD
  };
}

export const loadPersonalMailSuccess = (data) => {
  return {
    type: PERSONALMAIL_LOAD_SUCCESS,
    payload: data
  };
}


export const loadPersonalMailFailed = () => {
  return {
    type: PERSONALMAIL_LOAD_FAILED
  };
}


export const addPersonalMail = (data) => {
  return {
    type: PERSONALMAIL_ADD,
    payload: data
  };
}


export const addPersonalMailSuccess = (filter) => {
  return {
    type: PERSONALMAIL_ADD_SUCCESS,
    message: 'PersonalMail added successfully.',
    payload: filter
  };
}

export const addPersonalMailFailed = () => {
  return {
    type: PERSONALMAIL_ADD_FAILED
  };
}

export const updatePersonalMail = (data) => {
  return {
    type: PERSONALMAIL_UPDATE,
    payload: data
  };
}


export const updatePersonalMailSuccess = (data) => {
  return {
    type: PERSONALMAIL_UPDATE_SUCCESS,
    payload: data
  };
}

export const updatePersonalMailFailed = (data) => {
  return {
    type: PERSONALMAIL_UPDATE_FAILED,
    payload: data
  };
}

export const deletePersonalMail = (data) => {
  return {
    type: PERSONALMAIL_DELETE,
    payload: data
  };
}

export const deletePersonalMailSuccess = () => {
  return {
    type: PERSONALMAIL_DELETE_SUCCESS,
    message: 'PersonalMail deleted successfully.'
  };
}

export const deletePersonalMailFailed = () => {
  return {
    type: PERSONALMAIL_DELETE_FAILED
  };
}

export const deleteAllPersonalMails = (data) => {
  return {
    type: PERSONALMAIL_DELETE_ALL,
    payload: data
  };
}

export const deleteAllPersonalMailsSuccess = () => {
  return {
    type: PERSONALMAIL_DELETE_ALL_SUCCESS,
    message: 'PersonalMail all deleted successfully.'
  };
}

export const deleteAllPersonalMailsFailed = () => {
  return {
    type: PERSONALMAIL_DELETE_ALL_FAILED
  };
}

export const exportExcelPersonalMails = (filter) => {
  return {
    type:PERSONALMAILS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelPersonalMailsSuccess = (data) => {
  return {
    type:PERSONALMAILS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelPersonalMailsFailed = () => {
  return {
    type:PERSONALMAILS_EXPORT_EXCEL_FAILED
  };
}
