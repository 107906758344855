import {
  LEDGERS_SELECT_ALL,
  LEDGERS_UNSELECT_ALL,
  LEDGER_SELECTED,
  LEDGERS_LOAD,
  LEDGERS_LOAD_SUCCESS,
  LEDGERS_LOAD_FAILED,
  LEDGER_LOAD,
  LEDGER_LOAD_SUCCESS,
  LEDGER_LOAD_FAILED,
  LEDGER_ADD,
  LEDGER_ADD_SUCCESS,
  LEDGER_ADD_FAILED,
  LEDGER_UPDATE,
  LEDGER_UPDATE_SUCCESS,
  LEDGER_UPDATE_FAILED,
  LEDGER_DELETE,
  LEDGER_DELETE_SUCCESS,
  LEDGER_DELETE_FAILED,
  LEDGER_DELETE_ALL,
  LEDGER_DELETE_ALL_SUCCESS,
  LEDGER_DELETE_ALL_FAILED,
  LEDGERS_EXPORT_EXCEL,
  LEDGERS_EXPORT_EXCEL_SUCCESS,
  LEDGERS_EXPORT_EXCEL_FAILED,
  LEDGERS_REPORT,
  LEDGERS_REPORT_SUCCESS,
  LEDGERS_REPORT_FAILED,
  LEDGERS_REPORTSUM_SUCCESS
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';
import Moment from 'moment';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  reportData: [],
  reportSumData: [],
  selectedLedger: null,
  selectedLedgerId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEDGERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case LEDGERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case LEDGER_SELECTED:
      return {
        ...state,
        selectedLedger: action.payload,
        selectedLedgerId: action.payload.id
      };
    case LEDGERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case LEDGERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case LEDGERS_LOAD_FAILED:
      return {
        ...state
      };
    case LEDGER_LOAD:
      return {
        ...state
      };
    case LEDGER_LOAD_SUCCESS:
      return {
        ...state
      };
    case LEDGER_LOAD_FAILED:
      return {
        ...state
      };
    case LEDGER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case LEDGER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case LEDGER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LEDGER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case LEDGER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case LEDGER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LEDGER_DELETE:
      return {
        ...state
      };
    case LEDGER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LEDGER_DELETE_FAILED:
      return {
        ...state
      };
    case LEDGER_DELETE_ALL:
      return {
        ...state
      };
    case LEDGER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LEDGER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case LEDGERS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case LEDGERS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case LEDGERS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case LEDGERS_REPORT:
      return {
        ...state,
        reportData: []
      };
    case LEDGERS_REPORT_SUCCESS:
      let reportData = action.payload;
      let result = [];
      for (var d = Moment(reportData.filter.startDate).format('YYYY-MM-1'); d <= Moment(reportData.filter.endDate).format('YYYY-MM-1'); d = Moment(d).add(1, 'months').format('YYYY-MM-1')) {
        let date = Moment(d);
        let data = reportData.data.filter(r => r.key.month == date.format('M') && r.key.year == date.format('YYYY'));

        let receiveData = data.filter(r => r.key.types == 1);
        if (receiveData.length) {
          result.push(receiveData[0]);
        } else {
          result.push({
            key: {
              month: parseInt(date.format('M')),
              year: parseInt(date.format('YYYY')),
              types: 1
            },
            value: 0
          })
        }

        let payData = data.filter(r => r.key.types == 2);
        if (payData.length) {
          result.push(payData[0]);
        } else {
          result.push({
            key: {
              month: parseInt(date.format('M')),
              year: parseInt(date.format('YYYY')),
              types: 2
            },
            value: 0
          })
        }
      }

      return {
        ...state,
        reportData: result
      };
    case LEDGERS_REPORT_FAILED:
      return {
        ...state,
        reportData: []
      };
      case LEDGERS_REPORTSUM_SUCCESS:
        return {
          ...state,
          reportSumData: action.payload
        };
    default:
      return state;
  }
}
