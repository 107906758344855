import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "../constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";
import Api from './Api';
import jwtDecode from "jwt-decode";

const createUserWithEmailPasswordRequest = async (email, password) => {
  return await Api().post('user/register', { email: email, password: password })
    .then(authUser => authUser)
    .catch(error => error);
}

const loginUserWithEmailPasswordRequest = async (email, password) => {
  return await Api().post('user/authenticate', { email: email, password: password })
    .then(authUser => {
      localStorage._ra___ = authUser.data.token;
      var token = jwtDecode(localStorage._ra___);
      localStorage._claim___ = token.claims;
      localStorage._info___ = JSON.stringify(authUser.data.info);

      return authUser.data;
    })
    .catch(error => error);
}

function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      yield put(userSignUpSuccess(signUpUser.data.info));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(loginUserWithEmailPasswordRequest, email, password);

    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      yield put(userSignInSuccess(signInUser.info));
    }
  } catch (error) {
    console.log(error);
    yield put(showAuthMessage(error));
  }
}
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export default function* authSaga() {
  yield all([fork(signInUser),
    fork(createUserAccount)]);
}
