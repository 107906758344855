import {
  QUOTATIONWASTES_SELECT_ALL,
  QUOTATIONWASTES_UNSELECT_ALL,
  QUOTATIONWASTE_SELECTED,
  QUOTATIONWASTES_LOAD,
  QUOTATIONWASTES_LOAD_SUCCESS,
  QUOTATIONWASTES_LOAD_FAILED,
  QUOTATIONWASTE_LOAD,
  QUOTATIONWASTE_LOAD_SUCCESS,
  QUOTATIONWASTE_LOAD_FAILED,
  QUOTATIONWASTE_ADD,
  QUOTATIONWASTE_ADD_SUCCESS,
  QUOTATIONWASTE_ADD_FAILED,
  QUOTATIONWASTE_UPDATE,
  QUOTATIONWASTE_UPDATE_SUCCESS,
  QUOTATIONWASTE_UPDATE_FAILED,
  QUOTATIONWASTE_DELETE,
  QUOTATIONWASTE_DELETE_SUCCESS,
  QUOTATIONWASTE_DELETE_FAILED,
  QUOTATIONWASTE_DELETE_ALL,
  QUOTATIONWASTE_DELETE_ALL_SUCCESS,
  QUOTATIONWASTE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedQuotationWaste: null,
  selectedQuotationWasteId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'quotationUpdatedDate',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QUOTATIONWASTES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case QUOTATIONWASTES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case QUOTATIONWASTE_SELECTED:
      return {
        ...state,
        selectedQuotationWaste: action.payload,
        selectedQuotationWasteId: action.payload.id
      };
    case QUOTATIONWASTES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case QUOTATIONWASTES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case QUOTATIONWASTES_LOAD_FAILED:
      return {
        ...state
      };
    case QUOTATIONWASTE_LOAD:
      return {
        ...state
      };
    case QUOTATIONWASTE_LOAD_SUCCESS:
      return {
        ...state
      };
    case QUOTATIONWASTE_LOAD_FAILED:
      return {
        ...state
      };
    case QUOTATIONWASTE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case QUOTATIONWASTE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATIONWASTE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATIONWASTE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case QUOTATIONWASTE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATIONWASTE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATIONWASTE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case QUOTATIONWASTE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case QUOTATIONWASTE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATIONWASTE_DELETE_ALL:
      return {
        ...state
      };
    case QUOTATIONWASTE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case QUOTATIONWASTE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
