import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSupplyImportVouchers as loadSupplyImportVouchersAction,
  loadSupplyImportVouchersSuccess,
  addSupplyImportVoucherSuccess,
  updateSupplyImportVoucherSuccess,
  exportExcelSupplyImportVouchersSuccess
} from '../actions/SupplyImportVoucher';

import {
  SUPPLYIMPORTVOUCHERS_LOAD,
  SUPPLYIMPORTVOUCHER_ADD,
  SUPPLYIMPORTVOUCHER_UPDATE,
  SUPPLYIMPORTVOUCHER_DELETE,
  SUPPLYIMPORTVOUCHER_DELETE_ALL,
  SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSupplyImportVoucherById = (id, callback) => {
  Api().get('supply-import-voucher/' + id).then(response => {
    callback(response.data);
  })
}

export const changeSupplyImportVoucherStatus = (model, callback) => {
  Api().post('supply-import-voucher/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllSupplyImportVouchers = (callback) => {
  Api().post('supply-import-voucher/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}
export const getMaxSupplyImportVoucherCodeSorted = (callback) => {
  Api().post('supply-import-voucher/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadSupplyImportVouchersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`supply-import-voucher/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`supply-import-voucher`, { params })
    .then(data => data)
    .catch(error => error);
}


const addSupplyImportVoucherRequest = async (payload) =>
  await Api().post(`supply-import-voucher`, payload)
    .then(data => data)
    .catch(error => error);

const updateSupplyImportVoucherRequest = async (payload) =>
  await Api().patch(`supply-import-voucher/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSupplyImportVoucherRequest = async (id) =>
  await Api().delete(`supply-import-voucher/${id}`)
    .then(data => data)
    .catch(error => error);

const exportSupplyImportVouchersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`supply-import-voucher/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadSupplyImportVouchers({ payload }) {
  try {

    const data = yield call(loadSupplyImportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyImportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyImportVouchers() {
  yield takeEvery(SUPPLYIMPORTVOUCHERS_LOAD, loadSupplyImportVouchers);
}

function* loadSupplyImportVoucher({ selectedSupplyImportVoucherId }) {
  try {

    const data = yield call(loadSupplyImportVouchersRequest, selectedSupplyImportVoucherId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyImportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyImportVoucher() {
  yield takeEvery(SUPPLYIMPORTVOUCHERS_LOAD, loadSupplyImportVoucher);
}


function* addSupplyImportVoucher({ payload }) {
  try {
    const data = yield call(addSupplyImportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSupplyImportVoucherSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSupplyImportVoucher() {
  yield takeEvery(SUPPLYIMPORTVOUCHER_ADD, addSupplyImportVoucher);
}


function* updateSupplyImportVoucher({ payload }) {
  try {
    const data = yield call(updateSupplyImportVoucherRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSupplyImportVoucherSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSupplyImportVoucher() {
  yield takeEvery(SUPPLYIMPORTVOUCHER_UPDATE, updateSupplyImportVoucher);
}

function* deleteSupplyImportVoucher({ payload }) {
  try {
    const data = yield call(deleteSupplyImportVoucherRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyImportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSupplyImportVouchers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSupplyImportVoucherRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSupplyImportVouchersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSupplyImportVoucher() {
  yield takeEvery(SUPPLYIMPORTVOUCHER_DELETE, deleteSupplyImportVoucher);
}

function* processDeleteAllSupplyImportVouchers() {
  yield takeEvery(SUPPLYIMPORTVOUCHER_DELETE_ALL, deleteAllSupplyImportVouchers);
}

function* exportSupplyImportVouchers({ payload }) {
  try {
    const data = yield call(exportSupplyImportVouchersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSupplyImportVouchersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSupplyImportVouchers() {
  yield takeEvery(SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL, exportSupplyImportVouchers);
}

export default function* SupplyImportVoucherSagas() {
  yield all([fork(processLoadSupplyImportVouchers),
  fork(processLoadSupplyImportVoucher),
  fork(processAddSupplyImportVoucher),
  fork(processUpdateSupplyImportVoucher),
  fork(processDeleteAllSupplyImportVouchers),
  fork(processDeleteSupplyImportVoucher),
  fork(processExportSupplyImportVouchers)]);
}
