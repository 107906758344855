import {
  FORMRECEIVEQUOTATIONWASTES_SELECT_ALL,
  FORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL,
  FORMRECEIVEQUOTATIONWASTE_SELECTED,
  FORMRECEIVEQUOTATIONWASTES_LOAD,
  FORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS,
  FORMRECEIVEQUOTATIONWASTES_LOAD_FAILED,
  FORMRECEIVEQUOTATIONWASTE_LOAD,
  FORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_LOAD_FAILED,
  FORMRECEIVEQUOTATIONWASTE_ADD,
  FORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_ADD_FAILED,
  FORMRECEIVEQUOTATIONWASTE_UPDATE,
  FORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED,
  FORMRECEIVEQUOTATIONWASTE_DELETE,
  FORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_DELETE_FAILED,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS,
  FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED,
  FORMRECEIVEQUOTATIONWASTE_WASTES,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
  FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED,
  FORMRECEIVEQUOTATIONWASTES_SUM,
  FORMRECEIVEQUOTATIONWASTES_SUM_SUCCESS,
  FORMRECEIVEQUOTATIONWASTES_SUM_FAILED,
  FORMRECEIVEQUOTATIONWASTES_SET_FILTER
} from '../constants/ActionTypes';

export const selectAllFormReceiveQuotationWastes = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_SELECT_ALL
  }
}

export const unselectAllFormReceiveQuotationWastes = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_UNSELECT_ALL
  }
}

export const selectFormReceiveQuotationWaste = data => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_SELECTED,
    payload: data
  }
}

export const loadFormReceiveQuotationWastes = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_LOAD,
    payload: data
  };
}

export const loadFormReceiveQuotationWastesSuccess = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_LOAD_FAILED
  };
}

export const loadFormReceiveQuotationWaste = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_LOAD
  };
}

export const loadFormReceiveQuotationWasteSuccess = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadFormReceiveQuotationWasteFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_LOAD_FAILED
  };
}


export const addFormReceiveQuotationWaste = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_ADD,
    payload: data
  };
}


export const addFormReceiveQuotationWasteSuccess = (filter) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_ADD_SUCCESS,
    message: 'FormReceiveQuotationWaste added successfully.',
    payload: filter
  };
}

export const addFormReceiveQuotationWasteFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_ADD_FAILED
  };
}

export const updateFormReceiveQuotationWaste = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_UPDATE,
    payload: data
  };
}


export const updateFormReceiveQuotationWasteSuccess = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateFormReceiveQuotationWasteFailed = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteFormReceiveQuotationWaste = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_DELETE,
    payload: data
  };
}

export const deleteFormReceiveQuotationWasteSuccess = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_DELETE_SUCCESS,
    message: 'FormReceiveQuotationWaste deleted successfully.'
  };
}

export const deleteFormReceiveQuotationWasteFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_DELETE_FAILED
  };
}

export const deleteAllFormReceiveQuotationWastes = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllFormReceiveQuotationWastesSuccess = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_SUCCESS,
    message: 'FormReceiveQuotationWaste all deleted successfully.'
  };
}

export const deleteAllFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_DELETE_ALL_FAILED
  };
}

export const copyWasteListFromReportToFormProcessing = (wastes) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTE_WASTES,
    payload: wastes
  };
}

export const exportExcelFormReceiveQuotationWastes = (filter) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelFormReceiveQuotationWastesSuccess = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_EXPORT_EXCEL_FAILED
  };
}

export const sumFormReceiveQuotationWastes = (filter) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_SUM,
    payload: filter
  } 
}

export const sumFormReceiveQuotationWastesSuccess = (data) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_SUM_SUCCESS,
    payload: data
  };
}

export const sumFormReceiveQuotationWastesFailed = () => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_SUM_FAILED
  };
}

export const setFilter = (newFilter) => {
  return {
    type: FORMRECEIVEQUOTATIONWASTES_SET_FILTER,
    payload: newFilter
  }
}