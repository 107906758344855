import {
  SUPPLYFORMITEMS_SELECT_ALL,
  SUPPLYFORMITEMS_UNSELECT_ALL,
  SUPPLYFORMITEM_SELECTED,
  SUPPLYFORMITEMS_LOAD,
  SUPPLYFORMITEMS_LOAD_SUCCESS,
  SUPPLYFORMITEMS_LOAD_FAILED,
  SUPPLYFORMITEM_LOAD,
  SUPPLYFORMITEM_LOAD_SUCCESS,
  SUPPLYFORMITEM_LOAD_FAILED,
  SUPPLYFORMITEM_ADD,
  SUPPLYFORMITEM_ADD_SUCCESS,
  SUPPLYFORMITEM_ADD_FAILED,
  SUPPLYFORMITEM_UPDATE,
  SUPPLYFORMITEM_UPDATE_SUCCESS,
  SUPPLYFORMITEM_UPDATE_FAILED,
  SUPPLYFORMITEM_DELETE,
  SUPPLYFORMITEM_DELETE_SUCCESS,
  SUPPLYFORMITEM_DELETE_FAILED,
  SUPPLYFORMITEM_DELETE_ALL,
  SUPPLYFORMITEM_DELETE_ALL_SUCCESS,
  SUPPLYFORMITEM_DELETE_ALL_FAILED,
  SUPPLYFORMITEMS_EXPORT_EXCEL,
  SUPPLYFORMITEMS_EXPORT_EXCEL_SUCCESS,
  SUPPLYFORMITEMS_EXPORT_EXCEL_FAILED,
} from '../constants/ActionTypes';

export const selectAllSupplyFormItems = () => {
  return {
    type: SUPPLYFORMITEMS_SELECT_ALL
  }
}

export const unselectAllSupplyFormItems = () => {
  return {
    type: SUPPLYFORMITEMS_UNSELECT_ALL
  }
}

export const selectSupplyFormItem = data => {
  return {
    type: SUPPLYFORMITEM_SELECTED,
    payload: data
  }
}

export const loadSupplyFormItems = (data) => {
  return {
    type: SUPPLYFORMITEMS_LOAD,
    payload: data
  };
}

export const loadSupplyFormItemsSuccess = (data) => {
  return {
    type: SUPPLYFORMITEMS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSupplyFormItemsFailed = () => {
  return {
    type: SUPPLYFORMITEMS_LOAD_FAILED
  };
}

export const loadSupplyFormItem = () => {
  return {
    type: SUPPLYFORMITEM_LOAD
  };
}

export const loadSupplyFormItemSuccess = (data) => {
  return {
    type: SUPPLYFORMITEM_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSupplyFormItemFailed = () => {
  return {
    type: SUPPLYFORMITEM_LOAD_FAILED
  };
}


export const addSupplyFormItem = (data) => {
  return {
    type: SUPPLYFORMITEM_ADD,
    payload: data
  };
}


export const addSupplyFormItemSuccess = (filter) => {
  return {
    type: SUPPLYFORMITEM_ADD_SUCCESS,
    message: 'SupplyFormItem added successfully.',
    payload: filter
  };
}

export const addSupplyFormItemFailed = () => {
  return {
    type: SUPPLYFORMITEM_ADD_FAILED
  };
}

export const updateSupplyFormItem = (data) => {
  return {
    type: SUPPLYFORMITEM_UPDATE,
    payload: data
  };
}


export const updateSupplyFormItemSuccess = (data) => {
  return {
    type: SUPPLYFORMITEM_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSupplyFormItemFailed = (data) => {
  return {
    type: SUPPLYFORMITEM_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSupplyFormItem = (data) => {
  return {
    type: SUPPLYFORMITEM_DELETE,
    payload: data
  };
}

export const deleteSupplyFormItemSuccess = () => {
  return {
    type: SUPPLYFORMITEM_DELETE_SUCCESS,
    message: 'SupplyFormItem deleted successfully.'
  };
}

export const deleteSupplyFormItemFailed = () => {
  return {
    type: SUPPLYFORMITEM_DELETE_FAILED
  };
}

export const deleteAllSupplyFormItems = (data) => {
  return {
    type: SUPPLYFORMITEM_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSupplyFormItemsSuccess = () => {
  return {
    type: SUPPLYFORMITEM_DELETE_ALL_SUCCESS,
    message: 'SupplyFormItem all deleted successfully.'
  };
}

export const deleteAllSupplyFormItemsFailed = () => {
  return {
    type: SUPPLYFORMITEM_DELETE_ALL_FAILED
  };
}

export const exportExcelSupplyFormItems = (filter) => {
  return {
    type: SUPPLYFORMITEMS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSupplyFormItemsSuccess = (data) => {
  return {
    type: SUPPLYFORMITEMS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSupplyFormItemsFailed = () => {
  return {
    type: SUPPLYFORMITEMS_EXPORT_EXCEL_FAILED
  };
}
